import { ChangeEvent } from "react";
import { FormControlLabel, Checkbox } from "@material-ui/core";

interface CustomCheckboxProps {
  label: string;
  checked?: boolean;
  color?: "default" | "primary" | "secondary" | undefined;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const CustomCheckboxLabel = (props: CustomCheckboxProps) => {
  const { label, checked = false, color = "primary", onChange } = props;
  return (
    <FormControlLabel
      control={<Checkbox color={color} checked={checked} onChange={onChange} />}
      label={<span style={{fontSize: "13px"}}>{label}</span>}
    />
  );
};

export default CustomCheckboxLabel