import  { useAppState } from '../../state';
import { VideoProvider } from '../../components/consultation/VideoProvider';
import useConnectionOptions from '../../utils/useConnectionOptions/useConnectionOptions';
import ErrorDialog from '../../components/consultation/ErrorDialog/ErrorDialog';
import ConsultationVideoCall from './ConsultationVideoCall';
import { useEffect, useState, useRef } from 'react';
import Consultation from '../../pages/AppointmentManagement/Consultation'
import Notify from '../../components/common/CommonToast';
import { useLocation } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { checkRoomIdValid } from '../../api/InstantVideo/Instant';
import FullScreenLoader from '../../components/common/FullScreenLoader';
import { DisconnectMsg, UpdateCallbackInfo, UpdateInstInfo, UpdatePatientInfo, UpdateRoomStatus } from '../../store/actions';
import CallerTune from "../../assets/mp3/callerTune.mp3"
import { sendMsg } from '../../components/Websocket/InstantChatIO';
import { USER_ROLES } from '../../utils/constants';
import { getPatientInfoByRoomId } from '../../api/consultation/consultation';
import { TWILIO_ROOM } from '../../utils';

const ConsultationRender = () => {
  const location = useLocation()
  const { row }: any = location?.state || {}
  const { getToken } = useAppState();
  const { user,userRole } = useSelector((state: RootStateOrAny) => state?.auth);
  const { connecting, callBackData } = useSelector((state : any)=> state?.instantVideo)
  const { error, setError } : any = useAppState();
  const connectionOptions = useConnectionOptions();
  const [isCallJoined, setCallJoined] = useState<boolean>(false);
  const [isCallBackUserJoind, setIsCallBackUserJoined] = useState<boolean>(false);
  const [roomStatus, setRoomStatus] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [alertMsg,setAlertMsg]=useState({show:false , message : ""});
  const [isVideoCollapsed, setVideoCollapsed] = useState<boolean>(false);
  const {disconnectMsg} = useSelector((state: any) => state?.instantChat);
  const [patientLeftChat,setpatientLeftChat] =useState({loading:false,message:'',severity:''});
  const roomState = useRef();
  const dispatch = useDispatch();
  const [show, setshow] = useState({
    show: false,
    message: ''
  });
  const searchVal = location.search.split('?')[1];
  const { instantdata, isDuplicateTab } = useSelector((state: RootStateOrAny) => state?.instantVideo);
  const check = () => {
    setVideoCollapsed(!isVideoCollapsed);
  };

  useEffect(()=>{
    if(isDuplicateTab && !isVideoCollapsed){
      check()
    }
  },[isDuplicateTab])

const changeRoomState = (value) =>
{
  roomState.current = value ;
}
  // Commenting this for now only, we will enable this for later.
  // useEffect(() => {
  //   if(unSavedChanges){
  //   window.addEventListener('beforeunload', (event) => {
  //     event.preventDefault();
  //     return (event.returnValue = "");
  //   }, { capture: true });
  //   return () => {  
  //       window.removeEventListener('beforeunload', (event) => {
  //       event.preventDefault();
  //       return (event.returnValue = "");
  //     }, { capture: true })
  //   }
  //   }
  // },[unSavedChanges]);

  useEffect(()=>{
    setRoomStatus('')
    setError(null)
    setCallJoined(false);
    TWILIO_ROOM.isUserDisconnected = false;
    if (location.pathname === '/Appointments/room') {
      roomIdValidation(row?.row?._id || location.search.split('?')[1])
    } else {
        if(searchVal?.split('/').length > 1){
          roomIdValidation(searchVal?.split('/')[1])
        } else {
          roomIdValidation((searchVal && searchVal !== 'undefined') ? searchVal : instantdata?.roomId)
        }
      
    }
  },[location])

  const roomIdValidation = async (roomId: string) => {
    try {
      setAlertMsg({show : true,message :""});
      const consultType = location.pathname === '/Appointments/room' ? "VIRTUAL_CONSULTATION" : "INSTANT"
      const isCallBack = searchVal?.split('/').length > 1;
      const isRefreshCallBack = isCallBack && callBackData?.status === 'CALL_BACK_TOKEN_STORED'
      const res = await checkRoomIdValid(roomId, consultType, userRole, isCallBack, isRefreshCallBack)
      if (res?.data?.state === "UNAVAILABLE") {
        if(sessionStorage.getItem("new-consulation"))
        {
          if (location.pathname === '/InstantCall/room' && userRole === USER_ROLES.doctor && instantdata?.type !== "RECONNECT") {
            sendMsg('doctorReject', {callId: instantdata?.callId, role: userRole})
          } else if (location.pathname === '/InstantCall/room' && userRole === USER_ROLES.receptionist && instantdata?.type !== "RECONNECT") {
            sendMsg('receptionistReject', {callId: instantdata?.callId, userId: user?.userId, role: userRole})
          }
          sessionStorage.removeItem("new-consulation");
        }
        setError({ code: -100001, message: res?.data?.message || 'Invalid Meeting Id' })
        setState(res?.data?.state)
      } else {
        //setError(null)
        if(location.pathname === '/Appointments/room' && instantdata?.type !== "RECONNECT"){
          dispatch(UpdateInstInfo({...instantdata, bookedId: row?.row?._id}))
        }
        setState('')
        setAlertMsg({show : false,message :res?.data?.message});
      }
      setRoomStatus(res?.data?.roomStatus)
      if (res?.data?.roomStatus === "UNAVAILABLE" && !isVideoCollapsed) 
      {
      check();
      }
      /* Only for Scheduled Call Get Token is called when patient ends the call before doctor Joins to change the call state to accepeted in back-end*/
      if(res?.data?.roomStatus === "UNAVAILABLE" && res?.data?.state === "AVAILABLE" && location.pathname === '/Appointments/room')
      {
        await getToken(user?._id,row?.row?._id || location.search.split('?')[1], userRole === USER_ROLES.receptionist ? "Receptionist" : USER_ROLES.doctor ? "Doctor" : userRole, "VIRTUAL_CONSULTATION",false).then(() => sendMsg('updateStatus',{'userId': user?._id, 'status': 'busy'}))
      }
    } catch (e) {
      setError({ code: -100001, message: e?.response?.data?.message ||'Invalid Meeting Id' })
      setState("UNAVAILABLE")
      setRoomStatus("UNAVAILABLE")
      if(!isVideoCollapsed){
        check()
      }
    }
  }

  useEffect(() => {
      if(roomStatus)
        {
          if(roomStatus === "AVAILABLE")
          dispatch(UpdateRoomStatus(true));
          else
          dispatch(UpdateRoomStatus(false));
        }
  },[roomStatus])

  const getPatientInfo = async() => {
    try{
      const res = await getPatientInfoByRoomId(searchVal?.split('/')[1])
      if(res){
        const info = {
                      photo: res?.photo,
                      name: res?.name?.first + " " + res?.name?.last
                    }
        const callbackTypeOnrefresh = {
                                        type: 'CALLBACK',
                                        status: 'CALL_BACK_TOKEN_STORED'
                                      }
        dispatch(UpdatePatientInfo(res));
        (!callBackData || JSON.stringify(callBackData) === "{}") ? dispatch(UpdateCallbackInfo({
          ...callbackTypeOnrefresh,
          ...info
        })) : dispatch(UpdateCallbackInfo({
          ...callBackData,
          ...info
        }));
      }
    }catch(e){

    }
  }

  /* Patient ended & Patient Left toaster clear cache */
  useEffect(() => {
    dispatch(DisconnectMsg(''));
    if(searchVal?.split('/').length > 1 && !row?.row?._id){
      getPatientInfo()
    }
    sessionStorage.setItem('INSTANT_CALL_INITIATED', 'true');
    return () => {
      if(sessionStorage.getItem("new-consulation"))
      {
        if (location.pathname === '/InstantCall/room' && userRole === USER_ROLES.doctor && instantdata?.type !== "RECONNECT") {
          sendMsg('doctorReject', {callId: instantdata?.callId, role: userRole})
        } else if (location.pathname === '/InstantCall/room' && userRole === USER_ROLES.receptionist && instantdata?.type !== "RECONNECT") {
          sendMsg('receptionistReject', {callId: instantdata?.callId, userId: user?.userId, role: userRole})
        }
        else if(location.pathname === "/Appointments/room" && userRole === USER_ROLES.doctor)
        {
          sendMsg('doctorReject', {callId: instantdata?.callId, role: userRole})
        }
        sessionStorage.removeItem("new-consulation");
      }
    }
  },[])
  
  useEffect(()=>{
    if(disconnectMsg)
    {
      if(document.getElementById("twilio-connecting") !== null || document.getElementById('joinnow-loader') !== null)
      TWILIO_ROOM.isUserDisconnected = true;
      else if(document.getElementById("end-call-buttons") !== null && disconnectMsg === "Patient ended the call")
      TWILIO_ROOM?.end();
      setpatientLeftChat({loading:false,message : '' ,severity:''})
      setTimeout(() => {
        setpatientLeftChat({loading:false,message :disconnectMsg ,severity:'error'})
        dispatch(DisconnectMsg(''));
      },500)
    }
  },[disconnectMsg])

  return ( 
    <div key={location.search} style={(isVideoCollapsed && isCallJoined)? { height:'auto', display: 'grid', gridTemplateRows: '1fr auto', background:'#F3F3F3' ,padding:'74px 1% 1% 1%'} : { height:'100%', display: 'grid', gridTemplateRows: '1fr auto', background:'#F3F3F3', paddingTop: '58px'}} >
      {(roomStatus === '' && !error) &&  <FullScreenLoader />}
      <VideoProvider options={connectionOptions} onError={setError}>
        {patientLeftChat.message  && <Notify message={patientLeftChat?.message} severity='error'/>}
        <ErrorDialog dismissError={() => setError(null)} error={error} isVideoCollapsed={isVideoCollapsed} check={check} roomState={roomState}/>
        {alertMsg.message && <Notify message={alertMsg?.message} severity={"error"} title={"Alert"}/>}
        {isCallJoined && show?.show && <Notify message={show?.message} severity={"error"} title={"Alert"}/>}
        {(isCallJoined || (roomStatus === "UNAVAILABLE" && state === '')) && <div style={{ display: isVideoCollapsed ? 'block' : 'none' }}>
          <Consultation/>
        </div>}
        {(connecting.show && roomStatus !== "UNAVAILABLE") && document.getElementById("end-call-buttons") && <audio src={CallerTune} autoPlay loop/>}
        {(roomStatus === "AVAILABLE") && <ConsultationVideoCall
          check={check}
          isVideoCollapsed={isVideoCollapsed}
          setVideoCollapsed={setVideoCollapsed}
          setCallJoined={setCallJoined}
          isCallJoined={isCallJoined}
          setshow={setshow}
          setError={setError}
          changeRoomState={changeRoomState}
          setIsCallBackUserJoined={setIsCallBackUserJoined}
          isCallBackUserJoind={isCallBackUserJoind}
          setRoomStatus={setRoomStatus}
          setState={setState}
        />} 

      </VideoProvider>
    </div>
  );
}

export default ConsultationRender