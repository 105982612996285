import * as actionTypes from "./actionTypes";

export const UpdateInstInfo =(data)=>({
    payload:data,
    type:actionTypes.UPDATE_INST_INFO,
});

export const UpdateBookId=(data)=>({
    payload:data,
    type:actionTypes.UPDATE_BOOK_ID,
});

export const UpdateCallInfo=(data)=>({
    payload:data,
    type:actionTypes.UPDATE_CALL_INFO
});

export const UpdateConnectingInfo=(data)=>({
    payload: data,
    type: actionTypes.UPDATE_CONNECTING_INFO
})

export const UpdateInstantType=(data)=>({
    payload: data,
    type: actionTypes.INSTANT_VIDEO_TYPE
})

export const UpdateCallId=(data)=>({
    payload:data,
    type:actionTypes.UPDATE_CALL_ID,
});

export const UpdateCallbackInfo =(data)=>({
    payload:data,
    type:actionTypes.UPDATE_CALLBACK_INFO,
});
export const UpdateRoomStatus = (data) =>({
    payload: data,
    type: actionTypes.ROOM_STATUS
}) 
export const UpdateRejoinStatus = (data) =>({
    payload: data,
    type: actionTypes.UPDATE_IS_REJOIN
})
export const EmptyinstantVideo = () =>({
    type: actionTypes.EMPTY_INSTANT_VIDEO
})
export const UpdateDuplicateTab = (data) => ({
    payload: data,
    type: actionTypes.ACTIVE_CONSULTATION_DUPLICATE_TAB
})

export const UpdateUserOnlineStatus = (data) => ({
    payload: data,
    type: actionTypes.USER_ONLINE_STATUS
})

export const UpdateErrorCode = (data) => ({
    payload: data,
    type: actionTypes.TWILLIO_ERROR_CODE
})

export const UpdateRemoveReceptionist = (data) => ({
    payload: data,
    type: actionTypes.REMOVE_RECEPTIONIST
})

export const UpdateCameraFlip = (data) => ({
    payload: data,
    type: actionTypes.UPDATE_CAMERA_FLIP
})

export const UpdatePatientInfo = (data) => ({
    payload: data,
    type: actionTypes.UPDATE_PATIENT_INFO
})

export const UpdateChatToCallRequest = (data) => ({
    payload: data,
    type: actionTypes.CHAT_TO_CALL_REQUEST
})
export const setReferInternal = (data) => ({
    payload: data,
    type: actionTypes.REFER_INTERNAL,
  });

export const UpdateUserEnded = (data) => ({
    payload: data,
    type: actionTypes.USER_ENDED_CONSULTATION_DATA
})
