export const styles = (theme) => ({
    root: {
        "& .MuiPaper-root": {
            borderRadius: "8px",
            backgroundColor: "white"
        },
        "& .MuiDialogContent-root": {
            maxWidth: "100%",
            "& p": {
                fontSize: "18px"
            }
        },
        "& .MuiDialogTitle-root": {
            "& .close-btn": {
                display: "inline-block",
                width: "fit-content",
                position: "absolute",
                top: "8px",
                right: "16px",

            }
        }
    }
});