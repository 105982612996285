import { AxiosInstance } from 'axios';
import { FILE_COUNT, PRACTITIONER_SERVICE ,VIDEO_SERVICE,} from '../../config/url.config';

import Services from "../services";

const instance : AxiosInstance = new Services().getServiceInstance();
interface IDoctorlist
{
    speciality?:string,
    pageSize:number,
    pageNumber:number,
}
export interface IRemoveUser
{
    "room_name": string,
    "user_identity": string,
    "role":string,
    "callId":string
}
export interface IendVideoCall
{
    "room_name": string,
    "user_identity":string,
    "consultationType":string,
    "role":string,
    "duration":number,
    "callId":string,
    "reason":string,
    "comment":string,
    "bookedId":string,
}
export const getAllDoctors = async (params: IDoctorlist) => {
    const { data } = await instance.get(`${PRACTITIONER_SERVICE}/instant/practitioners?`, {params});
    return data
}

export const removeUser = async (key:string,payload : IRemoveUser) =>{
    const {data} = await instance.post(`${VIDEO_SERVICE}/userRemove`,payload);
    return data
}

export const endVideoCall = async (key:string,payload : IendVideoCall) =>{
    const {data} = await instance.post(`${VIDEO_SERVICE}/endcall`,payload);
    return data
}

export const checkRoomIdValid = async(roomId:string,consultType:string, userRole:string, isCallBack: boolean, isRefreshCallback: boolean) => {
    const data = await instance.get(`${VIDEO_SERVICE}/getReconnectStatus?roomId=${roomId}&ConsultationType=${consultType}&role=${userRole}&isCallBack=${JSON.stringify(isCallBack)}&isRefreshCallback=${isRefreshCallback}`);
    return data
}

export const doctorDashboardCallcount = async(param:any) => {
    const {data} = await instance.get(`${VIDEO_SERVICE}/getMissedCall?type=${param.type}&pageSize=${param.pageSize}&listView=${param.listView}&role=${param.roleId}&practitionerId=${param.practitionerId}`);
    return data
} 

export const receptionistDashboardCallcount = async(param:any) => {
    const {data} = await instance.get(`${VIDEO_SERVICE}/getMissedCall?type=${param.type}&pageSize=${param.pageSize}&listView=${param.listView}`);
    return data
} 

export const receptionistMissedCallcount = async(param:any) => {
    const {data} = await instance.get(`${VIDEO_SERVICE}/getMissedCall?type=${param.type}&pageSize=${param.pageSize}&listView=${param.listView}&fromDate=${param.fromDate}&toDate=${param.toDate}&search=${param.search}&pageNumber=${param.pageNumber}`);
    return data
} 

export const doctorMissedCallcount = async(param:any) => {
    const {data} = await instance.get(`${VIDEO_SERVICE}/getMissedCall?type=${param.type}&pageSize=${param.pageSize}&listView=${param.listView}&fromDate=${param.fromDate}&toDate=${param.toDate}&role=${param.role}&practitionerId=${param.practitionerId}&search=${param.search}&pageNumber=${param.pageNumber}`);
    return data
} 

export const roomDeleteApi = async (key:string,room_name : string) =>{
    const {data} = await instance.post(`${VIDEO_SERVICE}/endCallForDoctor`,{room_name:room_name});
    return data
}

export const fileUploadCount = async (key:any,payload:any) =>{
    const data = await instance.post(`${FILE_COUNT}/chatHistoryFileCount`,payload);
    return data
}

export const doctorRemoveReceptionist = async (roomName:string) => {
    const {data} = await instance.post(`${VIDEO_SERVICE}/removeReceptionistFromCall`,{room_name:roomName});
    return data
}