import { Box, Grid, Tooltip, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import CustomIconButton from "../../../components/common/CustomIconButton";
import Download from "../../../assets/images/icons/download.svg";
import View from "../../../assets/images/icons/view.svg";
import styles from "./styles";
import { downloadFile } from "../../../utils";
import DocumentViewer from "../../../components/common/DocumentViewer";
import { getAllPrescription } from "../../../api/consultation/consultation";
import { useQuery } from "react-query";
import { RootStateOrAny, useSelector } from "react-redux";
import { genericObj } from "../../../models/common";

interface IdetailProps {
  detailData: any;
}

const Documents = ({ detailData }: IdetailProps) => {
  const classes = styles();
  const [showDoc, setShowDoc] = useState<any>("");
  const [prescriptionList, setPrescriptionList]=useState<genericObj[]>([])
  const [docList, setDocList]=useState<genericObj[]>([])
  const { details } = useSelector((state: RootStateOrAny) => state.clinic);

  const onclickToView = (item: any) => {
    setShowDoc(item?.url);
  };

  const getAllPrescriptionPayload = {
    facilityId: details?.id,
    consultationId: detailData?.consultationId,
    patientId: detailData?.participant?.secondaryUserId?.id,
    pageNumber: 1,
    pageSize: 20,
    idList: [],
  };

  const { data: userPrescription = {} } = useQuery(["GET_USER_PRESCRIPTION", getAllPrescriptionPayload],getAllPrescription, {enabled:detailData?.consultationId && detailData?.participant?.secondaryUserId?.id});

  useEffect(()=>{
    if(userPrescription?.data?.data?.length>0 && userPrescription){
        setPrescriptionList(userPrescription?.data?.data?.map((x:any)=>({name:x?.pid, tags:"Prescription", fileName:x?.prescriptionUrl?.map((x:{fileName:string})=>x?.fileName)[0], url:x?.prescriptionUrl?.map((x:{s3Url:string})=>x?.s3Url)[0]})))
    }
  },[userPrescription])

  useEffect(()=>{
    if(detailData?.caseSheet){
        const caseSheetName=new URL(decodeURIComponent(detailData?.caseSheet))?.pathname?.split('/')?.pop()
        setDocList([...prescriptionList, {name:caseSheetName?.split('.')?.slice(0, -1)?.join('.'), url:decodeURIComponent(detailData?.caseSheet), fileName:caseSheetName, tags:"Casesheet"}])
    }else{
        setDocList(prescriptionList)
    }
  },[prescriptionList, detailData])

  return (
    <Grid
      container
      xs={12}
      className={classes.detailsRoot}
      style={{ marginTop: "16px" }}
    >
      <Box
        component="div"
        className={classes.detailsTitle}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography variant="h6" className={classes.details}>
         Documents
        </Typography>
        {/* This will be ennabled in future */}
        {/* <CustomIconButton
          icon={
            <img
              src={Download}
              style={{ height: "20px", marginRight: "8px" }}
            />
          }
          label="Download All"
          onClick={() => {}}
          variant="text"
          size="small"
          labelFont={16}
          classes={classes.download}
        /> */}
      </Box>
      {docList?.length > 0?
      <Box component="div" className={classes.detailsContent}>
        {docList?.map((item: any) => (
          <Grid
            container
            xs={12}
            className={classes.feeGrid}
          >
            <Grid item xs={5} md={6}
             className={classes.documentName}>
             <Tooltip title={item.name}> 
             <span className={classes.feeContent}>{item.name}</span>
             </Tooltip>
             <Grid item xs={3} md={2}>
              <span className={classes.downloadTag}>{item.tags}</span>
            </Grid>
            </Grid>
            {item?.url && 
            <Grid item >
              <CustomIconButton
                icon={<img src={View}  />}
                label=""
                onClick={onclickToView.bind(null, item)}
                variant="text"
                size="small"
                labelFont={16}
                classes={classes.view}
              />
              <CustomIconButton
                icon={<img src={Download}  />}
                label=""
                onClick={async () => {
                  const fileName = item?.fileName;
                  await downloadFile(item?.url, fileName);
                }}
                variant="text"
                size="small"
                labelFont={16}
                classes={classes.download}
              />
            </Grid>}
          </Grid>
        ))}
      </Box>: <div className={classes.noAttach}>No Documents</div>
}
      {showDoc && (
        <DocumentViewer
          open={showDoc}
          onClose={() => {
            setShowDoc(null);
          }}
          title={"View Document"}
          documents={[{ uri: showDoc }]}
        />
      )}
    </Grid>
  );
};

export default Documents;
