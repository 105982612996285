import React from "react";
import { Button, PropTypes ,Tooltip} from "@material-ui/core";
import styles from "./style";
import clsx from "clsx";

interface ICustomIconButton {
    icon: React.ReactElement,
    label: string,
    onClick: (e:React.MouseEvent<HTMLButtonElement>) => void,
    variant: "text" | "outlined" | "contained",
    color?: PropTypes.Color | undefined,
    size?: "small" | "medium" | "large" | undefined,
    labelFont?: number,
    classes?: string,
    disabled?: boolean,
    iconPosition?: string,
    type?: any,
    tooltiptext?:string,
    tooltipplace?:any,
    tooltipclass?:any
}

const CustomIconButton = ({ icon, label, onClick, variant, color, size, labelFont, classes, disabled, iconPosition, type ,tooltiptext,tooltipplace,tooltipclass}: ICustomIconButton): React.ReactElement => {
    const rootClass = styles();
    return (
        <Tooltip title={tooltiptext ? tooltiptext : '' } classes={{ popper: tooltipclass }} placement={tooltipplace ? tooltipplace :'left'}>
        <Button  type={type} variant={variant} onClick={onClick} color={color} size={size} className={clsx(classes ,rootClass.resetFont)} disabled={disabled}>
            {!iconPosition ? icon : null}
            <span style={{ fontSize: labelFont, marginLeft: 1 }}>{label}</span>
            {iconPosition ? icon : null}
        </Button>
        </Tooltip>
    )
}

export default CustomIconButton;