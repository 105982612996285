import { useEffect ,useState} from "react";
import { useSelector } from "react-redux";
import { QueryCache, ReactQueryCacheProvider } from "react-query";
import Routes from "./routes";
import Layout from "./hoc/Layout";
import { authStorage } from "./utils/login";
import { useHistory } from "react-router-dom";
import ConsultationRender from "./pages/consultation/ConsultationRender";
import Notify from "./components/Notify";
import { Typography } from "@mui/material";
export default function App() {
  const history = useHistory();
  const queryCache = new QueryCache({
    defaultConfig: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
  const { isLoggedUser } = useSelector((state) => state.auth);
  const isAuthenticated = useSelector((state) =>
    authStorage.authToken && state.auth.user && !state.auth.inProgress
      ? true
      : false
  );
  
  useEffect(() => {
    if (!authStorage.authToken && isLoggedUser) {
      history.push("/logout");
    }
  }, []);

  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <Layout isAuthenticated={isAuthenticated}>
        <Routes isAuthenticated={isAuthenticated} />
      </Layout>
    </ReactQueryCacheProvider>
  );
}
