import { makeStyles, Grid, Button, Theme, Avatar } from '@material-ui/core';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import { Steps } from '../PreJoinScreens';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useAppState } from '../../../../state';
import useChatContext from '../../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { UpdateConnectingInfo, UpdateInstInfo, UpdateInstantType, UpdateCallbackInfo, UpdateRoomStatus, UpdateRemoveReceptionist } from '../../../../store/actions';
import useRoomState from '../../../../hooks/useRoomState/useRoomState';
import { useHistory } from 'react-router-dom';
import { USER_ROLES } from '../../../../utils/constants'
/* import EndCall from '../../../../assets/images/consultation/EndCall.svg' */
import { isPermissionDenied } from '../../../../utils/device';
import { sendMsg } from '../../../Websocket/InstantChatIO';
import useParticipants from '../../../../hooks/useParticipants/useParticipants';
import CallerTune from '../../../../assets/mp3/callerTune.mp3';
import { checkRoomIdValid } from '../../../../api/InstantVideo/Instant';
import FullScreenLoader from '../../../common/FullScreenLoader';
import { joinConsultation } from '../../../../api/consultation/consultation';
import { genericObj } from '../../../../models/common';


const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    width: '74px !important',
    height: '52px !important',
    '& .MuiSwitch-switchBase': {
      transitionDuration: '300ms',
      position: "absolute",
      padding: '9px',
      width: '58px',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        //width: '62px',
        '& + .MuiSwitch-track': {
          backgroundColor: '#1976d2',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
      marginTop: '6px'
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  },
  localPreviewContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 2.5em',
    },
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row-reverse',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        margin: '0.5em 0',
      },
    },

    "& .MuiButtonBase-root": {
      padding: '12px 56px'
    }
  },
  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1.5em 0 1em',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
  rootGrid: {
    color: 'transparent',
    background: '#252A36',
    display: 'flex',
    padding: '16px 12px 16px 24px',
    margin: '16px 0'
  },
  audioGrid: {
    display: 'flex',
    width: '50%',
    justifyContent: 'space-between',
    marginRight: '24px',
    paddingRight: '8px',
    borderRight: '1px solid #ffffff'
  },
  videoGrid: {
    display: 'flex',
    width: '50%',
    justifyContent: 'space-between'
  },
  countDown: {
    display: 'flex', justifyContent: 'center', fontSize: '26px'
  },
  infoText: {
    color: "#ffffff", display: 'flex', justifyContent: 'center'
  },
  avatarIcons: {
    backgroundColor: "#4965A5",
    width: '100px',
    height: '100px',
    borderRadius: "50%",
    margin: "16px 0 10px 0",
    fontSize: "2rem",
  },
  btn: {
    background: '#E22525',
    color: 'white',
    padding: '0px',
    minWidth: '0',
    borderRadius: '50%',
    marginTop: '24px',
    '&:hover': {
      background: '#600101',
    },
    "& .MuiButton-startIcon": {
      marginRight: '0'
    }
  },
  profileImage: {
    borderRadius: "50%",
    marginTop: "16px",
  }
}));

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  userId: string;
  // role: string | undefined;
  setStep: (step: Steps) => void;
  setCallJoined: any;
  location: any,
  isCallJoined?: boolean,
  patientName: string,
  img?: string,
  mediaError: Error | undefined
  setMediaError: (value: any) => void,
  loading: boolean;
  isCallBack: any;
  setIsCallBackUserJoined: any;
  isCallBackUserJoind: boolean;
  reference:any;
  setRequest:any;
  setError : (value) => void
  setVideoCollapsed:(value : boolean) => void
}
var timerCompleted: boolean = false;
var TimerReject;
var callBackTimer;
export default function DeviceSelectionScreen({ name, roomName, setStep, userId, /* role, */ setCallJoined, location, isCallJoined, patientName, img, mediaError, setMediaError, loading, isCallBack, setIsCallBackUserJoined, isCallBackUserJoind, reference, setRequest , setError,setVideoCollapsed}: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { instantdata, connecting, type, callBackData, isDuplicateTab, errorCode, showRemoveReceptionist ,callID,bookedId:booked_Id} = useSelector((state: any) => state?.instantVideo)
  const { getToken, isFetching } = useAppState();
  const { connect: chatConnect } = useChatContext();
  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting, setUserId } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const { userRole, user } = useSelector((state: RootStateOrAny) => state?.auth);
  const [showConnectng, setShowConnecting] = useState<boolean>(false)
  const [callBacktoken, setCallBacktoken] = useState<string>('');
  const row: genericObj = location?.state?.row || {}
  const roomState = useRoomState();
  var TimeCount: any;
  const searchVal = location.search.split('?')[1];
  let CallBack = searchVal?.split('/').length > 1;
  const participants = useParticipants();
  const [ShowLoader, setShowLoader] =  useState<boolean>(false)
  const { pickup } = useSelector((state: RootStateOrAny) => state?.call);

  useEffect(()=>{
    if(isDuplicateTab && sessionStorage.getItem("currentTabDuplicate")){
      timerCompleted = true
      TimerReject && clearInterval(TimerReject);
      callBackTimer && clearInterval(callBackTimer);
    }
  },[isDuplicateTab])

  //Fix for the Bug 32401
  useEffect(()=>{
    if(pickup && !CallBack && instantdata?.type !== "VIRTUAL_CONSULTATION"){
      setError({ code: -100001, message: 'You are not part of this consultation' })
    }
  },[])

  const recepEndCall = () => {
    let Data = {
      callDetailLogId: isCallBack?.row?.data?._id,
      role: userRole === USER_ROLES.receptionist ? "Receptionist" : userRole
    }
    sendMsg('recepitionistEndCall', Data);
  }

  useEffect(()=>{
    if(errorCode !== ''){
      if(CallBack){
        callBackTimer && clearInterval(callBackTimer);
        recepEndCall()
      }
    }
  },[errorCode])
  const handleJoin = async () => {
    const [IS_CALL_BACK,CALL_ID,ROW,INSTANT_DATA,BOOKED_ID]=[CallBack,callID,row,instantdata,booked_Id]; /*Block Scope Variables make sure value is not undefines */
    sessionStorage.removeItem("new-consulation");
    if (isCallBack?.row?.data && callBackData?.status === 'ACCEPTED') {
      let Data = {
        callDetailLogId: isCallBack?.row?.data?._id,
        patientId: isCallBack?.row?.data?.userId,
        userId: user?._id,
        role: userRole === USER_ROLES.receptionist ? "Receptionist" : userRole,
        isCheckStatus: "false"
      }
      setShowLoader(true)
      sendMsg('recepitionistCallback', Data);
      sessionStorage.setItem('CallBackConnecting','true'); /* Scenario to call Reject on Browser back before Patient Join */
    } else {
      timerCompleted = true
      clearInterval(TimerReject);
      setShowLoader(true)
      const consultationType = location.pathname === '/Appointments/room' ? "VIRTUAL_CONSULTATION" : "INSTANT"
      try{
        const isRefreshCallBack = CallBack && callBackData?.status === 'CALL_BACK_TOKEN_STORED'
        const res = await checkRoomIdValid(roomName, consultationType, userRole, CallBack, isRefreshCallBack)
        if(res?.data?.roomStatus === "UNAVAILABLE" && res?.data?.state === "AVAILABLE" &&  !CallBack){
          setVideoCollapsed(true);
          setShowLoader(false)
          dispatch(UpdateRoomStatus(false));
          setRequest({
            message: res?.data?.message || "Patient end the call",
            loading: false,
            severity: "error",
          });
        }
        else if( res?.data?.state === "UNAVAILABLE" &&  !CallBack) 
        {
          setShowLoader(false)
          handleEnd(true);
          setError({ code: -100001, message:  res?.data?.message || 'Invalid Meeting Id' })
          return ;
        }
        else{
          /* Link consulation ID for re-join process */
          if(!IS_CALL_BACK)
            {
              try{
                await joinConsultation({
                  bookedSlotId: IS_CALL_BACK ? CALL_ID?.split('/')[0] : ROW?.row?._id ? ROW?.row?._id : INSTANT_DATA?.bookedId ? INSTANT_DATA?.bookedId : BOOKED_ID,
                  callLogId :  IS_CALL_BACK ? CALL_ID?.split('/')[1] : INSTANT_DATA?.callId,
              });
              }
              catch(err){}
            }
          await getToken(userId, roomName, userRole === USER_ROLES.receptionist ? "Receptionist" : USER_ROLES.doctor ? "Doctor" : userRole, consultationType).then(({ token }) => {
            videoConnect(token);
            setUserId(userId);
            process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect?.(token);
            setShowLoader(false)
          });
          setVideoCollapsed(false);
        }
      }
      catch(error)
      {
        setShowLoader(false)
        setError({ code: -100001, message: error?.response?.data?.message ||'Invalid Meeting Id' })
        return ;
      }  
     
      if (CallBack) {
        setIsCallBackUserJoined(false);
        setCallJoined(false);
        setShowConnecting(true);
        callBackTimer = setInterval(() => {
          if (callBackTimer) {
            clearInterval(callBackTimer);
            //recepEndCall();
            history.push({ pathname: "/Dashboard", state: "Patient Not Accepted the call" });
          }
        }, 120000);
      } else {
        setCallJoined(true);
        setIsCallBackUserJoined(true);
        setShowConnecting(false)
      }
      dispatch(UpdateInstantType(''));
      sendMsg('updateStatus',{'userId': user?._id, 'status': 'busy'});
      /* This Accept makes sure the Rep/doctor has accepted the call */
      if(instantdata?.callId)
      (userRole === USER_ROLES.receptionist ? sendMsg('receptionistAccepct', {callId: instantdata?.callId}) : sendMsg('doctorAccepted', {callId: instantdata?.callId}));
      /*When rep/doctor browser back before the join process has been finished re-join is need to trigger */
      if(!["/InstantCall/room","/Appointments/room"].includes(window.location.pathname) && !IS_CALL_BACK)
        {
          /* setTimeout is used where the reponse for accept and first check need a gap */
          if(!document.getElementById("rejoinButton"))
            setTimeout(() =>{
             userRole === USER_ROLES.receptionist ? sendMsg('receptionistFirstCheck',{userId: user?._id}) : sendMsg('doctorFirstCheck',{userId: user?._id})
           },1000);
        }  
    }
  };

  useEffect(() => {
    return () => {
      if (callBackTimer) {
        clearInterval(callBackTimer);
      }
    }
  }, [])


  useEffect(() => {
    if (participants.length > 0) {
      const partici = participants[0]
      if (JSON.parse(partici.identity).role === 'UNIFIED_APP_CONSUMER' && callBackTimer) {
        clearInterval(callBackTimer);
      }
    }
  }, [participants.length])

  useEffect(() => {
    if (callBackData?.type === 'CALLBACK') {
      if (callBackData?.status === 'CALL_BACK_TOKEN') {
        //sessionStorage.setItem('CALLBACK_INITIATED', 'true');
        setShowLoader(false)
        setShowConnecting(true)
        dispatch(UpdateCallbackInfo({
          ...callBackData,
          status: 'CALL_BACK_TOKEN_STORED',
          photo: isCallBack?.row?.data?.photo,
          token: callBackData?.data?.token,
          name: isCallBack?.row?.data?.name?.first + " " + isCallBack?.row?.data?.name?.last
        }));
        dispatch(UpdateInstInfo({
          ...instantdata,
          photo: isCallBack?.row?.data?.photo,
          token: callBackData?.data?.token,
          name: isCallBack?.row?.data?.name?.first + " " + isCallBack?.row?.data?.name?.last
        }))
        setCallBacktoken(callBackData?.data?.token)
        callBackTimer = setInterval(() => {
          if (callBackTimer) {
            clearInterval(callBackTimer);
            recepEndCall();
            history.push({ pathname: "/Dashboard", state: "Patient Not Accepted the call" });
          }
        }, 120000)
        connectCallbackRoom();
      } else if (callBackData?.status === 'BUSY' || callBackData?.status === 'USER_REJECTED') {
        clearInterval(callBackTimer);
        history.push('/Dashboard')
      }
    }
  }, [callBackData])

  const connectCallbackRoom = async () => {
    process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect?.(callBackData?.data?.token);
    setIsCallBackUserJoined(false);
    setCallJoined(false);
    setUserId(userId);
    setShowConnecting(true);
    videoConnect(callBackData?.data?.token || callBacktoken);
  }

  useEffect(() => {
    if(pickup)
    {
      clearInterval(TimerReject);
    }
    else
    {
    if (connecting?.split)
      dispatch(UpdateConnectingInfo({ ...connecting, split: false, show: false, name: '' }));
    if (location.pathname === '/InstantCall/room' && (userRole === USER_ROLES.doctor || userRole === USER_ROLES.receptionist) && type === 'Initial') {
      handleDoctorJoining();
      setTimeout(()=>{TimerUI()},100)
    }
    if (location.pathname === '/InstantCall/room' && userRole === USER_ROLES.receptionist) {
      dispatch(UpdateConnectingInfo({}))
    }
    showRemoveReceptionist && dispatch(UpdateRemoveReceptionist(false))
  }
  }, [pickup,type])

  const handleDoctorJoining = () => {
    TimerReject = setInterval(() => {
      if (!timerCompleted && roomState === 'disconnected') {
        handleEnd();
        TimerReject && clearInterval(TimerReject);
      }
    }, 15000)
  }
  useEffect(() => {
    return () => {
      if (!timerCompleted && roomState === 'disconnected' && !CallBack) {
        //handleEnd();
        TimerReject && clearInterval(TimerReject);
      }
      timerCompleted = false
    }
  }, [])

  const TimerUI = () => {
    TimeCount && clearInterval(TimeCount);
    var countdownNumberEl = document.getElementById("countdownnumber");
    var countdown = 15;
    if (countdownNumberEl !== null) {
      getTimerCount(countdownNumberEl, countdown)
      if(document.getElementById("twillio-error"))
        {
          TimeCount && clearInterval(TimeCount);
          TimerReject && clearInterval(TimerReject);
        }
      TimeCount = setInterval(function () {
        if (--countdown <= 0) {
          TimeCount && clearInterval(TimeCount);
        }
        getTimerCount(countdownNumberEl, countdown)
      }, 950);
    }
  };

  const getTimerCount = (countdownNumberEl: HTMLElement | null, countdown: number) => {
    if (countdownNumberEl) {
      if (countdown > 10) {
        countdownNumberEl.style.color = '#24B648'
      } else if (countdown <= 10 && countdown > 5) {
        countdownNumberEl.style.color = '#D1A12A'
      } else {
        countdownNumberEl.style.color = '#E02F3F'
      }
    }
    if (countdownNumberEl !== null) {
      countdownNumberEl.textContent = countdown >= 10 ? "00 : 00 : " + countdown.toString() : "00 : 00 : 0" + countdown.toString();
    }
    return countdownNumberEl
  }

  const handleEnd = (doNotRoute? : boolean) => {
    if(sessionStorage.getItem("new-consulation"))
    {
      if (location.pathname === '/InstantCall/room' && userRole === USER_ROLES.doctor && instantdata?.type !== "RECONNECT") {
        sendMsg('doctorReject', {callId: instantdata?.callId, role: userRole})
      } else if (location.pathname === '/InstantCall/room' && userRole === USER_ROLES.receptionist && instantdata?.type !== "RECONNECT") {
        sendMsg('receptionistReject', {callId: instantdata?.callId, userId: user?.userId, role: userRole})
      }
      sessionStorage.removeItem("new-consulation");
      if(!doNotRoute)
      location.pathname === '/InstantCall/room' ? history.push('/Dashboard') : history.push('/Appointments');
    }
    //  history.go(0);
  }

  const getInitials = () => {
    return location.pathname === '/Appointments/room' ? patientName?.split(' ').map((name: string) => name[0]).join('').toUpperCase() :
      instantdata?.name?.split(' ').map((name: string) => name[0]).join('').toUpperCase()
  }

  const PreJoinCheck = async () => {
    const isCameraPermissionDenied = await isPermissionDenied('camera');
    const isMicrophonePermissionDenied = await isPermissionDenied('microphone');
    if (isCameraPermissionDenied && isMicrophonePermissionDenied) {
      const error = new Error();
      setMediaError(error);
    }
    else
      handleJoin();
  }

  return (
    <>
      {((isFetching || isConnecting) && CallBack || ShowLoader) && <><FullScreenLoader /><span id={'joinnow-loader'}></span></>}
      <Grid container justifyContent="center" alignContent='center' style={{ background: '#000000' }}>
        {((!(isFetching || isConnecting)) && (!(callBackData?.type === 'CALLBACK' && showConnectng)) && (!(callBackData?.status === 'CALL_BACK_TOKEN' && !isCallBackUserJoind))) && <Grid item md={5} sm={12} xs={12} direction="column">
          <div className={classes.localPreviewContainer} style={{ paddingTop: '32px' }}>
            <LocalVideoPreview identity={name} />
          </div>
          <Grid className={classes.rootGrid} direction="row">
            <Grid direction="row" className={classes.audioGrid}>
              <ToggleAudioButton className={classes.deviceButton} disabled={disableButtons} roomState={roomState} />
            </Grid>
            <Grid direction="row" className={classes.videoGrid}>
              <ToggleVideoButton className={classes.deviceButton} disabled={disableButtons} roomState={roomState} />
            </Grid>
          </Grid>
          <div style={{ marginBottom: '16px' }}>
            {location.pathname === '/InstantCall/room' && !(document.getElementById("twillio-error")) && (userRole === USER_ROLES.doctor || userRole === USER_ROLES.receptionist) && type === 'Initial' && !(isDuplicateTab && sessionStorage.getItem("currentTabDuplicate")) && <>
              <div id="countdownnumber" className={classes.countDown}></div>
              <div className={classes.infoText}>If you don't join before the time runs out, you will be disconnected.</div>
            </>}
          </div>
          <div className={classes.joinButtons}>
            <Button
              variant="contained"
              color="primary"
              data-cy-join-now
              onClick={PreJoinCheck}
              disabled={disableButtons || Boolean(mediaError) || loading || (CallBack && instantdata?.type === "INSTANT")}
              style={{ backgroundColor: '#35C5EA', marginLeft: '8px' }}
            >
              Join Now
            </Button>
            <Button
              variant="contained"
              data-cy-join-now
              onClick={() => {
                dispatch(UpdateInstantType(''))
                if (!CallBack) {
                  if(location.pathname === '/Appointments/room')
                  {
                    sendMsg('updateStatus',{'userId': user?._id, 'status': 'online'})
                  }
                } else {
                  dispatch(UpdateCallbackInfo({}))
                }
                location.pathname === '/InstantCall/room' ? history.push('/Dashboard') : history.push('/Appointments');
              }}
              disabled={disableButtons}
              style={{ backgroundColor: '#e0e0e0', marginLeft: '8px' }}
            >
              Cancel
            </Button>
          </div>
        </Grid>}
        {((isFetching || isConnecting) || (callBackData?.type === 'CALLBACK' && showConnectng) || (callBackData?.status === 'CALL_BACK_TOKEN' && !isCallBackUserJoind)) && <Grid item md={6} sm={12} xs={12} direction="column" style={{ display: 'flex', alignItems: 'center' }}>
          {instantdata?.photo || (img && img !== '') ?
            <div style={{ marginBottom: "10px" }}>
              <img src={instantdata?.photo || img} className={classes.profileImage} alt="profile img" width="100px" height="100px" />
            </div> :
            <Avatar className={classes.avatarIcons}>{getInitials()}</Avatar>
          }
          <span id = {"twilio-connecting"}style={{ display: 'flex', justifyContent: "center", color: "#ffffff" }}>Connecting With {instantdata?.name || patientName}</span>
          <audio src={CallerTune} autoPlay loop/>
          {/* {!CallBack && <Button
            onClick={() => {
              handleEnd()
            }}
            className={classes.btn}
            startIcon={<img src={EndCall} />}
            data-cy-disconnect
          ></Button>} */}
        </Grid>}
      </Grid>
    </>
  );
}
