import { makeStyles, Theme } from "@material-ui/core";

const styles = makeStyles<Theme>((theme) => ({
  root: {
    userSelect: "none",
    "& .hidden": {
      display: "none",
    },
    "& .Mui-disabled": {
      opacity: "0.7 !important",
    },
   
  },
  title: {
    fontSize: "15px !important",
    fontWeight: 600,
    marginBottom: "8px",
    "&.MuiTypography-colorPrimary": {
      cursor: "pointer",
    },
  },
  patientType: {
    "& .MuiRadio-root[hidden]": {
      display: "none",
    },
    "& label": {
      cursor: "pointer",
    },
    marginBottom: "12px",
  },
  patientTypeChoice: {
    textAlign: "center",
    marginRight: "10px",
    padding: "8px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #A8AEBC",
    borderRadius: "5px",
    width: "160px",
    color: "#A8AEBC",
    "& .MuiTypography-body2": {
      fontSize: "14px !important",
    },
    "&.active": {
      backgroundColor: "#F2F6FF !important",
      border: "1px solid #34519C",
      color: "#34519C !important",
      "& .MuiTypography-body2": {
        fontWeight: 600,
      },
    },
  },
  patientList: {
    maxHeight: "290px",
    overflowY: "auto",
    scrollBehavior: "smooth",
    "&::-webkit-scrollbar": {
      width: "7px",
      borderRadius: "15px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#E4E4E4",
    },
  },
  patientListItem: {
    margin: "5px",
    cursor: "pointer",
    "& .patientName": {
      fontSize: "14px",
    },
    "& .mobile": {
      fontSize: "12px",
      color: "#979797",
    },
  },
  userDisplayPic: {
    width: "32px",
    height: "32px",
    fontSize: "12px",
    borderRadius: "50%",
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.2)",
    marginRight: "10px",
    color: "#FFFFFF",
    backgroundColor: "#4965A5",
  },
  noPatient: {
    "& .MuiBox-root": {
      padding: "0 !important",
      "& .MuiBox-root": {
        "&:first-child": {
          width: "unset !important",
          height: "unset !important",
          padding: "20px !important",
        },
      },
    },
  },
  addPatientInformation: {
    "& .MuiGrid-grid-xs-6": {
      maxWidth: "49%",
      marginBottom: "12px",
    },
  },
  createPatient: {
    marginBottom: "12px",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F4F4F4 !important",
    },
  },
  policyDetail: {
    fontSize: "13px",
    "& .MuiGrid-container": {
      width: "auto",
    },
    "& .balanceText": {
      fontWeight: 600,
      color: "#64707D",
      fontSize: "13px",
    },
  },
  options: {
    display: "flex !important",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    background: "#ffffff",

    "& .MuiSvgIcon-root": {
      color: "#35C5EA",
    },
  },
  walletInformation: {
    margin: "10px 0",
    backgroundColor: "#F3F7FF",
    borderRadius: "8px",
    padding: "10px 20px",
    "& .MuiTypography-body2": {
      fontSize: "14px !important",
    },
    "& .walletInfo": {
      marginLeft: "10px",
    },
    "& .walletNote": {
      fontSize: "12px !important",
      color: "#64707D",
    },
    "& .walletBalance": {
      color: "#64707D",
    },
  },
  appointmentType: {
    "& .MuiRadio-root[hidden]": {
      display: "none",
    },
    "& label": {
      cursor: "pointer",
    },
    marginBottom: "12px",
  },
  appointmentTypeChoice: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: "15px",
    padding: "20px 0px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #A8AEBC",
    borderRadius: "4px",
    width: "160px",
    color: "#A8AEBC",
    "& .MuiTypography-body2": {
      fontSize: "14px !important",
    },
    "&.active": {
      backgroundColor: "#F2F6FF !important",
      border: "1px solid #34519C",
      color: "#34519C !important",
      "& .MuiTypography-body2": {
        fontWeight: 600,
      },
    },
    "&[hidden]": {
      color: "#A8AEBC !important",
      backgroundColor: "#FFFFFF  !important",
      border: "1px solid #A8AEBC  !important",
    },
  },
  consultInformation: {
    gap: "12px",
    marginBottom: "12px",
    "& .MuiGrid-grid-xs-6": {
      maxWidth: "49%",
    },
    "& .MuiIconButton-root": {
      color: "#8F8F8F",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F4F4F4 !important",
      height: "50px",
      borderRadius: "4px !important",
    },
    "& legent": {
      maxWidth: "0 !important",
    },
    "& .MuiCheckbox-colorPrimary.Mui-checked" :{
      color: "#32529D"
  }
  },
  availableTime: {
    "& .MuiRadio-root[hidden]": {
      display: "none",
    },
    marginBottom: "12px",
    "& .MuiTypography-body2": {
      fontSize: "14px !important",
    },
  },
  timeSlots: {
    border: "1px solid #D0D8EA",
    padding: "10px 0",
    width: "100px",
    textAlign: "center",
    cursor: "pointer",
    "& .MuiTypography-body2": {
      fontSize: "14px !important",
    },
    borderRadius: "6px",
    margin: "0px 7px 7px 7px",
    "&.active": {
      backgroundColor: "#34519C !important",
      border: "1px solid #34519C",
      "& .MuiTypography-body2": {
        fontWeight: 600,
        color: "#FFFFFF",
      },
    },
  },
  noSlots: {
    fontSize: "20px !important",
    fontWeight: 600,
    margin: "2.5rem auto",
    "&.MuiTypography-colorPrimary": {
      cursor: "pointer",
    },
  },
  paymentInformation: {
    "& .infoGrid": {
      backgroundColor: "#F4F4F4",
      color: "#223345",
      borderRadius: "6px",
      "& .MuiTypography-body2": {
        fontSize: "14px",
        flex: 1,
      },
      "& .MuiGrid-justify-content-xs-space-between": {
        padding: "12px",
      },
    },
  },
  dropDown: {
    "& .MuiSelect-select": {
      color: "#717171 !important",
    },
  },
  dropDownvalue: {
    "& .MuiSelect-select": {
      color: "#000000 !important",
      fontWeight: 600,
    },
  },

  drop: {
    "& .MuiSelect-select": {
      padding: "11px !important",
    },
  },
  specialitiesTypes: {
    "& .MuiAutocomplete-inputRoot": {
      overflowY: "hidden !important",
    },
  },
  TimeHide: {
    opacity: "0.6 !important",
    background: "lightgrey !important",
    cursor: "no-drop",
  },
  dates: {
    "& .background-color: #F4F4F4;": {
      background: "#F4F4F4",
      borderRadius: "4px",
    },
  },
  rerferContainer:{
   display:"flex",
   flexDirection:'column'
  },
  referInternal:{
    opacity:0.4,
    "& .MuiIconButton-colorPrimary:hover": {
      cursor:'default',
      backgroundColor: "#ffffff !important"
  },
  "& .MuiFormControlLabel-root":{
    cursor:'default !important',
  }
  }
}));

export default styles;
