import { useRef, useEffect, useState } from 'react';
import { IVideoTrack } from '../../../types';
import { styled } from '@material-ui/core/styles';
import { Track } from 'twilio-video';
import useMediaStreamTrack from '../../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoTrackDimensions from '../../../hooks/useVideoTrackDimensions/useVideoTrackDimensions';
import { useSelector } from 'react-redux';

const Video = styled('video')({
  width: '100%',
  height: '100%',
});

interface VideoTrackProps {
  track: IVideoTrack;
  isLocal?: boolean;
  priority?: Track.Priority | null;
  videoType?: string;
}

export default function VideoTrack({ track, isLocal, priority, videoType }: VideoTrackProps) {
  const ref = useRef<HTMLVideoElement>(null!);
  const mediaStreamTrack = useMediaStreamTrack(track);
  const dimensions = useVideoTrackDimensions(track);
  const { cameraFacingMode } = useSelector((state: any) => state?.instantVideo)
  const isPortrait = (dimensions?.height ?? 0) > (dimensions?.width ?? 0);
  const [_cameraMode,setCameraMode]=useState("rotateY(180deg)");

  useEffect(() => {
    if(cameraFacingMode)
    !isLocal && setTimeout(() => {setCameraMode(cameraFacingMode)},500); /* Exception for Local Participant */
    else
    setCameraMode("rotateY(180deg)"); /* Default Front-Facing */
  },[cameraFacingMode])
  useEffect(() => {
    const el = ref.current;
    el.muted = true;
    if (track.setPriority && priority) {
      track.setPriority(priority);
    }
    track.attach(el);
    return () => {
      track.detach(el);

      // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
      // See: https://github.com/twilio/twilio-video.js/issues/1528
      el.srcObject = null;

      if (track.setPriority && priority) {
        // Passing `null` to setPriority will set the track's priority to that which it was published with.
        track.setPriority(null);
      }
    };
  }, [track, priority]);

  // The local video track is mirrored if it is not facing the environment.
   const isFrontFacing = mediaStreamTrack?.getSettings().facingMode !== 'environment';
  const style = {
    transform: isLocal ?  isFrontFacing ? 'rotateY(180deg)' : '' : 'rotateY(360deg)',
    objectFit: isPortrait || track.name.includes('screen') ? ('contain' as const) : ('cover' as const),
  };

  return <Video ref={ref} style={style} id={videoType} />;
}
