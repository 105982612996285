import { makeStyles, withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

export const StyledTableCell = withStyles((theme) => ({
  head: {
    color: "#717171",
    fontWeight: 500,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    color: "#223345"
  },
}))(TableRow);

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
    color: "white",
  },
  table: {
    borderCollapse: "collapse",
    border: "1px solid #F3F7F9",
    "& tr": {
      fontSize: "16px",
      border: "1px solid #F3F7F9 !important",
      overflowWrap: "break-word",
    },
    "& .MuiTableCell-root": {
      borderBottom: "none"
    },
    "& td": {
      color: "#000000",
      fontWeight: 400,
      position: "relative",
      overflowWrap: "anywhere",
      padding: '10px 14px',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontSize: "12px",
      [theme.breakpoints.down('md')]:{
        padding: '8px 11px',
        fontSize: "11px",
     },
    },
    "& th":{
      fontSize: "13px",
      padding: "10px 14px",
      [theme.breakpoints.down('md')]:{
        fontSize: "12px !important",
        padding: "5px 8px",
     },
    }
  },
  paginationContainer: {
    // marginTop: "30px",
    "& ul": {
      justifyContent: "center",
      "& li button": {
        height: "32px",
        width: "32px",
        backgroundColor: "transparent",
        fontSize: "13px",
        color: "#223345",
        borderRadius: "50%",
        fontWeight: 600
      },
      "& li button.Mui-selected, & li button:hover": {
        backgroundColor: "#32529D",
        color: "white",
      },
      "& li button.navigation": {
        width: "auto"
      }
    },
  },
  center: { textAlign: "center" },
}));
