import io from 'socket.io-client';
import { authStorage } from '../../utils/login';
export var sendMsg =(value1?,value2?)=>{}, closeSocket =()=>{}, UpdateAction ,chatRoomDetail ={},currentRoomID,socketConnection;
export const ChatRoomDetails =()=>{
return chatRoomDetail;
}
export const ValueSet = (value) =>{
  chatRoomDetail = value ;
}
export const setcurrentRoomID = (value) => {
  currentRoomID = value ;
}
export const getcurrentRoomID = () => {
  return currentRoomID ;
}
export const instantChatIO = (fn ,userId) => {
  UpdateAction = fn.bind(fn);
  const socket = io(`${process.env.REACT_APP_INSTANT_WS}`, {
    auth:{ token : authStorage.authToken},
    path: `${process.env.REACT_APP_SOCKET_IO}`,
    reconnection: true,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 1000,
    //timeout: 20000,
    transports: ["websocket", "polling"],
    query:{
      userId : userId ,
      portal : 'web',
    }
  });
  socketConnection = socket.on.bind(socket);
  socket.on("connect_error", (reason) => {
    UpdateAction({ action: 'RECONNECTING', data: true })
  });

  socket.on("connect", () => {
    UpdateAction({ action: 'CONNECTED', data: true }) ;
   });

  socket.on("disconnect", () => {
    UpdateAction({ action: 'DISCONNECTED', data: false }) ;
  });

  socket.on("callBack", (event) => {
    const eventData = JSON.parse(event)
    UpdateAction({ action: eventData?.type, data: eventData })
  });

  socket.on("message", (event) => {
    UpdateAction({ action: 'UPDATE_MESSAGE_LIST', data: JSON.parse(event) })
  });

  socket.on("roomData",(event)=>{
    const eventData = JSON.parse(event)
    if(eventData?.type === 'USER_INFORMATION')
    UpdateAction({ action: 'USER_INFORMATION_ACK', data: eventData })
    else
    chatRoomDetail=eventData;
  })

  socket.on("reconnect", (event) => {
    UpdateAction({ action: 'RECONNECT', data: JSON.parse(event) })
  });

  socket.on("chat", (event) => {
    UpdateAction({ action: 'CHAT', data: JSON.parse(event) })
  });

  socket.on("instant", (event) => {
    const eventData = JSON.parse(event)
    if(eventData?.type === 'INSTANT'){
      UpdateAction({ action: 'INSTANT', data: eventData })
    }
  });

  socket.on("doctorIncomingCall", (event) => {
      UpdateAction({ action: 'INSTANT', data: JSON.parse(event) })
  });

  socket.on("tabNotification", (event) => {
    UpdateAction({ action: 'TAB_NOTIFICATION', data: JSON.parse(event) })
  });

  socket.on("PONG", (event)=>{
    UpdateAction({ action: 'PONG', data: event?.message })
  });

  socket.on("status", (event) => {
    UpdateAction({ action: 'ONLINE_STATUS', data: JSON.parse(event) })
  });

  socket.on("typingNotification", (event) => {
    UpdateAction({ action: 'DOCTOR_TYPING', data: event })
  });

  socket.on("statusNotification", (event) => {
    const eventData = JSON.parse(event) ; 
    if(['userEnded','userLeft'].includes(eventData?.action ))
    {
      ["/Appointments/room","/InstantCall/room","/chat-consulation"].includes(window?.location?.pathname) && UpdateAction({ action: 'PATIENT_DISCONNECT', data: eventData, type : eventData?.type});
        eventData?.action === 'userEnded' && UpdateAction({ action: 'STATUS_NOTIFICATION', data: JSON.parse(event) })
    }
  });

  socket.on("unattended",(event) => {
    UpdateAction({ action: 'UNATTENDED', data: JSON.parse(event) })
  })
  
  socket.on("notification",(event) => {
    UpdateAction({ action: 'NOTIFICATION', data: JSON.parse(event) })
  })

  socket.on("callbackBookIdInformation",(event) => {
    UpdateAction({ action: 'callbackBookIdInformation', data: JSON.parse(event) })
  })

  socket.on("doctorOnCall",(event) => {
    const eventData = JSON.parse(event) ; 
    const {methodName = '',isDoctorOnCall} = eventData ;
    if(methodName && methodName === "addDoctorForCall" && isDoctorOnCall)
    {
      UpdateAction({ action: 'isdoctoroncall', data: JSON.parse(event) })
    }
  })

  sendMsg = socket.emit.bind(socket);
  closeSocket = socket.disconnect.bind(socket);
}