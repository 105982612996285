import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditIcon from '@material-ui/icons/Edit';
import makeStyles from './styles'




const ThreeDotsMenu = ({data,handleMenuView,handleResendInvite,handleMenuEdit,handleMenuDelete,handleMenuClone,displayIcon,handleMenuEditFee,handleMenuDeactivateUser}:any) => {
    const classes = makeStyles();
    const [anchorEl, setAnchorEl] = useState<any>(null);


    const handleClick = (e: any) => {
        setAnchorEl(e.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <React.Fragment>
            <Button onClick={handleClick}>
              <MoreVertIcon />
            </Button>
            <Menu id="card-actions-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            {handleMenuView && <MenuItem className={classes.ActionButton} onClick={() => {handleMenuView(data);handleClose();}}>
                    {!displayIcon ? "" : <VisibilityOutlinedIcon/>} View Details
                </MenuItem>}
                {handleMenuEdit && <MenuItem className={classes.ActionButton} onClick={() => {handleMenuEdit(data);handleClose();}}>
                    {!displayIcon ? "" : <EditIcon/>} Edit
                </MenuItem>}
                {handleMenuClone && <MenuItem className={classes.ActionButton} onClick={() => {handleMenuClone(data);handleClose();}}>
                     Clone
                </MenuItem>}
                {handleMenuDelete && <MenuItem className={classes.ActionButton} onClick={() => {handleMenuDelete(data);handleClose();}}>
                    {!displayIcon ? "" : <DeleteOutlineOutlinedIcon/>} Delete
                </MenuItem>}
                {handleResendInvite && <MenuItem className={classes.ActionButton} onClick={() => {handleResendInvite(data);handleClose();}}>
                    {!displayIcon ? "" : <DeleteOutlineOutlinedIcon/>} Re-send Invite
                </MenuItem>}
                {handleMenuEditFee && <MenuItem className={classes.ActionButton} onClick={() => {handleMenuEditFee(data);handleClose();}}>
                    {!displayIcon ? "" : <DeleteOutlineOutlinedIcon/>} Edit Fee
                    </MenuItem>}
                {handleMenuDeactivateUser && <MenuItem className={classes.ActionButton} onClick={() => {handleMenuDeactivateUser(data);handleClose();}}>
                    {!displayIcon ? "" : <DeleteOutlineOutlinedIcon/>} Deactivate User
                </MenuItem>}
            </Menu>
        </React.Fragment>
    )
}
export default ThreeDotsMenu;