import * as actionTypes from "../actions/actionTypes";

const initialState = {
    chatRoomList :[],
    chatUserInfo:{},
    chatParticipantInfo : {},
    chatRoomInfo:{},
    msgList : [],
    loadingHistory:false,
    hasMore : true,
    fetchingNewMessage : false,
    isChatWidgetEnabled: true,
    openChatList:[],
    openChatDetails: {},
    openChatRooms: []
};

const chat = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_WEBSOCKET:
      return { ...state, chatUserInfo: action.payload };

    case actionTypes.CHAT_ROOM_LIST:
        return { ...state, chatRoomList: action.payload };

    case actionTypes.CHAT_ROOM_INFO:
      return { ...state, chatRoomInfo: action.payload };

    case actionTypes.CHAT_ROOM_PARTICIPANT_INFO:
      return { ...state, chatParticipantInfo: action.payload };

    case actionTypes.CHAT_MESSAGE_LIST:
      return { ...state, msgList: action.payload };

    case actionTypes.CHAT_OTHER_INFO:
      return { ...state, 
       loadingHistory : action.payload.loadingHistory,
       hasMore : action.payload.hasMore,
       fetchingNewMessage : action.payload.fetchingNewMessage
      };

      case actionTypes.OPEN_CHAT_LIST:
        return { ...state, openChatList: action.payload };

      case actionTypes.ENABLE_CHAT_WIDGET:
        return { ...state, isChatWidgetEnabled: action.payload };

      case actionTypes.OPEN_CHAT_DETAILS:
        return { ...state, openChatDetails: action.payload };

      case actionTypes.OPEN_CHAT_ROOMS:
        return { ...state, openChatRooms: action.payload };        
    default:
      return state;
  }
};

export default chat;
