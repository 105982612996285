import { useEffect, useState } from "react";
import { networkSpeedCalc } from "../../utils/networkSpeed";
import { Typography } from "@mui/material";
import SignalCellularConnectedNoInternet3BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet3Bar';
import { workerPingInterval as webWorker, latencyWorker } from "../../utils/networkDetector";
//import { closeSocket } from "../Websocket/InstantChatIO";
import { RootStateOrAny, useSelector } from "react-redux";
import React from "react";
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import CloseIcon from '@mui/icons-material/Close';
let timer;

const index: React.FC = () => {
  const [count,setCount]=useState(0);
  const [show,setShow]=useState<boolean>(false);
  const { dbData } = useSelector((state: RootStateOrAny) => state?.auth);
  const [fetchLatency, setFetchLatency]=useState<boolean>(false);
  const [showRefresh, setShowRefresh]=useState<boolean>(false);
 // const dispatch = useDispatch();
  /* Function to detect Network drop and Up count */

  useEffect(()=>{
    return ()=>{
      timer && clearTimeout(timer)
    }
  },[])

  const getTimer = () => {
    return fetchLatency;
  }
  const UpdateDropCount = (value) => {
    setCount(value);
    const latCount =dbData?.LATENCY_REQUEST_RETRY_COUNT? dbData?.LATENCY_REQUEST_RETRY_COUNT:3
    if (value > latCount && !show){
      setShow(true);
      !showRefresh && setShowRefresh(true);
    }
    else if (value === 0 && show){
      setShow(false);
    } 
  };

  /* Webwork Callback execution on Every interval */
  webWorker.onmessage = (res) => {
    networkSpeedCalc(UpdateDropCount, count, dbData, getTimer);
  };

  //Stops Latency checker untill getting response from API
  latencyWorker.onmessage = (res) => {
    if(res?.data){
      setFetchLatency(true)
    } else {
      setFetchLatency(false)
    }
  }

/* After UI render Latency Hits starts */
  useEffect(() => {
    webWorker.postMessage({COMMAND:"START", INTERVAL:dbData?.LATENCY_REQUEST_RETRY_INTERVAL_MILLISECOND? dbData?.LATENCY_REQUEST_RETRY_INTERVAL_MILLISECOND:2000});
    return () => 
    {
      webWorker.postMessage({COMMAND:"END", INTERVAL:dbData?.LATENCY_REQUEST_RETRY_INTERVAL_MILLISECOND? dbData?.LATENCY_REQUEST_RETRY_INTERVAL_MILLISECOND:2000});
    };
  }, []);

  return (
    <>
      <div style ={show ? {display:"flex",position :"absolute",left:"45%",justifyContent:"center",zIndex:1500,top:"1rem"} : {display :"none"}}>
            <div style={{width : "fit-content",gap:"0.5rem",display:'flex' , background: "#FBE9EA",padding :"0.5rem",borderRadius:"0.5rem"}}>
              <SignalCellularConnectedNoInternet3BarIcon fontSize="small" color="error"/>
              <Typography fontSize={"0.85rem"}>Poor connection, trying to reconnect...</Typography>
            </div>
      </div>
      <div style ={(!show && showRefresh) ? {display:"flex",position :"absolute",left:"45%",justifyContent:"center",zIndex:1500,top:"1rem"} : {display :"none"}}>
          <div style={{width : "fit-content",gap:"0.5rem",display:'flex' , background: "#D1FADF",padding :"0.5rem",borderRadius:"0.5rem"}}>
            <SignalCellularAltIcon fontSize="small" color="success"/>
            <Typography fontSize={"0.85rem"}>Please click <a onClick={()=>{setShowRefresh(false); window.location.reload();}}>refresh</a> to continue.</Typography>
            <CloseIcon fontSize="small" onClick={()=>{setShowRefresh(false)}} style={{cursor: "pointer"}}/>
          </div>
    </div>
    </>
  );
};

export default React.memo(index);
