import axios from "axios";
import audio from '../assets/images/audio_icon.svg'
import video from '../assets/images/videoNew_icon.svg'
import pdf from '../assets/images/pdfNew_icon.svg'
import ppt from '../assets/images/pptNew_icon.svg'
import pptx from '../assets/images/pptxNew_icon.svg'
import image from '../assets/images/imageNew_icon.svg'
import pngimage from '../assets/images/pngNew_icon.svg'
import excel from '../assets/images/xlsNew_icon.svg'
import csv from '../assets/images/xlsxNew_icon.svg'
import word from '../assets/images/wordNew_icon.svg'
import heic from "../assets/images/ic_heic.svg"
import docx from "../assets/images/docx.svg"
import moment from "moment";
import Services from "../api/services";
import {COUNTRY_STATE_CITY_BASEURL as BASEURL} from "../config/url.config";
export const TWILIO_ROOM = {
  room : {},
  roomState :"disconnected",
  set : function setRoom (param) {
       this.room = Object.create(param);
  },
  remove : function removeRoom () {
    this.room = {};
},
  exist : function checkExist () {
       return !["disconnected"].includes(this.roomState)
  },
  disconnect : function disconnect() {
    try{this.room.disconnect()}
    catch(err) {}
  },
  isUserDisconnected : false,
};
export const getIdByName = (json, name) => {
  const data = json.filter((v) => v.name === name);
  if (data && data.length) {
    return data[0].id;
  } else {
    return "";
  }
};

export const getNameById = (json, id) => {
  const data = json.filter((v) => v.id === id);
  if (data && data.length) {
    return data[0].name;
  } else {
    return "";
  }
};

export const getRoleName = (role) => {
  switch (role) {
    case "administrators":
      return "Admin";
    case "doctors":
      return "Doctor";
    case "dietitians":
      return "Dietician";
    case "diabetic-coaches":
    case "diabetic-coach":
      return "Diabetes Coach";
    default:
      return "Role";
  }
};
export const getRefRoleName = (role) => {
  switch (role) {
    case "administrator":
      return "Clinic Admin";
    case "doctor":
      return "Doctor";
    case "dietitian":
      return "Dietician";
    case "diabetic-coach":
      return "Diabetes Coach";
    case "Wellness Coach":
    case "wellness-coach":
      return "AHP";  
    default:
      return "Role";
  }
};
export const getRefRoleToRole = (role) => {
  switch (role) {
    case "administrator":
      return "administrators";
    case "doctor":
      return "doctors";
    case "dietitian":
      return "dietitians";
    case "diabetic-coach":
      return "diabetic-coaches";
    case 'ahp':
      return 'ahp';
    default:
      return "Role";
  }
};

export const getRefRolesToRole = (roles) => {
  switch (roles) {
    case "administrators":
      return "administrator";
    case "doctors":
      return "doctor";
    case "dietitians":
      return "dietitian";
    case "diabetic-coaches":
      return "diabetic-coach";
    case 'ahp':
      return 'ahp';
    default:
      return roles;
  }
}

export const flattenArray = (arr) => {
  if (arr && arr.length) {
    const result = arr.map((v) => {
      const { active } = v;
      return {
        active,
        ...v.doctor,
        mobileNum: `+ ${v.mobile.countryCode} ${v.mobile.number}`,
      };
    });
    return result;
  }
  return arr;
};

export const getIcon = (filename) => {
  const url = new URL(filename);
  const ext = url.pathname.split(".").pop();
  switch (ext) {
    case 'xls':
      return excel;
    case 'xlsx':
    case 'csv':
      return csv;
    case 'ppt':
      return ppt;
    case 'heic':
    case 'heif':
      return heic;
    case 'pptx':
      return pptx;
    case 'mp3':
      return audio;
    case 'mp4':
      return video;
    case 'jpg':
    case 'jpeg':
    case 'gif':
      return image;
    case 'png':
      return pngimage;
    case 'pdf':
      return pdf;
    case 'docx':
      return docx
    default:
      return word;
  }
};

export const padNumber = (number, size) => {
  var s = String(number);
  while (s.length < (size || 2)) { s = "0" + s; }
  return s;
}

export const booleanToString = (value) => {
  switch (value) {
    case true:
      return 'yes'
    case false:
      return 'no'
    case null:
    case "null":
      return 'NA'
    default:
      return ''
  }
}
export const stringToBoolean = (value) => {
  switch (value) {
    case 'yes':
      return true
    case 'no':
      return false
    case 'NA':
      return "null"
    default:
      return ''
  }
}

export const downloadFile = async (url, fileName) => {
  const response = await axios({
    url,
    method: 'GET',
    responseType: 'blob',
  })
  const blobbedResponse = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = blobbedResponse;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
}

export const getBasenameFormUrl = (urlStr) => {
  if(urlStr){
    try{
        const url = new URL(urlStr);
        return url.href.split("?")[0].split("/").pop()
    }
    catch(e){
        return "file"
    }
  }
};
export const getAppointmentType = (type) => {
  const types = {
    CARE_PROGRAM: "Wellness Program",
    VIRTUAL_CONNECT: "Virtual Connect",
    PHYSICAL_CONSULTATIONS: "Physical Consultation",
  }

  return types[type] || 'NA'
}

export const getAge=(dob)=>{
  const diff = moment().diff(moment(dob, ["YYYY-MM-DD", "DD/MM/YYYY"]));
  const duration = moment.duration(diff);
  const years = duration.years();
  const months = duration.months();
  if (years >= 1) {
    return `${years} Yrs`;
  } else {
    return `${months} mo.`;
  }
}

export const serverTimer = async () =>{
  const instance = new Services().getServiceInstance();
  const ApiStart = new Date();
  const json = {};
 try
 {
  const response = await instance.get(`${BASEURL}/getCurrentServerTime`);
  Object.assign(json,response?.data);
 }
 catch(err){}
 finally
 {
   const ApiEnd = new Date();
   JSON.stringify(json) === '{}' && Object.assign(json,{currentDateTime : ApiEnd});
   const timeDelay =  (ApiEnd.getTime() - ApiStart.getTime())/1000;
   Object.assign(json,{timeDelay : timeDelay});
   return json;
 }
}
