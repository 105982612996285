import * as actionTypes from "../actions/actionTypes";
const initialState = false;
const lowLetency = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOW_LATENCY:
        return state = action.payload
        default:
        return state = false
    }
}

export default lowLetency;