import Services from "../services";
import { AxiosInstance } from "axios";
import { CREATE_MEDICAL_HISTORY, GET_PAST_CONSULTATION, CREATE_CASESHEET ,CALL_REASONS , BASE_URL_OPD, VIDEO_SERVICE} from "../../config/url.config";
import { apiGetInvestigations, apiGetSymptoms, apiUpdateCaseSheet, CREATE_CONSULTATION, GET_JOIN_CONSULTATION, apiGetMedicalRecords } from "../../config/url.config";
import { CaseSheetPayload } from "../../models/consultation";
import { genericObj } from "../../models/common";

const instance: AxiosInstance = new Services().getServiceInstance();

export const createPrescription = async (key: string, payload: any) => {
  const { data } = await instance.post(`${CREATE_CONSULTATION}`, payload);
  return data;
};
export const addDrug = async (key: string, payload: any) => {
  const { data } = await instance.post(`${CREATE_CONSULTATION}/Drug`, payload);
  return data;
};

export const getDataParameterList = async (key:string, payload:any) =>{
  const params= payload;
  const {data} = await instance.get(`${CREATE_MEDICAL_HISTORY}/dataparameter/GetDataParameterMasterValueByCode?`, {params});
  return data;
};

/* Insert User DataParameter*/
export const addMedicines = async (key: string, payload: any) => {
  const { data } = await instance.post(`${CREATE_MEDICAL_HISTORY}/userdataparameter/InsertUserDataParameter`, payload);
  return data;
}; 

/*Get All User DataParameter */
export const getAllUserDataParameter = async (key: string, payload: any) =>{
  const {data} = await instance.post(`${CREATE_MEDICAL_HISTORY}/userdataparameter/GetUserDataParameterSelectedValue`, payload);
  return data;
}
export const deleteDrug = async (key: string, payload: any) => {
  const { data } = await instance.post(`${CREATE_CONSULTATION}/Drug/Delete`, payload);
  return data;
};
export const editDrug = async (key: string, payload: any) => {
  const { data } = await instance.patch(`${CREATE_CONSULTATION}/Drug`, payload);
  return data;
};
export const getAllPrescription = async (key: string, payload: any) => {
  const { data } = await instance.post(`${CREATE_CONSULTATION}/GetAllPrescription`, payload);
  return data;
};
export const generatePrescription = async (key: string, payload: any) => {
  const { data } = await instance.patch(`${CREATE_CONSULTATION}`, payload);
  return data;
};
export const getAddedDrugs = async (key: string, payload: any) => {
  const { data } = await instance.post(`${CREATE_CONSULTATION}/GetAddedDrugs`, payload);
  return data;
};

export const getPrescriptionById = async (key: string, payload: any) => {
  const { data } = await instance.post(`${CREATE_CONSULTATION}/GetPrescriptionById?data=${payload.id}`);
  return data;
};
export const endConsultation = async (key: string, payload: any) => {
  const { data } = await instance.patch(`${GET_PAST_CONSULTATION}consultation/updateStateConsultation`, payload);
  return data;
};

export const endConsultationUpdate = async (key: string, payload: any) =>{
  const { data } = await instance.post(`${GET_PAST_CONSULTATION}updateStatusForEndCall`, payload);
  return data;
}

export const endCallReasons = async (key: string, params: any) =>{
  const { data } = await instance.get(`${CALL_REASONS}callStatus?`,{params});
  return data;
}

export const joinConsultation = async (payload:object) => await instance.post(GET_JOIN_CONSULTATION, payload)

export const getPastConsultation = async (userId: string, PageNumber:number) => await instance.get(`${GET_PAST_CONSULTATION}pastConsultations?userId=${userId}&pageNumber=${PageNumber}`)

export const fetchSymptoms = async (_: string, pageNumber: number, pageSize: number = 25, limit: number = 25, skip: number = 0) => await instance.get(apiGetSymptoms(pageNumber, pageSize, limit, skip))

export const fetchInvestigation = async (_: string, search: string = "") => await instance.get(apiGetInvestigations(search))

export const autoUpdateCaseSheet = async (caseSheetId: string, payload: CaseSheetPayload) => await instance.patch(apiUpdateCaseSheet(caseSheetId), payload)

export const fetchMedicalRecords = async (_: string, userId: string) => await instance.get(apiGetMedicalRecords(userId))

export const createCaseSheet = async (_: string, consultationId: string) => await instance.post(`${CREATE_CASESHEET}CreateCaseSheet?consultationId=${consultationId}`)

export const chatHistoryList = async (key:string,payload:genericObj) =>
{
  const {data} = await instance.get(`${process.env.REACT_APP_API_BASE_URL}chat-service/api/v1/getChatHistory?roomId=${payload?.roomId}&pageSize=${payload?.pageSize}&pageNumber=${payload?.pageNumber}&skip=${payload?.skip}&roleId=${payload?.roleId}`);
  return data;
}

export const chatRoomStatus = async (roomId : string) =>{
  const data = await instance.get(`${process.env.REACT_APP_API_BASE_URL}chat-service/api/v1/getConnectionStatus?roomId=${roomId}`);
  return data;
}

export const getMedicalHistory = async (key,payload) => {
const {data} = await instance.post(`${process.env.REACT_APP_API_BASE_URL}master-data-service/api/v1/getMasterData`,payload);
return data;
}
export const getMedicalHistoryUser = async (key,payload) => {
  const {data} = await instance.post(`${BASE_URL_OPD}/getUserMasterData`,payload);
  return data;
}
export const postMedicalHistory = async (key,payload) => {
  const data = await instance.post(`${BASE_URL_OPD}/addUserMasterData`,payload);
  return data;
}

export const deleteMedicalHistory = async (key,payload) => {
  const data = await instance.delete(`${BASE_URL_OPD}/deleteUserMasterData?userId=${payload?.userId}&code=${payload?.code}`);
  return data;
}

export const getChiefComplainList = async (key,payload) => {
  const {data} = await instance.post(`${process.env.REACT_APP_API_BASE_URL}master-data-service/api/v1/getMasterData`,payload);
  return data;
}

export const getSymptomsList = async (key,payload) =>
{
  const {data} = await instance.post(`${process.env.REACT_APP_API_BASE_URL}master-data-service/api/v1/getMasterData`,payload);
  return data;
} 

export const getLabInvestigation = async(key,payload) => 
{
  const params = payload ;
  const {data} = await instance.get(`${process.env.REACT_APP_API_BASE_URL}master-data-service/api/v1/lab-investigation`,{params});
  return data;
}
export const getDBSettings = async (key: string, payload: any) =>{
  const { data } = await instance.post(`${GET_PAST_CONSULTATION}getDBSettings`, payload);
  return data;
}

export const getPatientInfoByRoomId = async(roomID: string) => {
  const {data}= await instance.get(`${VIDEO_SERVICE}/getUserDetailsByRoomId?roomId=${roomID}`);
  return data;
}
