import { FormControl, FormHelperText, InputLabel, Select } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import clsx from "clsx";
import styles from "./styles";
import { genericObj } from "../../../models/common";

const CommonDropDown = ({ className, ...props }: genericObj) => {
  const classes = styles();

  const renderLabel = (value: any) => {
    if(props.children && Array.isArray(props.children)){
      const data =  props.children.filter((item: any) => item.props.value === value);
      if (data && data.length > 0) {
        return data[0].props.children
      }
      return props.placeholder;
    }
  }

  const renderMultiplelabel =(value: any) =>{
   if(value.length > 0)
   {
    if(typeof value[0] === 'object')
    {
        let displaylist = value.map((item)=>{ return item?.name})
        return displaylist.toString();
    }
      return value.toString();
   }
   else
   {
    props.placeholder
   }
  }
  return (
    <>
      {/* <FormLabel>{props.label}</FormLabel> */}
      <FormControl
        variant={props?.variant ? props?.variant : 'outlined'}
        className={clsx(clsx(classes.root, props?.label ? classes.withLabel : classes.withoutLabel), className)}
      >
        <InputLabel style={{paddingLeft: '4px'}}>{props.label}</InputLabel>
        <Select
          displayEmpty
          renderValue={(value: any) => {
           const  displayText : any  = value != undefined && value != '' ? (props.multiple ? renderMultiplelabel(value) : renderLabel(value)) : props.placeholder;
            return displayText;
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
            PaperProps:{
              style:{
                maxHeight : '300px'
              }
            }
          }}
          IconComponent={ExpandMore}
          {...props}
          labelId={`select-outlined-label-${props.label}`}
        >
          {props.children}
        </Select>
        {props.helperText &&
          <FormHelperText>{props.helperText}</FormHelperText>
        }
      </FormControl>
    </>
  );
};

export default CommonDropDown;
