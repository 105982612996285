export const USER_ROLES = {
  nutritionist: "UNIFIED_APP_NUTRITIONIST",
  wellness_coach: "UNIFIED_APP_WELLNESS_COACH",
  diet_coach: "UNIFIED_APP_DIET_COACH",
  field_agent: "UNIFIED_APP_FIELD_AGENT",
  facility_admin: "UNIFIED_APP_FACILITY_ADMIN",
  super_admin: "UNIFIED_APP_SUPER_ADMIN",
  care_admin: "UNIFIED_APP_CARE ADMIN",
  telemedicine_admin: "UNIFIED_APP_TELEMEDICINE_ADMIN",
  opd_admin: "UNIFIED_APP_OPD_ADMIN",
  default: "UNIFIED_APP_DEFAULT",
  lab_vendor: "UNIFIED_APP_LAB_VENDOR",
  claims_admin: "UNIFIED_APP_CLAIMS_ADMIN",
  wellness_manager: "UNIFIED_APP_WELLNESS_MANAGER",
  consumer: "UNIFIED_APP_CONSUMER",
  empanelment_admin: "UNIFIED_APP_EMPANELMENT_ADMIN",
  doctor: "UNIFIED_APP_DOCTOR",
  phc_agent: "UNIFIED_APP_PHC_AGENT",
  transcription_agent: "UNIFIED_APP_TRANSCRIPTION_AGENT",
  claims_processor: "UNIFIED_APP_CLAIMS_PROCESSOR",
  operations_admin: "UNIFIED_APP_OPERATIONS_ADMIN",
  receptionist: "UNIFIED_APP_TELEMEDICINE_RECEPTIONIST",
  phc_admin: "UNIFIED_APP_PHC_ADMIN",
  opd_ops: "UNIFIED_APP_OPERATIONAL_RECEPTIONIST",
  facility_receptionist: "UNIFIED_APP_FACILITY_RECEPTIONIST",
  phc_lab_walkin: "UNIFIED_APP_PHC_LAB_WALKIN", 
  phc_finance_admin:"UNIFIED_APP_PHC_FINANCE_ADMIN", 
  phc_operation:"UNIFIED_APP_PHC_OPERATION", 
  phc_operation_admin:"UNIFIED_APP_PHC_OPERATION_ADMIN",
};

export const VENDOR_TYPE = {
  vaccine : "VaccineManagement",
  lab: 'LabManagement',
  appointmentType:{
    lab:'lab',
    vaccine:'vaccine'
  }
}

export const ROLES_GROUP = {
  Admin_group : 'ADMIN',
  Doctor_group: 'DOCTOR',
  Ahp_group: 'AHP',
  Others_group : 'OTHER',
  Field_group: 'AGENT',
  Default_group:'DEFAULT',
  Consumer_group : 'USER',
}


export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 1280,
  height: 720,
  frameRate: 24,
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'TwilioVideoApp-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'TwilioVideoApp-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'TwilioVideoApp-selectedVideoInput';

// This is used to store the current background settings in localStorage
export const SELECTED_BACKGROUND_SETTINGS_KEY = 'TwilioVideoApp-selectedBackgroundSettings';

export const labColumns = {
  ["1"]: {
    key: 1,
    class: "Confirmed",
    label:"Booking Confirmed",
    status: ["Booking Confirmed","Booking Rescheduled"],
    items: [],
    response: [],
    Query: {},
    pageNumber: 1,
    isEnableCancel: true,
    isEnablePartial: false,
    isEnableUpload: false,
  },
  ["2"]: {
    key: 2,
    class: "SampleCollected",
    label:"Sample(s) Collected",
    status: ["Sample(s) Collected"],
    items: [],
    response: [],
    Query: {},
    pageNumber: 1,
    isEnableCancel: true,
    isEnablePartial: true,
    isEnableUpload: false,
  },
  ["3"]: {
    key: 3,
    class: "SampleReceived",
    label:"Sample(s) Received",
    status: ["Sample(s) Received"],
    items: [],
    response: [],
    Query: {},
    pageNumber: 1,
    isEnableCancel: true,
    isEnablePartial: false,
    isEnableUpload: false,
  },
  ["4"]: {
    key: 4,
    class: "ProcessingSample",
    label:"Processing Sample(s)",
    status: ["Processing Sample(s)"],
    items: [],
    response: [],
    Query: {},
    pageNumber: 1,
    isEnableCancel: false,
    isEnablePartial: false,
    isEnableUpload: false,
  },
  ["5"]: {
    key: 5,
    class: "ReportGenerated",
    label:"Report(s) Generated",
    status: ["Report(s) Generated"],
    items: [],
    response: [],
    Query: {},
    pageNumber: 1,
    isEnableCancel: false,
    isEnablePartial: false,
    isEnableUpload: true,
  },
};

export const vaccineColumns = {
  ["1"]: {
    key: 1,
    class: "Confirmed",
    label:"Confirmed",
    status:  ["Booking Confirmed by Vaccination Center","Booking Rescheduled"],
    items: [],
    response: [],
    Query: {},
    pageNumber: 1,
    isEnableCancel: true,
    isEnablePartial: false,
    isEnableUpload: false,
  },
  ["2"]: {
    key: 2,
    class: "ReportGenerated",
    label:"Upload vaccine card",
    status: ["Vaccine Card Generated"],
    items: [],
    response: [],
    Query: {},
    pageNumber: 1,
    isEnableCancel: false,
    isEnablePartial: false,
    isEnableUpload: true,
  },
};
