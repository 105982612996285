import { CLAIMS_CONSULTATION_BASE_URL } from "../../config/url.config";
import Services from "../services";

const instance = new Services().getServiceInstance();

  export const bookConsultationInstant = async (key: string, payload: any) => {
    const {data} = await instance.post(`${CLAIMS_CONSULTATION_BASE_URL}/bookConsultationForInstantChat`, payload)
    return data;
}

export const InstantUpdateDoctor = async (key: string, payload: any) => {
  const {data} = await instance.post(`${CLAIMS_CONSULTATION_BASE_URL}/updateDoctor`, payload)
  return data;
} 
