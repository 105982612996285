import useVideoContext from '../useVideoContext/useVideoContext';
import useDominantSpeaker from '../useDominantSpeaker/useDominantSpeaker';
import useParticipants from '../useParticipants/useParticipants';
import useScreenShareParticipant from '../useScreenShareParticipant/useScreenShareParticipant';
import useSelectedParticipant from '../../components/consultation/VideoProvider/useSelectedParticipant/useSelectedParticipant';
import { USER_ROLES } from '../../utils/constants'
import { useDispatch, useSelector } from 'react-redux';
import { UpdateInstInfo, UpdateRoomStatus, UpdateRemoveReceptionist } from '../../store/actions';
import { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { sendMsg } from '../../components/Websocket/InstantChatIO';
import { doctorRemoveReceptionist } from '../../api/InstantVideo/Instant';

export default function useMainParticipant(isVideoCollapsed:any, reference:any) {
  const [selectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const dominantSpeaker = useDominantSpeaker();
  const participants = useParticipants();
  const { room } = useVideoContext();
  const localParticipant = room?.localParticipant;
  const { userRole , user} = useSelector((state: any) => state?.auth);
  const remoteScreenShareParticipant = screenShareParticipant !== localParticipant ? screenShareParticipant : null;
  const headers = new window.Headers();
  const dispatch = useDispatch();
  const { instantdata } = useSelector((state : any)=> state?.instantVideo)
  const history = useHistory();
  const searchVal = location.search.split('?')[1];
  const { dbData } = useSelector((state: any) => state?.auth);


  const endCall = () => {
    headers.set('content-type', 'application/json');
    dispatch(UpdateInstInfo({...instantdata,doctorConnected:true}));
    if(!isVideoCollapsed){
      reference()
    }
    dispatch(UpdateRoomStatus(false));
    room!.disconnect();
    sendMsg('updateStatus',{'userId': user?._id, 'status': 'online'});
    history.push({ pathname: "/Dashboard", state:"Doctor Joined the call" });
  };

  const removeReceptionist = () => {
    dispatch(UpdateRemoveReceptionist(true))
    setTimeout(async ()=>{
      const roomId = searchVal?.split('/').length > 1 ? searchVal?.split('/')[1] : (searchVal && searchVal !== 'undefined') ? searchVal : instantdata?.roomId;
      try{
        const res = await doctorRemoveReceptionist(roomId);
        if(res){
          dispatch(UpdateRemoveReceptionist(false))
        }
      } catch (e){
        dispatch(UpdateRemoveReceptionist(false))
      }
    },parseInt(JSON.parse(dbData?.REMOVE_RECEPTIONIST_FROM_ROOM_SETTINGS)?.RemovingTimeMillSecond))
  }

useEffect(()=>{
  participants.map((user)=>{
    if(JSON.parse(user.identity)?.role === "Doctor")
    {
      dispatch(UpdateInstInfo({...instantdata,doctorConnected:true}))
    }
  })
  
   //instantdata?.doctorConnected !== present && dispatch(UpdateInstInfo({...instantdata,doctorConnected:present}));

   if(participants.length >= 1){
    participants.forEach((user)=> {
      const Role = JSON.parse(user.identity)?.role
      if((userRole === USER_ROLES.receptionist || userRole === USER_ROLES.opd_ops ||
        userRole === USER_ROLES.facility_receptionist || userRole === "Receptionist") && Role === "Doctor"){
          endCall()
        } else if ((userRole === "Doctor"|| userRole === USER_ROLES.doctor) && Role ==="Receptionist"){
          setTimeout(()=>{
            removeReceptionist()
          },parseInt(JSON.parse(dbData?.REMOVE_RECEPTIONIST_FROM_ROOM_SETTINGS)?.WaitingTimeMillSecond))
        }
    })
  }
},[participants])
  

  // The participant that is returned is displayed in the main video area. Changing the order of the following
  // variables will change the how the main speaker is determined.
  return selectedParticipant || remoteScreenShareParticipant || dominantSpeaker || participants.length > 0 ? participants : [localParticipant];
}
