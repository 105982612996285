import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles
} from "@material-ui/core";
import { GetAppOutlined, HighlightOff } from "@material-ui/icons";
import { useState } from "react";
import { styles } from "./styles";
import { downloadFile, getBasenameFormUrl } from '../../../utils';
import { ViewContent } from "./viewContent";

const useStyles = makeStyles(styles);
const DocumentViewer = ({ title, onClose, documents = [], ...rest }) => {
  const documentsToExclude = ['xlsx', "docx", "pptx", 'xls', "doc", "ppt"]
  let [document] = documents
  const classes = useStyles();
  const [loading, setLoading] = useState(false)
  const fileExtenstion = document && document?.uri?.split("?")[0].split(".")[document?.uri?.split("?")[0].split(".").length - 1]
  const fileExcludeFlag = documentsToExclude.includes(fileExtenstion)
  const fileName = document && document?.uri?.split("?")[0].split("/")[document?.uri?.split("?")[0].split("/").length - 1]
  return (
    <Dialog
      {...rest}
      fullWidth={true}
      fullScreen={true}
      className={classes.root}
    >
      <DialogTitle>
        {title}
        <IconButton className="close-btn" onClick={onClose}>
          <HighlightOff />
        </IconButton>
      </DialogTitle>
      <DialogContent>

        {fileExcludeFlag ?
          <div className={classes.rootDiv}>
            <div className={classes.message}>Preview currently not available for <b>{fileName}</b>
            </div>
            <div className={classes.imgDiv}>
              <div className={classes.downloadMessage}>Please download to view</div>
              {loading ?
                <CircularProgress size={20} />
                :
                <IconButton
                  className={classes.downloadBtn}
                  onClick={async () => {
                    setLoading(true)
                    await downloadFile(document?.uri, fileName)
                    setLoading(false)

                  }}>
                  <GetAppOutlined />
                  Download
                </IconButton>
              }
            </div>
          </div>
          : documents?.length > 0 && (
            <div className={classes.rootDiv}>
              <div className={classes.message}>{fileName}</div>
              <ViewContent document={document}/>
            </div>
          )}
      </DialogContent>
    </Dialog>
  );
};

export default DocumentViewer;
