import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme: any) => ({
    root: {
        width: "100%",
        "& .MuiInputBase-root": {
            borderRadius: "4px",
            backgroundColor: '#fff',
            "&.Mui-disabled": {
                backgroundColor: '#fafafa',
            }
        },
        "& .MuiSelect-select": {
            padding: "12px !important",
            fontSize: "13px",
            fontWeight: "400"
        },
        "& .MuiSelect-select:focus": {
            backgroundColor: "unset"
        },
        "& fieldset, &:hover fieldset": {
            borderRadius: "4px",
            borderColor: `${theme.palette.input.borderMain} !important`,
            top: 0
        },
        "& fieldset legend": {
            display: "none"
        }
    },
    menuItem: {
        color: theme.palette.input.textMain,
        fontSize: 13,
        fontWeight: 500
    },
    placeholder: {
        fontWeight: 400,
        fontSize: 16,
        color: `${theme.palette.input.labelMain} !important`,
    }
}))

export default styles;
