import { Grid, Typography } from "@material-ui/core";
import useStyles from "./styles";
import { ReactComponent as FooterLogo } from "../../assets/images/poweredBySecurraBlue.svg";
import CustomSubmitButton from "../common/CustomSubmitButton";
import { ReactComponent as Error_Page } from "../../assets/images/no_internet.svg";
import LoginWrapper from "../../hoc/LoginWrapper";

const index = ({ isDataOn }) => {
  const classes = useStyles();
  return (
    <LoginWrapper relative={true}>
      <div className={classes.mainContainer}>
        <Grid item xs={6}>
          <Error_Page width="100%" height="auto" />
        </Grid>
        <Grid item xs={12} className={classes.contentContainer}>
          <Typography className={classes.errNumber}>
            {!isDataOn ? "Something went wrong." : "No Internet"}
          </Typography>
          <div className={classes.contentText}>
            {!isDataOn ? (
              <Typography>Please try again.</Typography>
            ) : (
              <>
                <Typography>
                  Your are not connected to internet. Please check the
                </Typography>
                <Typography>
                  router or contact your service provider.
                </Typography>
              </>
            )}
          </div>
        </Grid>
        <Grid item xs={12} className={classes.btnContainer}>
          <CustomSubmitButton
            type="button"
            onClick={() => window.location.reload()}
            className=""
          >
            {!isDataOn ? "Try Again" : "Refresh"}
          </CustomSubmitButton>
        </Grid>

        <div className={classes.footerLogo}>
          <FooterLogo width="100%" height="100%"/>
        </div>
      </div>
    </LoginWrapper>
  );
};
export default index;
