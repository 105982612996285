import { Box } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
const index : React.FC = ()=>
{
    return (
    <Box component={'div'} height={'100%'} width={'100%'} display={'grid'} bgcolor={'white'} padding={'1rem 3rem'} gridTemplateRows={'25% 25% 50%'}>
    <Box display={'grid'} gridTemplateColumns={'5rem auto'} gap={'1rem'}>
    <Skeleton animation={'wave'} variant="circular" height={'5rem'} style={{top :"20%"}}/>
    <Skeleton animation={'wave'}/>
    </Box>
    <Box display={'grid'} gridTemplateColumns={'auto auto auto'} gap={'1rem'}>
    <Skeleton animation={'wave'}/>
    <Skeleton animation={'wave'}/>
    <Skeleton animation={'wave'}/>
    </Box >
    <Box display={'grid'} gridTemplateColumns={'auto auto'} gap={'1rem'}>
      <Box display={'grid'} gridTemplateRows={'auto auto auto auto'}>
      <Skeleton animation={'wave'}/>
      <Skeleton animation={'wave'}/>
      <Skeleton animation={'wave'}/>
      <Skeleton animation={'wave'}/>
      </Box>
      <Skeleton animation={'wave'}/>
    </Box>
    </Box>);
}
export default index;