    export const styles = (theme) => ({
    root: {
        padding: "7px 16px",
        borderRadius: "4px",
        lineHeight: "26px",
        backgroundColor: "#35C5EA",
        fontSize: "14px",
        fontWeight: "600",
        color: "white",
        textTransform: "none",
        border: "2px solid #35C5EA",
        "& .loadin-icon": {
            height: "20px !important",
            width: "20px !important",
            marginRight: "10px",
            color: "white",
        },
        "& .MuiSvgIcon-root": {
            marginRight: "10px"
        },
        "&:hover": {
            backgroundColor: "transparent",
            color: "#35C5EA",
            // transform: "translate(0px, -2px)"
        },
        "& .MuiButton-label":{
            fontSize: "13px"
        }
    }
});