import { useState, useEffect } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import { useLocation} from 'react-router-dom';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { RootStateOrAny, useSelector } from 'react-redux';
import { EmptyDoctorList } from '../../../pages/consultation/DoctorRejectList';
import { USER_ROLES } from '../../../utils/constants';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

export default function PreJoinScreens({ setCallJoined, setError, isCallJoined, setIsCallBackUserJoined, isCallBackUserJoind, reference, setRequest , setVideoCollapsed}: any) {
  const location = useLocation()

  const { user } = useSelector((state: RootStateOrAny) => state?.auth);
  const { getAudioAndVideoTracks } = useVideoContext();
  const [loading,setloading]=useState(false);
  const [step, setStep] = useState(Steps.roomNameStep);
  const [name, setName] = useState<string>(user?.displayName || '');
  const [userId, setUserId] = useState<string>('');
  const [roomName, setRoomName] = useState<string>('');

  const [mediaError, setMediaError] = useState<Error>();
  const { row }: any = location?.state || {}
  const { userRole } = useSelector((state: RootStateOrAny) => state?.auth);
  const { patientInfo } = useSelector((state: RootStateOrAny) => state?.instantVideo);
  const [patientName, setPatientName] = useState<string>('');

  useEffect(()=>{
    if(Object.keys(patientInfo).length > 0){
      setPatientName(patientInfo?.name?.first+" "+patientInfo?.name?.last)
    }
  },[patientInfo])

  useEffect(() => {
    if (location) {
      setloading(true);
      if (location.pathname === '/Appointments/room') {
        setRoomName(row?.row?._id || location.search.split('?')[1]);
        setPatientName(row?.row?.user?.name?.first + " " + row?.row?.user?.name?.last)
      } else {
        const searchVal = location.search.split('?')[1];
        if(searchVal?.split('/').length > 1){
          setRoomName(searchVal?.split('/')[1])
        } else {
          setRoomName(searchVal);
        }
      }
      setUserId(user?._id)
      setName(`${user?.name?.first} ${user?.name?.last}`)

      getAudioAndVideoTracks().then(()=>{ setloading(false);}).catch(error => {
        if(error?.name === 'NotAllowedError')
        {
          setMediaError(error);
          setError({ message: 'Permission denied', code: 0 });
        }
        setloading(false);
      });
    }
  }, [user, location]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  useEffect(() => {
    sessionStorage.setItem('INSTANT_CALL_INITIATED', 'true');
    userRole !== USER_ROLES.doctor && EmptyDoctorList();
  }, [])

  const mediaErrorSet = (error) => {
    setMediaError(error);
    setError({ message: 'Permission denied', code: 0 });
  }
  return (
    <DeviceSelectionScreen
      name={name}
      roomName={roomName}
      setStep={setStep}
      userId={userId}
      setCallJoined={setCallJoined}
      location={location}
      isCallJoined={isCallJoined}
      patientName={patientName}
      img={row?.row?.photo ?? patientInfo?.photo}
      mediaError={mediaError}
      setMediaError={mediaErrorSet}
      loading={loading}
      isCallBack={row}
      setIsCallBackUserJoined={setIsCallBackUserJoined}
      isCallBackUserJoind={isCallBackUserJoind}
      reference={reference}
      setRequest={setRequest}
      setError={setError}
      setVideoCollapsed={setVideoCollapsed}
    />

  );
}
