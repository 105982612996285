import { makeStyles, Theme } from "@material-ui/core";

const styles = makeStyles<Theme>((theme) => ({
    root: {
        padding: '24px 0px'
    },
    leftGrid: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: '16px 0px !important', 
        background: '#F4F7FB',
        height: 'max-content'
    },
    details: {
        fontSize: '16px',
        fontWeight: 600
    },
    header: {
        fontSize: '13px',
        fontWeight: 600
    },
    image: {
        width: '80px', 
        height: '80px', 
        borderRadius: '50%'
    },
    names: {
        fontSize: '16px', 
        paddingTop: '12px', 
        fontWeight: 700
    },
    subHeader: {
        fontSize: '12px', 
        paddingTop: '16px', 
        color: '#6C6C6C',
        fontWeight: 400
    },
    detailsHeader: {
        fontSize: '12px', 
        color: '#6C6C6C',
        fontWeight: 400
    },
    detailsRoot: {
        border: '1px solid #E4E4E4',

        "& .MuiAccordion-root":{
            position: 'relative !important',
            width: '100%',
            minHeight: '0',
            boxShadow: 'none',
        
        },
        "& .MuiAccordionSummary-root":{
            maxHeight: '54px',
            minHeight: '0 !important'
        }
    },
    detailsTimeline: {
        "& .MuiAccordion-root":{
            position: 'relative !important',
            width: '100%',
            minHeight: '0',
            boxShadow: 'none',
        
        },
        "& .MuiAccordionSummary-root":{
            maxHeight: '54px',
            minHeight: '0 !important'
        }
    },
    detailsTitle: {
        width: '100%', 
        border: '1px solid #E4E4E4',
        padding: '8px 24px', 
        background: '#F4F7FB',
        
    },
    detailHeader:{
        height:"100%",
        "&::-webkit-scrollbar": {
            width: "0px !important",
          },
    },
    detailsContent: {
        width: '100%', 
        padding: '16px 24px'
    },
    feeGrid: {
        display: 'flex',
        justifyContent: 'space-between',
        padding:"5px"
    },
    feeContent: {
        color:'#000000',
        fontWeight: 400,
        fontSize: '13px',
        cursor:'pointer'
    },
    feeContentTotal: {
        fontWeight: 600,
        fontSize: '14px'
    },
    RecordName:{
        textOverflow:'ellipsis',
        overflow:'hidden',
        whiteSpace:'nowrap'
      },
    documentName:{
        textOverflow:'ellipsis',
        overflow:'hidden',
        whiteSpace:'nowrap',
        display:"flex",
        alignItems:"center",
        gap:"1rem"
      },
    view: {
        color: '#32529D',
        minWidth: '32px'
    },
    download: {
        color: '#32529D',
        minWidth: '32px',
        marginLeft: '16px'
    },
    downloadTag: {
        fontSize: '12px',
        color: '#34519C',
        background: '#E7EDFF',
        padding: '5px',
        borderRadius: '4px'
    },
    noAttach:{
       width:'100%',
       fontSize:14,
       fontWeight:400,
       padding:20,
       textAlign:'center'
    },
    select: {
        marginTop: '16px',
        "& .MuiInputBase-input": {
            paddingTop: "6px !important",
            paddingBottom: "6px !important",
            fontWeight: '400 !important',
            fontSize: '16px !important',
            lineHeight: '26px'
        },
        "& .MuiOutlinedInput-root" : {
            background: '#F4F4F4 !important'
        }
    },
    statusDetail: {
        fontSize: '16px',
        fontWeight: 500,
        color: '#717171'
    },
    cancelStatusDetail: {
        fontSize: '16px',
        fontWeight: 500,
        color: '#F42B3D'
    },
    confirmStatusDetail:{
        fontSize: '16px',
        fontWeight: 500,
        color: '#07C180'
    },
    timelineItem: {
        alignSelf: 'flex-start'
    },
    emptyConnector: {
        "& .MuiTimelineConnector-root":{
            backgroundColor: '#F2F3F5',
            height: '116px'
        }
    },
    completedConnector: {
        "& .MuiTimelineConnector-root":{
            backgroundColor: '#384985',
            height: '116px'
        }
    },
    cancelbtn: {
        minWidth: '32px',
        marginLeft: '24px'
    },
    approvebtn: {
        minWidth: '32px',
        marginLeft: '8px'
    },
    profileImage: {
        borderRadius: "50%",
        marginTop: "16px",
      },
      avatarIcons:{
        backgroundColor: "#4965A5",
        width: '100px',
        height: '100px',
        borderRadius: "50%",
        margin: "16px 0 8px 0",
        fontSize: "2rem",
      }
}))

export default styles;