import { Grid } from "@material-ui/core";
import {ReactComponent as Logo} from "../../assets/images/onboarding/images/login_logo.svg";
import useStyles from "./styles";

interface ICustomChildrenProps {
    children: JSX.Element | JSX.Element[];
    relative: boolean;
}

const LoginWrapper = ({ children, relative }: ICustomChildrenProps) => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.root}>
                <div className={classes.container}>
                    <Grid container>
                        <Grid item xs={12} sm={2} md={3} className={classes.baseContainer}>
                            <Logo width="52%" height="100%"></Logo>
                        </Grid>
                        <Grid item xs={12} sm={10} md={9} className={classes.content}>
                            {children}
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    )

}
export default LoginWrapper;