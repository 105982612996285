import Services from "./services";
import { DASHBOARD_SERVICE, UPCOMING_APPOINTMENTS} from "../config/url.config";
import { AxiosInstance } from "axios";

const instance : AxiosInstance = new Services().getServiceInstance();

export const GetDashboardCount = async (key:string, payload:any)=>{
    const { data } = await instance.post(`${DASHBOARD_SERVICE}/getDashBoardCount`,payload);
    return data
}

export const Upcoming_Appointments = async (facilityPractitioner:string, numberOfdays:number)=>{
    const { data } = await instance.get(`${UPCOMING_APPOINTMENTS}upcomingAppointments?facilityPractitioner=${facilityPractitioner}&numberOfdays=${numberOfdays}`);
    return data
}

export const getTotalBookedAppointment = async (userId:string)=>{
    const data = await instance.get(`${UPCOMING_APPOINTMENTS}/getTotalBookedAppointment?userId=${userId}`);
    return data
}