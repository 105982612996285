import { LinearProgress, makeStyles, TextField } from "@material-ui/core";
import React ,{useState} from "react";
import clsx from "clsx";
import Loader from 'react-loader-spinner'
import { styles } from "./styles";
import pageloader from "../../../assets/images/loader/star_blue_loader.gif";
import  CircularProgress  from "@mui/material/CircularProgress";
const useStyles = makeStyles(styles);
const FullScreenLoader = React.forwardRef(({ className, ...props }, ref) => {
  const classes = useStyles();
  const [show,setShow]=useState(false);
  return (
    <div className={classes.root}>
       {!show && <CircularProgress sx={{height :"100px" ,width :'100px'}}/>}
      <img src={pageloader} style={!show ? {display : "none"} : {width: '100px'}} onLoad={(event) => {
        setShow(true)
        }}/>
    </div>
  );
});

export default FullScreenLoader;
