import * as actionTypes from "../actions/actionTypes";

const initialstate = {
    connected: false,
    chatdetails: {},
    Chatmessages: [],
    reconnecting: false,
    chatbookedID: '',
    onchatBusy: false,
    skipCount: 0,
    newMsgReceived: false,
    disconnectMsg: '',
    updateTyping:{},
    userEnd: false
}

const instantChat = (state = initialstate, action) => {
    switch (action.type) {
        case actionTypes.INSTANT_CHAT_CONNECTED:
            return { ...state, connected: true };

        case actionTypes.INSTANT_CHAT_DISCONNECTED:
            return { ...state, connected: false }
        case actionTypes.INSTANT_CHAT_RECONNECT:
            return { ...state, reconnecting: action.payload }
        case actionTypes.INSTANT_CHAT_DETAILS:
            const bookedId = action.payload ? JSON.stringify(action.payload) === '{}' ? "" : action.payload?.bookedId : state.chatbookedID;
            state.chatbookedID = bookedId;
            return { ...state, chatdetails: action.payload}
        case actionTypes.DOCTOR_TYPING:
            return {...state, updateTyping: {...state.updateTyping,...action.payload}}

        case actionTypes.UPDATE_MESSAGE_LIST:
            return { ...state, Chatmessages: [...state?.Chatmessages, ...action.payload] }

        case actionTypes.EMPTY_MESSAGE_LIST:
            return { ...state, Chatmessages: [] }
        case actionTypes.REPLACE_MESSAGE_LIST:
            return { ...state, Chatmessages: action.payload }
        case actionTypes.INSTANT_CHAT_SLOTID:
            return { ...state, chatbookedID: action.payload }

        case actionTypes.ON_CHAT_BUSY:
            return { ...state, onchatBusy: action.payload }

        case actionTypes.SKIP_COUNT:
            return { ...state, skipCount: action.payload }

        case actionTypes.NEW_MSG_RECEIVED:
            return { ...state, newMsgReceived: action.payload }

        case actionTypes.DISCONNECT_MSG:
            return { ...state, disconnectMsg: action.payload }
        case actionTypes.USER_END_STATUS:
            return { ...state, userEnd: action.payload } 
        default:
            return state;
    }
}

export default instantChat;