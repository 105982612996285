var Tracks;
/* Twilio MediaStream Tracks will be saved in above Variable */
export const saveTracks = (stream) => 
{
    if(["/Appointments/room","/InstantCall/room"].includes(window.location.pathname))
    Tracks= stream;
    else  /* If your current page is not Video Call Page then Remove Tracks */
    try
    {
        stream?.forEach((tracks) => {
            tracks.stop();
            tracks.detach();
          })
    }
    catch(err)
    {

    }
}
/* Explicit Method to remove Tracks */
export const removeTrack = () =>
{
    try
    {
        Tracks?.forEach((tracks) => {
            tracks.stop();
            tracks.detach();
          })
    }
    catch(err)
    {

    }
   
}