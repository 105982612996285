import { Typography } from "@material-ui/core";
import moment from "moment";
import useStyles from "./styles";
import ic_view from "../../../../assets/images/appointment/ic_eye.svg";
import { MedicalRecordData } from "../../../../models/consultation";
import ic_prescription_grey from "../../../../assets/images/consultation/ic_prescription_grey.png";
import { getIcon } from "../../../../../src/utils";

const MedicalRecords = ({ medicalRecords = [] }) => {
  const classes = useStyles();
  const TYPES={
    test_reports:'Test Reports',
    other_docs:'Other Documents',
    lab_report:'Lab Report',
    caseSheet:'Casesheet',
    invoice:'Invoice',
    medical_records:'Medical Records',
    prescription:'Prescription',
    prescriptions:'Prescriptions',
    CHAT:'Chat'
  }  
  const renderContainer = ({
    id,
    name,
    path,
    tags,
    createdAt
  }: MedicalRecordData) => {
    return (
      <div key={id} className={classes.listWrapper}>
        <div>
          <div className={classes.fileWrapper} key={id}>
            <div className={classes.fileContainer}>
              <div className={classes.pdfContainer}>
              <img
                              src={getIcon(path)}
                              alt="document"
                              width="50"
                              height="50"
                            />
              </div>
              <div>
                <Typography className={classes.nameContainer}>
                  {name}
                </Typography>
                <div className={classes.typeWrapper}>
                  <div className={classes.typeName}>
                    {TYPES[tags[1]]}
                  </div>
                  <Typography className={classes.timeContainer}>
                  {moment(createdAt).format("LT")}
                  </Typography>
                </div>
              </div>
            </div>
            <div>
              <a
                href={path}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.viewContainer}
              >
                <img src={ic_view} alt="view icon" height={14} width={22} />
                <Typography className={classes.view}>View</Typography>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const groupArrayObject = medicalRecords.reduce((group: any, arr: any) => {
    const { updatedAt } = arr || {};
    const formatted = moment(updatedAt).format("DD/MM/YYYY");
    group[formatted] = group[formatted] ?? [];
    group[formatted].push(arr);
    return group;
  }, {});

  return (
    <>
      {medicalRecords.length === 0 ? (
        <div className={classes.emptyPrescription}>
          <div className="iconWrapper">
            <img src={ic_prescription_grey} alt="placeholder" />
          </div>
          <span className="desc">No Medical Records to display</span>
        </div>
      ) : (
        <div className={classes.mainContainer}>
          {Object.keys(groupArrayObject ? groupArrayObject : {}).map((key) => {
            return (
              <>
                <Typography className={classes.appointmentDate}>
                  {key}
                </Typography>
                {groupArrayObject[key].map(renderContainer)}
              </>
            );
          })}
        </div>
      )}
    </>
  );
};

export default MedicalRecords;
