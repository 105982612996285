import { Grid, Typography } from "@material-ui/core";
import styles from "./styles";
import DummyDoctor from "../../assets/images/dummy_doctor.jpg";
import male from "../../assets/images/GenderMale.svg";
import ChatCircleDots from "../../assets/images/ChatCircleDots.png";
import Accept_Call from "../../assets/images/Accept_Call.png";
import Decline_Call from "../../assets/images/Decline_Call.png";
import cross from "../../assets/images/cross.png";
import { useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setChatNow, setPickUp } from "../../store/actions/pickup";
import { UpdateBookId, UpdateCallInfo, UpdateInstInfo, UpdateConnectingInfo, UpdateInstantType, UpdateCallbackInfo } from "../../store/actions/instantVideo";
import { sendMsg } from "../Websocket/InstantChatIO";

import moment from "moment";
import { bookConsultationInstant, InstantUpdateDoctor } from "../../api/Instant/InstantApi";
import Notify from "../Notify";
import { useHistory, useLocation } from "react-router-dom";
import { USER_ROLES } from "../../utils/constants";
import { ChatDetails, instantChatSlot, OnchatBusy, EmptyMessagelist } from "../../store/actions";
import PopUpSound from '../../assets/mp3/PopUpSound.mp3';
import { getDoctorlistview } from "../../api/AppointmentManagement/Appointment";
import FullScreenLoader from "../common/FullScreenLoader";
import { serverTimer } from "../../utils";
import { useQuery } from "react-query";
var TimeCount;

const CallPopUp = ({ Notifydata, setinstantResponse}: any) => {
  const classes = styles();
  const { pickup } = useSelector((state: any) => state?.call);
  const [request, setRequest] = useState({
    message: "",
    loading: false,
    severity: "",
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { details } = useSelector((state: any) => state?.clinic);
  const { userRole, user } = useSelector((state: any) => state?.auth);
  const { instantdata } = useSelector((state: any) => state?.instantVideo)
  const { chatdetails } = useSelector((state: any) => state?.instantChat);
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(()=>{
    return () => {
      TimeCount && clearInterval(TimeCount);
    }
  },[])

  useEffect(()=>{
    if(location.pathname === '/Appointments/room'){
      sessionStorage.removeItem('CountTimer');
      TimeCount && clearInterval(TimeCount)
      dispatch(UpdateConnectingInfo({})) 
      dispatch(setPickUp({ pickup: false }));
    }
  },[location])

  const getInMilliseconds = (time:any) => {
    let milliseconds = 0;
    let convertTime = new Date(time)
    if (convertTime) {
        let hours = convertTime.getHours()
        let minutes = convertTime.getMinutes()
        let seconds = convertTime.getSeconds()
        milliseconds = (hours * 60 * 60 * 1000) + (minutes * 60 * 1000) + (seconds * 1000);
    }
    return milliseconds;
}

  const receptionist = userRole === USER_ROLES.receptionist || userRole === USER_ROLES.opd_ops || userRole === USER_ROLES.facility_receptionist

  const Timer = () => {
    var countdownNumberEl = document.getElementById("countdownnumber1");
    var countdown = Notifydata?.type === 'VIRTUAL_CONSULTATION' ? 60 : 20;
    if (countdownNumberEl !== null) {
      countdownNumberEl.textContent = countdown.toString();

      TimeCount = setInterval(function () {
        if (--countdown <= 0 && TimeCount) {
          clearInterval(TimeCount);
          callReject();
        }

        if (countdownNumberEl !== null) {
          countdownNumberEl.textContent = countdown.toString();
        }
      }, 950);
    }
  };
  const { data: timeSlots } = useQuery("getSlotList",
    serverTimer,
  );

  const callReject = () => {
    TimeCount && clearInterval(TimeCount)
    dispatch(setPickUp({ pickup: false }));
    dispatch(setChatNow({ chatnow: false }));
    dispatch(UpdateInstInfo({}))
    dispatch(ChatDetails({}))
    setinstantResponse({});
    if(Notifydata.type === 'VIRTUAL_CONSULTATION'){
      sendMsg('updateStatus',{'userId': user?._id, 'status': 'online'})
    } else {
      Notifydata.type === 'INSTANT' ? receptionist ? sendMsg('receptionistReject', {callId: Notifydata?.callId, userId: user?._id, role: userRole}) : sendMsg('doctorReject', {callId: Notifydata?.callId, role: userRole}) : sendMsg('doctorChatReject', {chatLogId: Notifydata?.chatLogId})
    }
  }

  useEffect(() => {
    TimeCount && clearInterval(TimeCount)
    Timer();
  }, []);


  const BookConsultation = async () => {
    setRequest({
      message: "",
      loading: true,
      severity: "",
    });
    let Receptionistpayload = {
      "facility": details?.id,
      "roleId": userRole,
      "practitioner": user?._id,
      "primaryUser": instantdata?.user,
      "consultationType": "INSTANT",
      "appointmentCreatedBy": user?._id,
      "appointmentCreatedPersonRole": userRole,
      "date": moment().format('YYYY/MM/DD'),
      "slotStartTime": getInMilliseconds(timeSlots?.currentDateTime),
      "isLinkRequired": false,
    }
    let DoctorPayload = {
      "bookedId": instantdata?.bookedId,
      "practitioner": user?._id,
      "roleId": userRole,
      "isLinkRequired": false,
    }

    let InstantDoctor = {
      "facility": details?.id,
      "roleId": userRole,
      "practitioner": user?._id,
      "primaryUser": chatdetails?.user,
      "appointmentCreatedBy": user?._id,
      "appointmentCreatedPersonRole": userRole,
      "date": moment().format('YYYY/MM/DD'),
      "slotStartTime": getInMilliseconds(timeSlots?.currentDateTime),
      "consultationType": Notifydata?.type,
      "isLinkRequired": true,
      "chatLogId": chatdetails?.chatLogId
    }

    try {
      const res = Notifydata?.type === 'INSTANT' ? receptionist ? await bookConsultationInstant('AppoinmentCreate', Receptionistpayload) : await InstantUpdateDoctor('DoctorUpdation', DoctorPayload) : await bookConsultationInstant('AppoinmentCreate', InstantDoctor);
      if (res && res?.data) {
        const {bookedId} = res?.data;
        setLoading(false);
        if (Notifydata?.type === 'INSTANT') {
          dispatch(UpdateBookId(res?.data?.bookedId));
          receptionist && dispatch(UpdateInstInfo({...instantdata, bookedId: bookedId}))
          dispatch(UpdateCallInfo(true));
          dispatch(setPickUp({ pickup: false }));
          history.push({
            pathname: `/InstantCall/room`,
            search: `${instantdata?.roomId}`,
            state: {
              row: { row :{_id:bookedId}}
            }
          })
        }
        else if (Notifydata?.type === 'CHAT') {
          localStorage.getItem('INSTANT_CHAT_JOINED') && localStorage.removeItem('INSTANT_CHAT_JOINED')
          dispatch(setChatNow({ chatnow: false }));
          dispatch(ChatDetails({...chatdetails, bookedId: bookedId}));
          dispatch(instantChatSlot(bookedId));
          dispatch(OnchatBusy(true));
          dispatch(EmptyMessagelist());
          const {name ,roomId,userId,chatLogId,user} = chatdetails ;
          sendMsg('join',{ name, room:roomId, userId : userId || user, "type": "web",  chatLogId,bookSlotId:bookedId});
          sendMsg('doctorChatAccepct', {chatLogId: Notifydata?.chatLogId})
          history.push({
            pathname: `/chat-consulation`,
            search: `?roomID=${chatdetails?.roomId}`,
            state: {
              row: { row :{_id:bookedId}}
            }
          })
        }
      }
      else {
        setRequest({
          message: res?.data?.message || "Something went wrong",
          loading: false,
          severity: "error",
        });
        localStorage.getItem('INSTANT_CHAT_JOINED') && localStorage.removeItem('INSTANT_CHAT_JOINED')
        dispatch(setPickUp({ pickup: false }));
        dispatch(setChatNow({ chatnow: false }));
        dispatch(UpdateInstInfo({}))
        dispatch(UpdateConnectingInfo({}))
        dispatch(ChatDetails({}))
        setinstantResponse({});
        Notifydata.type === 'INSTANT' ? receptionist ? sendMsg('receptionistReject', {callId: instantdata?.callId, userId: user?._id, role: userRole}) : sendMsg('doctorReject', {callId: Notifydata?.callId, role: userRole}) : sendMsg('doctorChatReject', {chatLogId: Notifydata?.chatLogId})
      }
    }
    catch (e) {
      setRequest({
        message: e?.response?.message || "Something went wrong",
        loading: true,
        severity: "error",
      });
      localStorage.getItem('INSTANT_CHAT_JOINED') && localStorage.removeItem('INSTANT_CHAT_JOINED')
      dispatch(setPickUp({ pickup: false }));
      dispatch(setChatNow({ chatnow: false }));
      dispatch(UpdateInstInfo({}))
      dispatch(UpdateConnectingInfo({}))
      dispatch(ChatDetails({}))
      setinstantResponse({});
    }
  }

  const getAge = (age:string) => {
    var dob = new Date(age);
    var month_diff = Date.now() - dob.getTime();
    var age_dt = new Date(month_diff);  
    return  Math.abs((age_dt.getUTCFullYear()) - 1970);
  }
  const getAppointmentDetail = async() => {
    try{
      const res = await getDoctorlistview("GET_APPOINTMENT_LIST",{facility: details?.id, bookedId: Notifydata?.bookedId, practitioner : user?._id});
      if(res?.data){
        setLoading(false)
        const row = res?.data?.data[0]
        history.push({
          pathname: `/Appointments/room`,
          search: `${row?._id}`,
          state:{
            row:{row}
          }
        })
      }
    } catch (e) {
      setRequest({
        message: e?.response?.message || "Something went wrong",
        loading: true,
        severity: "error",
      });
    }
    dispatch(setPickUp({ pickup: false }));
  }
const stopCircle = () => 
{
  const spinner = document.getElementById("animated-spinner-circle");
  if(spinner !== null)
  spinner.style.animationPlayState = "paused";
}
  return (
    <>
      {request.message && (
        <Notify message={request.message} severity={request.severity} />
      )}
      {loading && <FullScreenLoader />}
      <Grid className={classes.popupbox} style={{padding: Notifydata.type === 'VIRTUAL_CONSULTATION' ? '20px 60px 20px 20px' : '20px'}}>
        <audio src={PopUpSound} autoPlay loop/>
        <Grid container xs={12}>
          <Grid>
            <img
              className={classes.callUserPhoto}
              src={Notifydata?.photo || DummyDoctor}
              loading="lazy"
              decoding="async"
            />
          </Grid>
          <Grid>
            <Typography style={{ color: "white" }}>{Notifydata.type === 'VIRTUAL_CONSULTATION' ? "Appointment with ": ""}{Notifydata?.name}</Typography>
            <div style={{ color: "grey", paddingBottom: Notifydata.type === 'VIRTUAL_CONSULTATION' ? "0" : "24px" }}>
              <img src={male} style={{ width: "15px", color: "#717171" }} />{" " + Notifydata?.gender}
              {`, Age: ${getAge(Notifydata?.dob)}`}
            </div>
            {Notifydata.type === 'VIRTUAL_CONSULTATION' && <p style={{ color: "grey" }}>Scheduled at {Notifydata?.slotTime}</p>}
            <div>
              <Button
                variant="contained"
                className={classes.acceptButton}
                disabled={request?.loading}
                style={{ borderRadius: "20px", padding: "5px 10px" , minHeight :"2.0625rem",minWidth:'6.25rem'}}
                onClick={() => {
                  setLoading(true)
                  sessionStorage.removeItem('CountTimer');
                  TimeCount && clearInterval(TimeCount)
                  dispatch(UpdateConnectingInfo({}));
                  stopCircle();
                  Notifydata?.type !== 'CHAT' && sessionStorage.setItem("new-consulation","true");
                  if(Notifydata.type === 'VIRTUAL_CONSULTATION'){
                    getAppointmentDetail()
                  } else {
                    sendMsg('sendSelfNotification',{'userId': user?._id, 'consultationStatus': Notifydata.type === 'INSTANT' ? 'CALL_ACCEPTED' : 'CHAT_ACCEPTED'});
                    dispatch(UpdateInstantType('Initial'))
                    dispatch(UpdateCallbackInfo({}));
                    Notifydata?.type === 'CHAT' && localStorage.setItem('INSTANT_CHAT_JOINED', 'true')
                    BookConsultation();
                  }
                }}
              >
                {request?.loading ? <CircularProgress size={'small'} style={{width : '1.25rem',color :"white"}}/> : <><img src={pickup ? Accept_Call : ChatCircleDots} />
                &nbsp; {Notifydata.type === 'VIRTUAL_CONSULTATION' ? 'Join Now' : pickup ? 'Accept' : 'chat Now'}</>}
              </Button>
              <Button
                variant="contained"
                className={classes.rejectButton}
                disabled={request?.loading}
                style={{ borderRadius: "20px", marginLeft: "10px", padding: "5px 10px" }}
                onClick={() => {
                    TimeCount && clearInterval(TimeCount)
                    sessionStorage.removeItem('CountTimer');
                    dispatch(UpdateInstInfo({}))
                    dispatch(setPickUp({ pickup: false }));
                    dispatch(setChatNow({ chatnow: false }));
                    setinstantResponse({});
                    if(Notifydata.type === 'VIRTUAL_CONSULTATION'){
                      sendMsg('updateStatus',{'userId': user?._id, 'status': 'online'})
                    } else {   
                      dispatch(UpdateConnectingInfo({}))           
                      Notifydata.type === 'INSTANT' ? receptionist ? sendMsg('receptionistReject', {callId: Notifydata?.callId, userId: user?._id, role: userRole}) : sendMsg('doctorReject', {callId: Notifydata?.callId, role: userRole}) : sendMsg('doctorChatReject', {chatLogId: Notifydata?.chatLogId})
                      sendMsg('sendSelfNotification',{'userId': user?._id, 'consultationStatus': Notifydata.type === 'INSTANT' ? 'CALL_REJECTED' : 'CHAT_REJECTED'});
                      dispatch(UpdateInstInfo({}))
                      dispatch(ChatDetails({}))
                      setinstantResponse({});
                    }
                }}
              >
                <img src={pickup ? Decline_Call : cross} />
                &nbsp; {Notifydata.type === 'VIRTUAL_CONSULTATION' ? 'Close' : 'Reject'}
              </Button>
            </div>
          </Grid>
          <Grid style={{paddingLeft: Notifydata.type === 'VIRTUAL_CONSULTATION' ? '40px' : '0px'}}>
            <div id="countdown" className={classes.countdown}>
              <div id="countdownnumber1" className={classes.countdownumber}></div>
              <svg className={classes.svg}>
                <circle
                 id="animated-spinner-circle"
                  r="18"
                  cx="20"
                  cy="20"
                  className={classes.circle}
                  style={{ animation: Notifydata?.type === 'VIRTUAL_CONSULTATION' ? `countdown 60s linear infinite forwards` : `countdown 20s linear infinite forwards` }}
                ></circle>
              </svg>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CallPopUp;
