import axios from "axios";
var mime = require('mime-types');
import { authStorage } from "../utils/login";
import Services from "../../src/api/services";
import { useSelector } from "react-redux";

const bytesToMegaBytes = bytes => bytes / (1024*1024);
const fileInstance = new Services().getServiceInstance();

export const uploadToS3 = async ( file, prefix, dbData, payload) => {
  try {
    const { size } = file;
    const fileMBSize = bytesToMegaBytes(size);
    if (fileMBSize > (dbData?.MAX_FILE_SIZE / (1024*1024))) { // Check for 5 MB or more filesize
      let error = { response: { data: { message:` File size more than ${dbData?.MAX_FILE_SIZE / (1024*1024)} MB is not supported`} } };
      throw error;
    }
    const s3prefix = prefix || "logo";
    const formdata = new FormData();
    formdata.append('files', file);
    formdata.append("tag", s3prefix);
    payload && formdata.append(payload?.key, payload?.value);
    const response = await fileInstance.post(process.env.REACT_APP_FILE_BASEURL + "/api/v1/uploadFile", formdata);

    const { s3Url, keyPath ,fileId} = response?.data?.map((x)=>x)[0]?.response||{};
    return { url: s3Url.replace(/%2F/gi, "/"), err: null, keyPath , fileSize: response?.headers["content-length"],fileId:fileId};
  } catch (err) {
    return { url: undefined, err };
  }
};
