export const styles = (theme) => ({
    root: {
        width: "100%",
        "& .MuiInputBase-root" :{
            backgroundColor: "white",
            // "&:hover": {
            //     border: "0.3px solid grey !important",
            //     color: "#FFFFFF",
            //   },
        },
        "& .MuiAutocomplete-inputRoot": {
            padding: "0px !important",
        },
        "& .MuiChip-root": {
            background: "none",
            fontSize: "16px",
            fontWeight: "600",
            marginTop: "14px",
        },
        "& .MuiAutocomplete-endAdornment":{
            right:"0px !important",
        },
        '& fieldset': {
            border: 'none',
        },
        "&:hover fieldset": {
            borderRadius: "4px",
            border: '1px solid #717171 !important'
        },
        "& legend":{
            maxWidth: '0 !important',
            color: "black"
        },
    }
});