import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: any) => ({
  root: {
    position: "relative",
    "& .MuiOutlinedInput-input": {
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: "14px",
      padding: "18px 10px 12px 10px !important",
    },

    "& .MuiOutlinedInput-adornedEnd": {
      background: "#EDEFF3",
      borderRadius: "4px !important",
    },

    "& fieldset": {
      border: "1px solid #E4E4E4 !important",
      borderRadius: "4px !important",
    },

    "& label": {
      transform: " translate(14px, 14px) scale(1)",
    },
    "& input": {
      padding: "18px 10px 12px 15px !important",
    },
  },
  tabContainer: {
    width: "100%",
    borderBottom: "1px solid #E4E4E4",

    "& .MuiTabs-flexContainer": {
      display: "grid",
      gridTemplate: "auto/ auto auto auto",
      width: "100%",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#32529D",
      height: "3px",
    },
  },
  appointmentDate:{
    marginTop:"30px",
    marginBottom:"20px"
  },
  singleTabContainer: {
    width: "100%",
    borderBottom: "1px solid #E4E4E4",

    "& .MuiTabs-flexContainer": {
      display: "grid",
      gridTemplate: "auto/ auto auto auto",
      width: "100%",
      backgroundColor:"#F4F7FB"
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#32529D",
      height: "0px",
    },
  },
  pastTitle:{
    color:"#000000",
    fontSize:"1rem",
    fontWeight:500,
    margin:"0px",
    padding: "16px 20px",
    lineHeight:"16px"
  },
  tabItem: {
    textTransform: "none",
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
  },
  tabIcon: {
    margin: "0 8px",
  },
  scrollView: {
    overflowY: "auto",
    overflowX: "hidden",
    height:"70vh",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
  },
  emptyPrescription: {
    padding: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",

    "& .iconWrapper": {
      width: "80px",
      height: "80px",
      borderRadius: "50%",
      backgroundColor: "#F6F6F6",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& img": {
      width: "25px",
      height: "34px",
    },
    "& .desc": {
      fontSize: "12px",
      margin: "12px auto",
      color: "#747474",
    },
  },
  prescriptionForm: {
    "& .subtitle": {
      fontSize: "14px",
      fontWeight: 600,
    },
    "& .wrapper": {
      display: "flex",
      justifyContent: "space-between",
      padding: "10px 20px",
    },
    "& .firstWrapper":{
      display: "flex",
      justifyContent: "space-between",
      padding: "30px 20px 0px 20px",
    },
    "& .MuiInputBase-root" :{
      backgroundColor: "#F4F4F4 !important"
    },
    "& .textWrapper": {
      padding: "10px 20px",
    },
    "& .buttonWrapper": {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-end",
      margin: "4rem -3rem 1rem 0rem",
    },
    "& .MuiTypography-body1": {
      fontSize: "14px",
    },
    "& .MuiCheckbox-root": {
      padding: "4px",
    },
    "& .checkboxWrapper": {
      display: "grid",
      gridTemplate: "auto/ auto auto auto",
      margin: "16px 0 0 7px",
    },
    "& .MuiInputBase-multiline":{
       padding:"0px !important"
    },
  },
  bold:{
    fontWeight:600,
  },
  addNewButton: {
    color: "#34519C",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    margin: "1rem 0 1rem 1rem",
    cursor: "pointer",
  },
  gridFooter: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '26px',
    color: '#777777'
},
progressBarPast: {
  AlignItems: "center",
  display: "flex",
  left: "50%",
  margin: "150px auto",
},
  paginationContainer: {
    // marginTop: "30px",
    "& ul": {
      justifyContent: "center",
      "& li button": {
        height: "32px",
        width: "32px",
        backgroundColor: "transparent",
        fontSize: "13px",
        color: "#223345",
        borderRadius: "50%",
        fontWeight: 600
      },
      "& li button.Mui-selected, & li button:hover": {
        backgroundColor: "#32529D",
        color: "white",
      },
      "& li button.navigation": {
        width: "auto"
      }
    },
  },

  // medicine list
  prescriptionBox: {
    borderRadius: "8px",
    padding: "20px 20px",
    "& .buttonWrapper": {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-end",
      margin: "4rem -2rem 0rem 0rem",
    },
  },
  headerContainer: {
    borderRadius: "8px 8px 0px 0px",
    background: "#F4F7FB",
    borderTop: "1px solid #E4E4E4",
    padding: "11px 20px",
    display: "flex",
    width:"100%",
  },
  medicineName:{
    marginRight:"12px"
  },
  viewPrescribe: {
    paddingRight: "10px",
  },
  cidNumber: {
    fontWeight: 500,
    fontSize: "14px",
    color: "#00000",
    lineHeight: "17px",
    paddingLeft: "11px",
  },
  viewPrescription: {
    color: "#000000",
    justifyContent: "flex-end",
    display: "flex",
    fontWeight: 600,
    fontSize: "14px",
  },
  prescribeData: {
    border: "1px solid #E4E4E4",
    borderRadius: "0px 0px 8px 8px",
    background: "#FFFFFF",
  },
  tableData: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 20px",
    borderTop: "1px solid #E4E4E4",
  },
  tabArr: {
    color: "#333333",
    fontSize: "12px",
  },
  tabData: {
    color: "#000000",
    fontWeight: 500,
    fontSize: "14px",
    paddingTop: "7px",
    overflow:'hidden',
    textOverflow:'ellipsis'
  },

  //medical record
  mainContainer: {
    padding: "0rem 1.5rem 1.5rem 1.5rem",
  },
  listWrapper: {
    width: "100%",
  },
  dateContainer: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "16px",
    color: "#717171",
    padding: "5px 0px 20px 0px",
  },
  pdfContainer: {
    borderRadius: "6px",
    width: "46px",
    display: "flex",
    alignItems: "center",
    alignContent: "center",
  },
  fileWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "25px",
  },
  fileContainer: {
    display: "flex",
    gap: "10px",
  },
  typeWrapper: {
    display: "flex",
    gap: "5px",
  },
  viewContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    cursor:"pointer",
  },
  typeName: {
    padding: "3px 5px",
    background: "#A7A7A7",
    borderRadius: "4px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "10px",
    color: "#FFFFFF",
  },
  timeContainer: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    color: " #717171",
  },
  view: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "14px",
    color: "#32529D",
  },

  //past consultation
  prescriptionCover: {
    padding: "20px 20px",
  },
  headerWrapper: {
    borderRadius: "8px 8px 0px 0px",
    background: "#F4F7FB",
    border: "1px solid #E4E4E4",
    padding: "20px 11px",
    display: "flex",
  },
  viewPrescribeContainer: {
    paddingRight: "10px",
  },
  cidNumberCard: {
    fontWeight: 500,
    fontSize: "14px",
    color: "#00000",
    lineHeight: "17px",
    paddingLeft: "20px",
  },
  tableDataCard: {
    display: "flex",
    padding: "20px 30px",
    background: "#FFFFFF",
    border: "1px solid #E4E4E4",
    borderRadius: "0px 0px 8px 8px",
  },
  tabArrContainer: {
    color: "#333333",
    fontSize: "12px",
  },
  tabDataContainer: {
    color: "#000000",
    fontWeight: 500,
    fontSize: "14px",
    wordBreak: "break-word",
    paddingTop: "7px",
    display:"flex",
    gap:"5px"
  },
  viewPrescribeCard: {
    paddingRight: "10px",
    justifyContent: "flex-end",
    display: "flex",
    fontWeight: 600,
    fontSize: "14px",
    color: "#32529D !important",
    alignItems:"center",
    cursor:"pointer",
  },
  notesContainer:{
    marginTop:"30px",
    marginBottom:"15px"
  },
  RejectDoctor:{
    height:"370px",
    "&.MuiDialog-paper":{
     width:"380px !important",
    },
    "& .MuiDialogTitle-root":
    {
      borderBottom:"0.5px solid #B8CFE1!important"
    },
 },
 RejectDoctorClxBtn:{
  backgroundColor:"#B7BDCE !important",
  border: "1px solid #B7BDCE !important",
  color:'white !important',
  padding:"7px 15px !important",
  width:"150px",
  "&:hover": {
   backgroundColor:"white !important",
   color:"#B7BDCE !important",
  }
},
submitButton:{
  padding:"7px 15px",
},
disableContainer:{
  pointerEvents: "none",
},
RejectDoctorRejBtn:{
  backgroundColor:"#35C5EA !important",
  border: "1px solid #35C5EA !important",
  color:'white !important',
  width:"150px",
  padding:"7px 15px !important",
  "&:hover": {
      backgroundColor:"white !important",
      color:"#35C5EA !important",
     }
},
progressBar: {
  position: "absolute",
  left: "67%",
  top: "20rem",
  zIndex: 10,
},
//popup
CustomModel:{
  '& .MuiDialogTitle-root':{
      borderBottom:'1px solid #ddd'
  }
},
gridSpace:{
  padding:'0 10px',
  marginBottom:20,
  fontSize:"16px"
},
customDialogReject:{
  maxWidth:'450px',
  '& .MuiDialogTitle-root':{
      borderBottom: '1px solid rgb(184 207 225 / 50%)',
      padding: '10px 20px',
      '& h1':{
          fontSize: '18px'
      }
  },
  '& .close-btn':{
      padding:0,
      top: 12
  },
  '& .MuiDialogContent-root':{
      padding: '20px',
      textAlign:'center'
  }
},
RightAlign: {
  display: 'flex',
  justifyContent: 'flex-end'
},
fullWidthButton:{
  display:'flex',
  justifyContent:'center',
  '& button':{
      flex:1,
      fontSize:18,
      padding: '10px 25px'
  }
},
CustomCancelBtn:{
  backgroundColor: "white",
  birderColor: "#828282",
  color: "#828282",
  fontFamily: "Inter",
  fontSize: "14px",
  marginRight: 10
},
ReceptionistRejBtn:{
  backgroundColor:"#DB3500 !important",
  border: "1px solid #DB3500 !important",
  color:'white !important',
  width:"150px",
  padding:"7px 15px !important",
  "&:hover": {
      backgroundColor:"white !important",
      color:"#DB3500 !important",
     }
},
ReceptionistEndBtn:{
  backgroundColor:"#ED9A80 !important",
  border: "1px solid #ED9A80 !important",
  color:'white !important',
  width:"150px",
  padding:"7px 15px !important",
},
CommentSection:{
  "& .MuiFilledInput-multiline":{
    padding:'0px !important',
  }
},
unsavedTab:{
  padding: '16px',
  display:"flex !important",
  justifyContent: "space-between !important",
},
submitBtn: {
  color: " #FFFFFF !important",
  border:"2px solid #35C5EA !important",
  width:"200px",
  backgroundColor:"#35C5EA !important",
  textTransform:"none",
  "&:hover":{
    color: "#35C5EA !important",
    backgroundColor:"transparent !important",

  }
},
cancleBtn: {
  color: " #FFFFFF !important",
  border:"2px solid #B7BDCE !important",
  width:"200px",
  backgroundColor:"#B7BDCE !important",
  height:"40px",
  "&  .MuiButton-label":{
  fontSize:"14px",
},
  "&:hover":{
    color: "#B7BDCE !important",
    backgroundColor:"transparent !important",

  }
},
TagCustomModel: {
  '& .MuiDialogTitle-root': {
      borderBottom: '1px solid #ddd'
  }
},
TagcustomDialog: {
  maxWidth: '360px',
  '& .MuiDialogTitle-root': {
      borderBottom: '1px solid rgb(184 207 225 / 50%)',
      padding: '15px 25px',
      '& h2': {
          fontSize: '16px',
          fontWeight:600,
      }
  },
  '& .close-btn': {
      padding: 0,
      top: '18px !important'
  },
  '& .MuiDialogContent-root': {
      padding: '20px 20px 0px 20px',
      textAlign: 'center'
  },
  '& .MuiDialogActions-root': {
      padding: '20px !important',
  },
},
fullWidthBtn: {
  display: 'flex',
  justifyContent: 'center',

  '& button': {
      flex: 1,
      fontSize: 18,
      padding: '10px 25px'
  }
},
cancelBtn: {
 width:"320px !important",
 height:"40px",
},
Title: {
  fontSize: '16px',
  fontWeight: 600,
  marginTop: 0,
  textAlign: 'left',
  paddingLeft: '4px'
},

}));

export default useStyles;
