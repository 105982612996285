import { combineReducers } from "redux";
import { AUTH_LOGOUT } from "../actions/actionTypes";
import auth from "./auth";
import register from "./register";
import clinic from "./clinic";
import user from "./user";
import careProgram from "./careProgram";
import chat from "./chat"
import call from "./pickup"
import instantVideo from './instantVideo'
import OnlineDoctors from './OnlineDoctors'
import instantChat from './instantChat';
import showConsultation from './consultation';
import NotifyList from './Notification'
import lowLetency from "./lowLetency";

const combinedReducer = combineReducers({ auth, register, clinic, user, careProgram,chat ,call,instantVideo,OnlineDoctors,instantChat,showConsultation,NotifyList,lowLetency});

const reducers = (state,action) =>{
    if(action?.type === AUTH_LOGOUT)
    return combinedReducer(undefined, action);
    else return combinedReducer(state, action);
}

export default reducers;
