import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: any) => ({
   SecondaryContainer: {
      display: "flex",
      gap: "0.5rem",
      marginTop: "0.65rem",
   },
   //chat
   outerBox: {
      position: "fixed",
      bottom: 0,
      right: '50%',
      left: '50%',
      zIndex: 300,
      borderRadius: '8px 8px 0px 0px',
      "& .MuiPaper-root": {
         boxShadow: '0px 4px 44px rgba(0, 0, 0, 0.21) !important'
      }
   },
   mainNormalAccordion: {
      width: "330px",
      borderRadius: "15px 15px 0px 0px",
      "& .MuiAccordionSummary-root.Mui-expanded": {
         display: 'flex',
         padding: '0px',
         minHeight: '50px'
      },
      "& .MuiAccordionSummary-root": {
         flexDirection: 'row-reverse',
         gap: '10px',
         height: '50px',
         alignItems: 'center',
         padding: '0px',
      },
      "& .MuiAccordionDetails-root": {
         padding: '8px 10px',
         borderTop: "2px solid #F1F1F1",
         "& .MuiIconButton-root": {
            padding: '0px !important'
         }
      },
   },
   userPhoto: {
      height: "35px",
      width: "35px",
      backgroundSize: "cover",
      borderRadius: "50%",
      backgroundColor: "lightgrey",
      marginRight: "4px"
   },
   chatUserName_CallConversion: {
      lineHeight: "40px",
      width: '165px',
      fontWeight: 600,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginRight: "4px"
   },
   chatUserName: {
      lineHeight: "40px",
      width: '200px',
      fontWeight: 600,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginRight: "4px"
   },
   chatDetails: {
      height: "351px",
      overflowY: "auto",
      zIndex: 1,
      "& .Disconnect": {
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         width: '100%',
         height: '100%'
      }
   },
   chatText: {
      width: "100%",
      marginTop: '6px',
      border: 'none',
      resize: 'none',
      maxHeight: '100px',
      overflow: 'scroll',
      "&:focus": {
         border: 'none !important',
         outline: "0px solid transparent",
      }
   },
   sendermessage: {
      backgroundColor: "#34519C",
      padding: " 10px",
      color: "white",
      borderRadius: "12px 0px 12px 12px",
      maxWidth: '-webkit-fill-available',
      wordBreak:'break-word'
   },
   sendername: {
      fontWeight: 500,
      textAlign: "end",
      fontSize: "11px",

   },
   receivermessage: {
      backgroundColor: "#EBEFF4",
      padding: "10px",
      color: "black",
      borderRadius: "0px 12px 12px 12px",
      maxWidth: '-webkit-fill-available'
   },
   receivername: {
      fontWeight: 500,
      textAlign: "start",
      fontSize: "11px"
   },
   senderStyle: {
      width:'302px',
      marginTop: "3px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      "& .TextWrap": {
         wordWrap: "break-word",
         whiteSpace: 'pre-line'
      },
      "& .DateTime": {
         marginTop: '2px',
         color: '#717171',
         fontSize: "9px",
         fontWeight: 300,
         lineHeight: '14.25px'
      }
   },
   receiverStyle: {
      marginTop: "3px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      "& .TextWrap": {
         wordWrap: "break-word",
         whiteSpace: 'pre-line'
      },
      "& .DateTime": {
         marginTop: '2px',
         color: '#717171',
         fontSize: "9px",
         fontWeight: 300,
         lineHeight: '14.25px'
      }
   },
   imagePreview: {
      width: '250px !important',
      filter: 'invert(0) !important',
      height: '174px',
      objectFit: 'cover',
      objectPosition: 'center',
      borderRadius: 15
   },
   DocumentName: {
      flex: 1,
      display: 'flex',
      gap: '10px',
      textAlign: 'left',
      cursor: "pointer",
      height: '60px',
      alignItems: 'center',
      '& .filename': {
         width: '200px',
         whiteSpace: 'nowrap',
         overflow: 'hidden !important',
         textOverflow: 'ellipsis !important',
      }
   },
   IconButton: {
      "& .MuiButtonBase-root": {
         padding: "5px !important"
      }
   },
   cancelBtn: {
      backgroundColor: '#B7BDCE !important',
      padding: '8.5px 25px',
      color: '#FFFFFF',
      borderRadius: "4px",
      lineHeight: "26px",
      fontSize: "14px",
      fontWeight: 600,
      textTransform: "none"
   },
   titleRoot: {
      borderBottom: '1px solid #CCCCCC',
      "& .close-btn": {
         display: "inline-block",
         width: "fit-content",
         position: "absolute",
         top: "12px",
         right: "16px",
      }
   },
   Date: {
      marginTop: '2px',
      color: '#717171',
      fontSize: "14px",
      fontWeight: 300,
      lineHeight: '16.25px',
      textAlign:'center',
      width:'100%'
   },
   IsTyping:{
      fontSize:'12px',
      color: "#717171",
      marginLeft: "10px"
   },
   IsTypingFirst:{
      fontWeight:600
   },
   ChatPage:{
      padding:"74px 1% 1% 1%",
   },
   reconnect:{
      color:'blue',position:'absolute',padding:"0px 30%",textAlign : "center",
      "& .loader":{
         height:"15px",width:'15px',margin:'2px 2px 0px 0px'
      },
      "& .text":{
         background:"white",
      }
   },
   DoctorOnlinestatus: {
      height: "10px",
      width: "10px",
      borderRadius: "5px",
      position: "relative",
      top: "26px",
      left: "-14px",
    },
    iconWrapper:{
      display: 'flex',
      gap: '10px',
      alignItems: "center",
    },
    callRequest:{
      padding: '12px 0 16px 0',
      color: '#717171',
      fontSize: "14px",
      fontWeight: 300,
      lineHeight: '16.25px',
      textAlign:'center',
      width:'100%'
    },
    joinCallBtn:{
      backgroundColor: "#24B648 !important",
      borderRadius: "4px !important",
      color: "#ffffff !important",
      margin:"0 !important",
      padding: "0px !!important",
      fontSize: "12px !important",
      minWidth: "48px !important",
      maxHeight: "32px !important",
      top: "4px",
      "& .MuiSvgIcon-root":{
         fontSize: "1.4rem"
      }
    },
    title: {
      fontSize: '18px',
      fontWeight: 600,
      marginTop: 0,
      textAlign: 'left',
      paddingLeft: '4px',
      marginBottom: 0
  },
  fullWidthButton: {
      display: 'flex',
      justifyContent: 'center',

      '& button': {
         flex: 1,
         fontSize: 18,
         padding: '10px 25px'
      }
   },
  TagCustomModel: {
      '& .MuiDialogTitle-root': {
         borderBottom: '1px solid #ddd'
      }
   },
   TagcustomDialog: {
      maxWidth: '485px',
      '& .MuiDialogTitle-root': {
         borderBottom: '1px solid rgb(184 207 225 / 50%)',
         padding: '15px 25px',
         '& h2': {
            fontSize: '18px'
         }
      },
      '& .close-btn': {
         padding: 0,
         top: '18px !important'
      },
      '& .MuiDialogContent-root': {
         padding: '20px',
         textAlign: 'center'
      },
      '& .MuiDialogActions-root': {
         padding: '20px !important',
      },
   }
}));

export default useStyles