import * as actionTypes from "../actions/actionTypes";
const initialstate = {
    instantdata: {},
    bookedId: '',
    onCall: false,
    Chatmessages: [],
    connecting:{},
    type: '',
    callID: '',
    callBackData: {},
    roomStatus: false,
    isRejoin: false,
    isDuplicateTab: false,
    userOnlineStatus:{},
    initialCallReject: false,
    errorCode: "",
    showRemoveReceptionist: false,
    cameraFacingMode : "", 
    patientInfo: {},
    chatToCallRequest: {},
    isReferInternal:false,
    userEndedData:{}
}
const instantVideo = (state = initialstate, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_INST_INFO:
            return { ...state, instantdata: action.payload };

        case actionTypes.UPDATE_BOOK_ID:
            return { ...state, bookedId: action.payload }

        case actionTypes.UPDATE_CALL_INFO:
        return {...state,onCall:action.payload}

        case actionTypes.UPDATE_CONNECTING_INFO:
        return {...state,connecting:action.payload}

        case actionTypes.INSTANT_VIDEO_TYPE:
        return {...state,type:action.payload}

        case actionTypes.UPDATE_CALL_ID:
            return { ...state, callID: action.payload }

        case actionTypes.UPDATE_CALLBACK_INFO:
            return { ...state, callBackData: action.payload };
        
        case actionTypes.ROOM_STATUS:
            return {...state,roomStatus:action.payload}
        case actionTypes.UPDATE_IS_REJOIN:
            return {...state, isRejoin:action.payload}
        case actionTypes.ACTIVE_CONSULTATION_DUPLICATE_TAB:
            return {...state, isDuplicateTab:action.payload}
        case actionTypes.USER_ONLINE_STATUS:
            return {...state, userOnlineStatus:{...state?.userOnlineStatus,...action.payload}}
        case actionTypes.TWILLIO_ERROR_CODE: 
            return {...state, errorCode: action.payload}    
        case actionTypes.REMOVE_RECEPTIONIST:
            return {...state, showRemoveReceptionist: action.payload}  
        case actionTypes.UPDATE_CAMERA_FLIP:
            return {...state, cameraFacingMode: action.payload}  
        case actionTypes.UPDATE_PATIENT_INFO:
            return {...state, patientInfo: action.payload}  
        case actionTypes.CHAT_TO_CALL_REQUEST:
            return {...state, chatToCallRequest: action.payload}   
        case actionTypes.EMPTY_INSTANT_VIDEO:
            return state = initialstate;  
        case actionTypes.REFER_INTERNAL:
            return { ...state,  isReferInternal: action.payload};
        case actionTypes.USER_ENDED_CONSULTATION_DATA:
            return { ...state, userEndedData: action.payload}     
        default :
        return state ;
    }
}

export default instantVideo;