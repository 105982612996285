import { useEffect, useState } from "react";
import { Tabs, Tab} from "@material-ui/core";
import useStyles from "./styles";
import { TabData, TabItemProps, ItabProps } from "../../../../models/consultation";
import Prescription from "./Prescription";
import MedicalRecords from "./MedicalRecords";
import PastConsultations from "./PastConsultations";
import LayoutContainer from "../../../../components/common/LayoutContainer";
import ic_prescription from "../../../../assets/images/consultation/ic_prescription.png";
import ic_records from "../../../../assets/images/consultation/ic_records.png";
import ic_history from "../../../../assets/images/consultation/ic_history.png";
import ic_prescription_grey from "../../../../assets/images/consultation/ic_prescription_grey.png";
import ic_records_grey from "../../../../assets/images/consultation/ic_records_grey.png";
import ic_history_grey from "../../../../assets/images/consultation/ic_history_grey.png";
import { RootStateOrAny, useSelector } from "react-redux";
import { USER_ROLES } from "../../../../utils/constants";
import UnsavedChangesWarning from "../unsavedWarning";

const TabUi = ({pastConsultationArray, data, medicalRecords, patientDetail, notAReceptionist, setUnSavedChanges, setPageNumber, pageNumber, isPastLoading,setDirty,resetDirty,userData,isLoadingVitals ,patientInfo,refetchVitals}: ItabProps) => {
  const [selectedTab, setSelectedTab] = useState<number>(1);
  const classes = useStyles();
  const { userRole } = useSelector((state: RootStateOrAny) => state?.auth);
  const [editPres,seteditPres]=useState(false);
  const [warningDlg,setwarningDlg]=useState(false);
  const [tabSelect,settabSelect]=useState(1);
  var element:any = document.getElementById("scrollDiv");
  
  const EditPres =( value :boolean) =>{
    seteditPres(value);
    if(value){
      setDirty()
    } else {
      resetDirty()
    }
  }

  useEffect(()=>{
    setUnSavedChanges && setUnSavedChanges(editPres)
  },[editPres])

  const renderTab = (item: TabData) => (
    <TabItem
      {...item}
      userRole={userRole}
      selectedTab={selectedTab}
      onClick={(value) =>
        {
          if(notAReceptionist && !patientDetail && editPres && (selectedTab === 1 && value != selectedTab))
          {
            setwarningDlg(true);
            settabSelect(value);
          }        
          !editPres && setSelectedTab(value)
        }
      }
    />
  );
  
  return (
    <LayoutContainer
      style={{ padding: "0px", marginTop: "0px", height: "fit-content" }}
      className={classes.root}
    >
      <Tabs value={selectedTab} className={userRole !== USER_ROLES.receptionist ?classes.tabContainer:classes.singleTabContainer}>
        {patientDetail ? PatientTabsArr.map(renderTab) :  !notAReceptionist ? ReceptionistTabsArr.map(renderTab) : TabsArr.map(renderTab)}
      </Tabs>
      {patientDetail ?
        <div className={classes.scrollView}>
          {selectedTab === 1 && <MedicalRecords medicalRecords={medicalRecords?medicalRecords.reverse():[]} />}
          {selectedTab === 2 && <PastConsultations pastConsultationArray={pastConsultationArray} setPageNumber={setPageNumber} pageNumber={pageNumber} isPastLoading={isPastLoading}/>}
        </div> : !notAReceptionist ? 
        <div className={classes.scrollView}>
          {selectedTab === 1 && <PastConsultations pastConsultationArray={pastConsultationArray} setPageNumber={setPageNumber} pageNumber={pageNumber} isPastLoading={isPastLoading}/>}
        </div>
        :
        <div className={classes.scrollView} id="scrollDiv">
          {selectedTab === 1 && <Prescription refetchVitals={refetchVitals} userData={userData} EditPres ={EditPres} data={data} element={element} isLoadingVitals={isLoadingVitals} patientInfo={patientInfo}/>}
          {selectedTab === 2 && <MedicalRecords medicalRecords={medicalRecords?medicalRecords:[]} />}
          {selectedTab === 3 && <PastConsultations pastConsultationArray={pastConsultationArray} setPageNumber={setPageNumber} pageNumber={pageNumber} isPastLoading={isPastLoading}/>}
        </div>
      }
    <UnsavedChangesWarning open={warningDlg} onClose={()=>{setwarningDlg(false)}} onSubmit={()=>{setSelectedTab(tabSelect),setwarningDlg(false),EditPres(false)}}/>
    </LayoutContainer>
  );
};

export default TabUi;

const TabItem = ({
  icon,
  selIcon,
  label,
  value,
  selectedTab,
  onClick,
  userRole,
}: TabItemProps) => {
  const classes = useStyles();
  const isSelected = value === selectedTab;
  return (
    <>
    {userRole!==USER_ROLES.receptionist ?
    <Tab
      onClick={() => onClick(value)}
      style={{fontSize: "12px"}}
      label={
        <span
          className={classes.tabItem}
          style={{ color: isSelected ? "#32529D" : "#C0C0C0" }}
        >
          <img
            className={classes.tabIcon}
            src={isSelected ? selIcon : icon}
            alt={label}
          />
          {label}
        </span>
      }
      value={value}
    ></Tab>
    :
    <h4 className={classes.pastTitle}>Past Consultations</h4>
   }
    </>
  );
};

const TabsArr = [
  {
    label: "Prescription",
    selIcon: ic_prescription,
    icon: ic_prescription_grey,
    value: 1,
  },
  {
    label: "Medical Records",
    selIcon: ic_records,
    icon: ic_records_grey,
    value: 2,
  },
  {
    label: "Past consultations",
    selIcon: ic_history,
    icon: ic_history_grey,
    value: 3,
  },
];

const PatientTabsArr = [
  {
    label: "Medical Records",
    selIcon: ic_records,
    icon: ic_records_grey,
    value: 1,
  },
  {
    label: "Consultation History",
    selIcon: ic_history,
    icon: ic_history_grey,
    value: 2,
  },
];

const ReceptionistTabsArr = [
  {
    label: "Past Consultations",
    selIcon: ic_history,
    icon: ic_history_grey,
    value: 1,
  },
];
