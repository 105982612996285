import { FormControl, FormHelperText, FormLabel, Select } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import clsx from "clsx";
import styles from "./styles";
import { genericObj } from "../../../models/common";

const CustomDropDownWhite = ({ className, ...props }: genericObj) => {
  const classes = styles();

  const renderLabel = (value: any) => {
    const data =  props.children && props.children.filter((item: any) => item && item.props.value === value);
    if (data && data.length > 0) {
      return data[0].props.children
    }
    return props.placeholder;
  }
  return (
    <>
      <FormLabel>{props.label}</FormLabel>
      <FormControl
        variant="outlined"
        className={clsx(classes.root, className)}
      >
        <Select
          displayEmpty
          renderValue={(value: any) => {
            const displayText: any = value != undefined && value != '' ? (props.multiple ? value.toString() : renderLabel(value)) : props.placeholder;
            return displayText;
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          IconComponent={ExpandMore}
          {...props}
          labelId={`select-outlined-label-${props.label}`}
        >
          {props.children}
        </Select>
        {props.helperText &&
          <FormHelperText>{props.helperText}</FormHelperText>
        }
      </FormControl>
    </>
  );
};

export default CustomDropDownWhite;
