import * as actionTypes from "../actions/actionTypes";
const onlinelist={}
const OnlineDoctors =(state = onlinelist, action)=>{
    switch(action.type)
    {
        case actionTypes.UPDATE_DOCTOR_INFO :
        const status =action.payload;
        return {...state,[`${status?.userId}`] : status};
        
        default :
        return state ;
    }
}

export default OnlineDoctors;