import React, { useState } from "react";
import { makeStyles,Portal,Snackbar, SnackbarContent,IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles((theme) => ({
  close: {
    padding: theme.spacing(0),
  },
  root: { backgroundColor: "#59bad1",padding:"2px 12px",minWidth:"200px"},
  message: {    
    alignItems: 'left',
    fontFamily:"WorkSans",
    fontSize: '14',
    fontWeight:'600',
    fontStyle:"normal",
    fontStretch:"normal",
    letterSpacing:"normal"
}
}));

function Notify(props) {
  const [open, setOpen] = useState(true);
  const { severity, message, onClose } = props;
  const classes = useStyles();
 
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setOpen(false);
  };

  return message ? (
    <Portal>
      <Snackbar 
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <SnackbarContent className={classes.root} 
          action={                   
          <IconButton        
                aria-label="close"
                color="inherit"
                className={classes.close}
                onClick={handleClose}>
                  <CloseIcon />
              </IconButton>}             
          message={<span className={classes.message}>{message}</span>}>        
        </SnackbarContent>
      </Snackbar> 
    </Portal>   
  ) : null;
}

export default Notify;
