export const styles = (theme) => ({
    root: {
        padding: '8px 16px !important', 
        cursor: 'pointer !important',
        fontSize: "14px !important",
        "& input": {
            display: "none"
        },
        "& .attachFile:hover" : {
            color : 'black'
        }
    }
});