import axios from "axios";
import { STAR_AUTH_SERVICE } from "../config/url.config";
import Services from "./services";

let CryptoJS = require('crypto-js');
const passPhrase = 'INAKAHDUMALEVIPA';
const iv = '9a2b73d130c8796309b776eeb09834b0';
const salt = '577bd45a17977269694908d80905c32a';
const keySize = 128 / 32;
const iterationCount = 1000;
const instance = new Services().getServiceInstance();

// key generation
function generateKey() {
  return CryptoJS.PBKDF2(
    passPhrase,
    CryptoJS.enc.Utf8.parse(salt),
    { keySize: keySize, iterations: iterationCount });
}

// encrypting the script
export const encryptCode = (plainText) => {
  const key = generateKey();
  let encrypted = CryptoJS.AES.encrypt(
    plainText,
    key,
    {
      iv: CryptoJS.MD5(CryptoJS.enc.Utf8.parse(iv)),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
  return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
}

export const decrypt =(data) =>{
  const key = generateKey();
  const rawData = CryptoJS.enc.Base64.parse(data);
  return CryptoJS.AES.decrypt({ ciphertext: rawData },key, 
    {
        keySize: keySize,
        iv: CryptoJS.MD5(CryptoJS.enc.Utf8.parse(iv)),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8);
}

let service = axios.create();


export const loginPortal = async (values: any) => {
  const data = await instance.post(`${STAR_AUTH_SERVICE}portal/login/password`, values);
  return data
}


export const loginOTP = data => {
  return service
    .post(STAR_AUTH_SERVICE + "login/otp", data)
    .then(res => res)
    .catch(err => err.response);
};

export const getOTP = data => {
  return service
    .post(STAR_AUTH_SERVICE + "get/otp", data)
    .then(res => res)
    .catch(err => err.response);
};

export const resendOTP = async (payload: any) => {
  const data = await instance.post(`${STAR_AUTH_SERVICE}resend-otp`, payload);
  return data
}


export const resetPassword = async (payload: any) => {
  const data = await instance.post(`${STAR_AUTH_SERVICE}sso/update-password`, payload);
  return data
}


export const verifyOTP = (token, otp) => {
  return service
    .post(STAR_AUTH_SERVICE + "verify-otp", {
      token,
      otp
    })
    .then(res => res)
    .catch(err => err.response);
};

export const clearUser = (payload: any) => {
  return instance
    .post(STAR_AUTH_SERVICE + "portal/logout", payload)
    .then(res => res)
    .catch(err => err.response);
};


service.interceptors.response.use((response) => response, (error) => {
  if (error.toJSON().message === 'Network Error') {
    return { data: { message: "Network Error" } }
  }
  else
    return error;
});

service.interceptors.request.use((response) => response, (error) => {
  if (error.toJSON().message === 'Network Error') {
    return { data: { message: "Network Error" } }
  }
  else
    return error;
})