import { MenuItem } from '@material-ui/core';
import React from 'react';
import styles from "./styles";
import { genericObj } from "../../../models/common";

const CustomMenuItemWhite = React.forwardRef((props: genericObj, ref: any) => {
    const classes = styles();
    return (
        <MenuItem {...props} className={classes.menuItem} ref={ref}>{props.children}</MenuItem>
    );
})
export default CustomMenuItemWhite;