import Axios from 'axios';
import Group from '../assets/images/Group.svg'

export const networkSpeedCalc  = (UpdateDropCount,count,dbData, getTimer) => 
{
    if(getTimer()){
        try{
            let startTime = new Date().getTime();
            Axios({url : `${Group}?${Date.now()}`,method:"GET",timeout:dbData?.LATENCY_REQUEST_TIMEOUT_MILLISECOND ? dbData?.LATENCY_REQUEST_TIMEOUT_MILLISECOND :1500}).then((response) =>
            {
            let endTime = new Date().getTime();
            let duration = (endTime - startTime) / 1000;
            let bitsLoaded = dbData?.LATENCY_REQUEST_IMAGE_SIZE_BYTE ? dbData?.LATENCY_REQUEST_IMAGE_SIZE_BYTE : 120832;
            var speedBps = (bitsLoaded / duration).toFixed(2);
            var speedKbps = (speedBps / 1024).toFixed(2);
            var speedMbps = (speedKbps / 1024).toFixed(2);
            count > 0  && UpdateDropCount(count - 1);
            }).catch((error) => (count <= 5 && UpdateDropCount(count + 1)))
        }
        catch (error) 
        {
            console.log(error);
        }
    }
}

