import {
  AppBar,
  CssBaseline,
  Drawer,
  Icon,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Toolbar,
  Typography,
  Box,
  Grid
} from "@material-ui/core";
import { Badge } from '@mui/material';
import Collapse from "@material-ui/core/Collapse";
import logo from "../../assets/images/onboarding/images/login_logo.svg";
import ic_notify from "../../assets/images/admin/icons/ic_notify.svg";
import SessionLogout from "../../utils/sessionLogout";
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from "@material-ui/icons";
import clsx from "clsx";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  NavLink as RouterLink,
  useHistory,
  useLocation,
} from "react-router-dom";
import { Avatar } from "@material-ui/core";
import LogoWhite from "../../assets/images/logo.svg";
import MenuUIIcon from "../../assets/images/menu-icon.svg";
import {
  MENU_EMPANELMENT_ADMIN,
  MENU_SUPER_ADMIN,
  MENU_DOCTOR,
  MENU_FIELD_AGENT,
  Receptionist_Login,
  MENU_FACILITY_ADMIN,
  TRANSCRIPTION_AGENT,
  Operational_Receptionist_Login,
  CLAIMS_PROCESS,
  PHC_OPERATIONS,
  PHC_OPERATIONS_ADMIN,
  PHC_LAB_WALKIN,
  PHC_FINANCE,
  UNIFIED_APP_LAB_VENDOR,
  UNIFIED_APP_VACCINATION
} from "../../config/menu.config";
import { USER_ROLES, VENDOR_TYPE } from "../../utils/constants";
import {
  setChatRoomList,
  setChatRoomInfo,
  instantChatSlot,
  setChatParticipantInfo,
  setEnableWidget,
  setClinicDetails,
  OnchatBusy,
  NewMsgReceived,
  EmptyChatState,
  initialSocketCreation
} from "../../store/actions";
import EnhancedMenu from "./EnhancedMenu";
import { styles } from "./styles";
import Notify from "../common/CommonToast";
import Push from "push.js";
import menulogo from "../../assets/images/poweredBySecurraWhite.svg";
import tick from "../../assets/images/charm_tick.png";
import SecurraLogoBlue from "../../assets/images/securraLogoBlue.svg";
import { switchUserRole } from "../../store/actions";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import CallPopUp from "../../components/callPopup";
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import {
  setPickUp,
  setChatNow,
  UpdateInstInfo,
  UpdateCallInfo,
  UpdateDoctorStatus,
  UpdateConnectingInfo,
  NewNotifyUpd,
  UpdateInstantType,
  UpdateCallbackInfo,
  UpdateRejoinStatus,
  UpdateDuplicateTab,
  UpdateUserOnlineStatus,
  UpdateRoomStatus,
  UpdatePatientInfo,
  UpdateUserEnd,
  UpdateBookId, UpdateCallId
} from "../../store/actions";
import {
  instantChatIO,
  closeSocket,
  sendMsg,
  getcurrentRoomID,
  socketConnection
} from "../../components/Websocket/InstantChatIO";
import CustomSubmitButton from "../../components/common/CustomSubmitButton";
import {
  ChatConnected,
  ChatDisConnected,
  ChatDetails,
  UpdateMessagelist,
  EmptyMessagelist,
  ChatRoomReconnect,
  setShowConsultation,
  DisconnectMsg,
  UpdateTyping,
  UpdateCameraFlip,
} from "../../store/actions";
import Notification from "./Notification/index";
import MyProfile from "../../assets/images/myprofile.svg";
import Logout from "../../assets/images/logout.svg";
import CustomDialog from "../common/CustomDialog";
import CloseIcon from "@material-ui/icons/Close";
import {clearUser} from "../../api/StarLogin";
import { authStorage } from "../../utils/login";
import { removeTrack } from "../../utils/audioVideoStop";
import { LatencyContext } from "../../hoc/Layout";
import MuiAlert from '@mui/material/Alert';
import useChatContext from "../../hooks/useChatContext/useChatContext";
import { TWILIO_ROOM } from "../../utils";
import { store } from "../../store";

const useStyles = makeStyles(styles);

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default function Layout({ children }) {
  const classes = useStyles();
  const [isExpand, setIsExpand] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [isLogin, setIsLogin] = useState(false);
  const [isMultiUser, setIsMultiUser] = useState("");
  const clinic = undefined;
  const [showSwitch, setShowSwitch] = useState(false);
  const [showfacility, setshowfacility] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const [facilityList, setfacilityList] = useState([]);
  const [rejoinFlag,setrejoinFlag]=useState(false);
  const { userRole, user, facility , socketInitiated, dbData, vendorDetails} = useSelector((state) => state?.auth);
  const [statusNotify,setStatusNotify] = useState(false);
  const FirstName =
    user?.name?.first && user?.name?.first.length > 0
      ? user?.name?.first[0].toUpperCase() + user?.name?.first.slice(1)
      : "";
  const LastName =
    user?.name?.last && user?.name?.last.length > 0
      ? user?.name?.last[0].toUpperCase() + user?.name?.last.slice(1)
      : "";
  const fullname = FirstName + " " + LastName;
  const {
    connected,
    chatdetails,
    Chatmessages,
    reconnecting,
    onchatBusy,
    skipCount,
    updateTyping
  } = useSelector((state) => state?.instantChat);
  const clinicData = undefined; /* Temporary for dev-star-release*/
  const [rolesVisible, setrolesVisible] = useState(false);
  const [facilityVisible, setfacilityVisible] = useState(false);
  const [MENU, setMenu] = useState([]);
  const { details } = useSelector((state) => state?.clinic);
  const { pickup, chatnow } = useSelector((state) => state?.call);
  const { instantdata, onCall, connecting, callBackData, userOnlineStatus, isDuplicateTab, patientInfo } = useSelector(
    (state) => state?.instantVideo
  );
  const [instantResponse, setinstantResponse] = useState({});
  const [socketConnected, setsocketConnected] = useState(false);
  const [onlineDoctorlist, setonlineDoctorlist] = useState({});
  const [headerDisable, setheaderDisable] = useState(false);
  const [NewArraivalMsg, setNewArraivalMsg] = useState({});
  const [ReconnectConsult, setReconnectConsult] = useState({
    show: false,
    path: "",
  });
  const [Online, setOnline] = useState(true);
  const [request, setRequest] = useState({
    message: "",
    loading: false,
    severity: "",
  });
  const [chatRoomDetail, setchatRoomDetail] = useState({
    action: "",
    data: "",
  });
  const { isChatWidgetEnabled } = useSelector((state) => state?.chat);
  const [duplicateTab, setDuplicateTab] = useState(false);
  const [isReconnectCheck, setIsReconnectCheck] = useState(false);
  const [patientWaiting,setPatientWaiting]=useState({show : false , message : ''});
  const { connect: chatConnect } = useChatContext();

  useEffect(() => {
    if (user) {
      getChatRooms();
    }
  }, [user]);

  useEffect(() => {
    if(statusNotify)
    {
      if(chatnow || pickup)
      {
        if(userRole == USER_ROLES.doctor){
         !localStorage.getItem('INSTANT_CHAT_JOINED') && sendMsg('updateStatus',{'userId': user?._id, 'status': 'online'})
        }
        userRole == USER_ROLES.receptionist && sendMsg('updateStatus',{'userId': user?._id, 'status': 'online'})
      }
      if(window.location.search.includes("callBack") && (callBackData?.type === 'CALLBACK' && callBackData?.status === 'ACCEPTED')){
        dispatch(UpdateInstInfo({}));
      }
      localStorage.getItem('INSTANT_CHAT_JOINED') && localStorage.removeItem('INSTANT_CHAT_JOINED')
      dispatch(setChatNow({ chatnow: false }));
      dispatch(setPickUp({ pickup: false }));
      setinstantResponse({});
      setStatusNotify(false);
    }
  },[statusNotify])
  /* Instant chat Method Handling */

  const generateRandomData = (sizeInBytes) => {
    const data = Buffer.alloc(sizeInBytes);
    for (let i = 0; i < sizeInBytes; i++) {
      data.writeUInt8(Math.floor(Math.random() * 256), i);
    }
    return data;
  }

  const ChatInfo = (chatRoomDetail) => {
    if (chatRoomDetail?.action === "CONNECTED") {
      dispatch(ChatConnected());
      dispatch(ChatRoomReconnect(false));
      reconnectCheck();
      !socketInitiated && (sendMsg('sendSelfNotification',{'userId': user?._id,'multipleLogin': true}), dispatch(initialSocketCreation(true))) 
    } else if (chatRoomDetail?.action === "DISCONNECTED") {
      dispatch(ChatDisConnected());
      dispatch(ChatRoomReconnect(false));
    } else if (chatRoomDetail?.action === "RECONNECTING") {
      dispatch(ChatRoomReconnect(chatRoomDetail?.data));
    } else if (chatRoomDetail?.action === "CHAT_DETAILS") {
      dispatch(ChatDetails(chatRoomDetail?.data));
    } else if (chatRoomDetail?.action === "UPDATE_MESSAGE_LIST") {
      const roomId = getcurrentRoomID();
      if(roomId === chatRoomDetail?.data?.channelId)
      {
        dispatch(UpdateMessagelist([chatRoomDetail?.data]));
        dispatch(NewMsgReceived(true));
      }           
    } else if (chatRoomDetail?.action === "EMPTY_MESSAGE_LIST") {
      dispatch(EmptyMessagelist());
    } else if (chatRoomDetail?.action === "DOCTOR_TYPING"){
      const parsed = typeof chatRoomDetail?.data === 'string' && JSON.parse(chatRoomDetail?.data) ;
      typeof chatRoomDetail?.data !== 'string' && chatRoomDetail?.data ? dispatch(UpdateTyping({[`${chatRoomDetail?.data?.senderId}`]:chatRoomDetail?.data})) : parsed ? dispatch(UpdateTyping({[`${parsed.senderId}`]:parsed})) : undefined
    } else if (chatRoomDetail?.action === "PATIENT_DISCONNECT") {
      const {instantdata,callBackData} = store.getState()?.instantVideo ?? {}
        if(window.location.search.includes("callBack"))
        {
          if(callBackData?.status === 'CALL_BACK_TOKEN_STORED' && instantdata?.bookedId === chatRoomDetail?.data?.bookedSlotId)
          dispatch(DisconnectMsg(`Patient ${chatRoomDetail?.data?.action === 'userEnded' ? 'ended' : 'left'} the ${['CHAT'].includes(chatRoomDetail?.data?.type) ? 'chat' : 'call'}`));
        }
        else{
          if(chatRoomDetail?.data?.type === "CHAT" || instantdata?.bookedId === chatRoomDetail?.data?.bookedSlotId)
            dispatch(DisconnectMsg(`Patient ${chatRoomDetail?.data?.action === 'userEnded' ? 'ended' : 'left'} the ${['CHAT'].includes(chatRoomDetail?.data?.type) ? 'chat' : 'call'}`));
        }
    } else if (chatRoomDetail?.action === "CALL_BACK_TOKEN"){
      dispatch(UpdateCallbackInfo({
        type: 'CALLBACK',
        status: chatRoomDetail?.action,
        data: chatRoomDetail?.data
      }));
    } else if (chatRoomDetail?.action === "BUSY_ON_ANOTHER_CALL" || chatRoomDetail?.action === "ANOTHER_CALL"
                || chatRoomDetail?.action === "BUSY_ON_ANOTHER_CHAT" || chatRoomDetail?.action === "RECEPTIONIST_BUSY" || chatRoomDetail?.action ==="RECEPTIONIST_OFFLINE"){
      dispatch(UpdateCallbackInfo({
        type: 'CALLBACK',
        status: 'BUSY'
      }));
      setRequest({ message: "", loading: true, severity: "" });
      setRequest({
        message: chatRoomDetail?.data?.message,
        loading: false,
        severity: "info",
      });
    } else if(chatRoomDetail?.action === "USER_REJECTED"){
      dispatch(UpdateCallbackInfo({...callBackData,...{type: 'CALLBACK', status: chatRoomDetail?.action}}));
      setRequest({ message: "", loading: true, severity: "" });
      setRequest({
        message: chatRoomDetail?.data?.message,
        loading: false,
        severity: "info",
      });
    } else if(chatRoomDetail?.action === "ACCEPTED") {
      dispatch(UpdateCallbackInfo({...callBackData,...{type: 'CALLBACK', status: chatRoomDetail?.action}}));
    } else if(chatRoomDetail?.action === 'INSTANT') {
      if(sessionStorage.getItem('CallBackConnecting')){
        sessionStorage.removeItem('CallBackConnecting');
        sendMsg('receptionistReject', {callId: chatRoomDetail?.data?.callId, userId: user?.userId, role: userRole})
      }else{
        if(!chatRoomDetail?.data?.method){
          dispatch(UpdateInstInfo(chatRoomDetail?.data));
          setinstantResponse(chatRoomDetail?.data)
          dispatch(setPickUp({ pickup: true }));
          chatnow && dispatch(setChatNow({ chatnow: false }));
        }
        if(chatRoomDetail?.data?.method === "Rejected" && chatRoomDetail?.data?.message === "Doctor rejected"){
          if(!["/InstantCall/room","/Appointments/room"].includes(window.location.pathname))
          setrejoinFlag(true);
          dispatch(UpdateConnectingInfo({ ...connecting, split: true, show: false, name: '' }));
        }
        else if(chatRoomDetail?.data?.method === "Accepted")
        {
          if(["/InstantCall/room","/Appointments/room"].includes(window.location.pathname))
          history.push({ pathname: "/Dashboard", state:"Doctor Joined the call" });
        else
           {
            setRequest({ message: "", loading: true, severity: ""})
            setTimeout(() => {setRequest({ message: "Doctor Joined the call", loading: false, severity: ""})},300)
           }
          sendMsg('updateStatus',{'userId': user?._id, 'status': 'online'});
        }
      }
    } else if(chatRoomDetail?.action === 'RECONNECT' || chatRoomDetail?.action === 'CHAT'){
      setinstantResponse(chatRoomDetail?.data)
    } else if(chatRoomDetail?.action === 'TAB_NOTIFICATION' && chatRoomDetail?.data?.userId === user?._id){
      dispatch(setChatNow({ chatnow: false }));
      dispatch(setPickUp({ pickup: false }));
      setinstantResponse({});
      if(chatRoomDetail?.data?.multipleLogin)
      {
        logoutUser(true);
        closeSocket();
      }
      else if (chatRoomDetail?.data?.consultationStatus === 'CONSULTATION_ENDED')
        setReconnectConsult({ show: false, path: "" });
      else 
        if(chatRoomDetail?.data?.consultationStatus !=="REJECTED"){
          sessionStorage.setItem("currentTabDuplicate", true)
          dispatch(UpdateDuplicateTab(true));
          sessionStorage.removeItem("new-consulation");
          sessionStorage.removeItem("currentTabDuplicate")
        }
      if((window.location.pathname === '/InstantCall/room' || window.location.pathname === '/chat-consulation' || window.location.pathname === "/Appointments/room") && 
        (chatRoomDetail?.data?.consultationStatus === 'CHAT_ACCEPTED' || chatRoomDetail?.data?.consultationStatus === 'CALL_ACCEPTED')){
        setDuplicateTab(true)
        if(window.location.pathname === '/chat-consulation'){
          sendMsg('removeSocket',{userId: window?.location?.search?.split('=')[1],role:userRole})
        }
        sendMsg('removeSocket',{userId: user?._id,role:userRole})
      }
      else
      setrejoinFlag(true);
    } else if(chatRoomDetail?.action === 'ONLINE_STATUS'){
      let user = chatRoomDetail?.data?.userId;
      dispatch(UpdateUserOnlineStatus({[`${user}`] : chatRoomDetail?.data}))
      dispatch(UpdateDoctorStatus(chatRoomDetail?.data))
    } else if(chatRoomDetail?.action === 'STATUS_NOTIFICATION'){
      const {instantdata} = store.getState()?.instantVideo ?? {}
      if(instantdata?.bookedId === chatRoomDetail?.data?.bookedSlotId || chatRoomDetail?.data?.type === "CHAT"){
        setStatusNotify(true);
        dispatch(UpdateRoomStatus(false));
        if(chatRoomDetail?.data && chatRoomDetail?.data?.type === "CHAT"){
          dispatch(UpdateUserEnd(true));
        }
      }
    }
    else if(chatRoomDetail?.action === 'UNATTENDED'){
      if(window.location.pathname.includes("/Appointments/room")||window.location.pathname.includes('/chat-consulation') || window.location.pathname.includes('/InstantCall/room')){
        history.push({
          pathname: "/Appointments",
            state: { response: {message:chatRoomDetail?.data?.message, severity:"error"}},
          });
      }
      setReconnectConsult({ show: false, path: "" });
      //AS PER BUG-25694 THIS LINE IS ADDED TO CLEAR REJOIN DATA, AFTER THE APPOINTMENT GOT UNATTENDED.
      dispatch(UpdateInstInfo({}));
    }
    else if (chatRoomDetail?.action === 'NOTIFICATION')
    {
      const roomId = getcurrentRoomID();
      if(roomId !== chatRoomDetail?.data?.room)
      {
        let payload = {};
        payload[chatRoomDetail?.data?.bookedSlotId] = {bookedSlotId:chatRoomDetail?.data?.bookedSlotId,date:chatRoomDetail?.data?.date,message:`${chatRoomDetail?.data?.patientName?.first} ${chatRoomDetail?.data?.patientName?.last} from recent consultation messaged you`,type:'CHAT_NOTIFICATION'};
        dispatch(NewNotifyUpd(payload));
      }
    }
    else if(chatRoomDetail?.action === 'USER_INFORMATION_ACK')
    setrejoinFlag(true);
    else if(chatRoomDetail?.action === "isdoctoroncall")
    setPatientWaiting({show : true , message : chatRoomDetail?.data?.message});
    else if(chatRoomDetail?.action === "callbackBookIdInformation"){
      const res = chatRoomDetail?.data?.callBackBookIdDetails;
      dispatch(UpdateInstInfo({...instantdata, bookedId: res?.bookedId}))
      dispatch(UpdateBookId(res?.bookedId));
      process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect?.(callBackData?.data?.token);
    }
    };

  /* Remove Socket Connection */
  const removeCallNotify = () => {
    closeSocket();
  };

  useEffect(() => {
    SetUserRole(userRole);
  }, [userRole, vendorDetails]);

  useEffect(() => {
    if (
      !location.pathname.includes("/facility-list") &&
      !location.pathname.includes("/Admin/Slider") &&
      !userRole.includes("UNIFIED_APP_EMPANELMENT_ADMIN") &&
      !location.pathname.includes("/createfacility")
    ) {
      JSON.stringify(details) != "{}" && setRolesList(details?.roles);
      facility && setfacilityList(facility);
      user?.practitionerProfile?.service && setShowSwitch(true);
      JSON.stringify(details) != "{}" && setshowfacility(true);
    } else {
      setShowSwitch(false);
      setshowfacility(false);
      !isChatWidgetEnabled && dispatch(setEnableWidget(true));
    }

    if (
      !location.pathname.includes("/Appointments/room") &&
      !location.pathname.includes("/InstantCall/room")
    ) {
      dispatch(setShowConsultation({ isShowConsultation: false }));
    }
  }, [location]);

  const submenuView = (type, check) => {
    if (type === "role") setrolesVisible(check);
    else setfacilityVisible(check);
  };
  useEffect(() => {
    !Push.Permission.has() &&
      !localStorage.getItem("notifyRequest") &&
      Push.Permission.request(onGranted, onDenied);
  }, []);

  const onGranted = () => {};

  const onDenied = () => {
    localStorage.setItem("notifyRequest", "true");
  };

  const getChatRooms = async () => {
    setRequest({ message: "", loading: true, severity: "" });
    if (user.chatUserId && clinic?._id) {
      try {
        const res = await getChatRoomList(user.chatUserId, clinic._id);
        if (res && res.status < 350 && res.data) {
          dispatch(setChatRoomList([...res.data]));
        } else {
          setRequest({
            message: res.data.message,
            loading: false,
            severity: "",
          });
        }
      } catch (error) {
        setRequest({
          message: error?.response?.data?.message || "Something went wrong",
          loading: false,
          severity: "error",
        });
      }
    }
  };

  React.useEffect(() => {
    if (clinicData) {
      dispatch(setClinicDetails(clinicData));
    }
  }, [clinicData]);

  const MENU_ITEMS = [
    {
      key: "1",
      caption: "Calender",
      onClick: () => {},
    },
    {
      key: "2",
      caption: "Notifications",
      onClick: () => {},
    },
    {
      key: "3",
      caption: "My Account",
      onClick: () => {
        history.push("/my-account");
      },
    },
    {
      key: "switch_role",
      caption: "Switch Role",
      subMenuItems: [
        {
          key: "4",
          caption: "Administrator",
          onClick: () => switchRole("administrators"),
        },
        {
          key: "5",
          caption: "Doctor",
          onClick: () => switchRole("doctors"),
        },
      ],
    },
    {
      key: "6",
      caption: "Signout",
      onClick: () => {},
    },
  ];
  const FACILITY_VIEW_ROLE =
    userRole === USER_ROLES.facility_admin ||
    userRole === USER_ROLES.super_admin ||
    userRole === USER_ROLES.doctor ||
    userRole === USER_ROLES.field_agent ||
    userRole === USER_ROLES.receptionist ||
    userRole === USER_ROLES.facility_receptionist;
  const DRAWER_EXCLUDED_PATHS = [
    "/Admin/Slider",
    "/admin/facility-list",
    "/createfacility",
    "/404/error",
  ];
  const WEB_SOCKET_BLOCK_PATHS = [
    "/Admin/Slider",
    "/admin/facility-list",
    "/createfacility",
  ];
  const FACILITY_LIST_DRAWER = ["/admin/facility-list"];

  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuanchorEl, setmenuAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [openNotification, setOpenNotification] = useState(false);
  const isMenuOpen = Boolean(anchorEl) || Boolean(menuanchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const time =
    new Date(localStorage.getItem("cookie-expires")).getTime() -
    new Date().getTime();

  const remeberKeytime = new Date(localStorage.getItem("rememberKey-expires")).getTime() - new Date().getTime();

  React.useEffect(() => {
    setInterval(() => {
      window.alert("Your session has been expired please login again");
      window.location.href = "/";
    }, time);
  }, []);

  /* Delete Remember Key after TimeExpires*/
  React.useEffect(() => {
   var remember =  setInterval(() => {
      authStorage.deleteRememberKey();
      clearInterval(remember);
    }, remeberKeytime);
  }, []);


  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.target);
  };

  const handleProfileMenuClose = (event) => {
    setAnchorEl(null);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <EnhancedMenu
      id={mobileMenuId}
      open={Boolean(isMobileMenuOpen)}
      menuItems={MENU_ITEMS}
      anchorElement={mobileMoreAnchorEl}
      onClose={handleMenuClose}
    />
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const findSelected = (currentUrl) => {
    return location.pathname.includes(currentUrl);
  };

  const switchRole = (role) => {
    history.push("/logout");
  };
  const handleMenuClick = () => {
    dispatch(setChatParticipantInfo({}));
    dispatch(setChatRoomInfo({}));
    if (!getCookie("SAID")) {
      window.location.href = "/";
    }
  };

  useEffect(() => {
    if (location) {
      setIsExpand(location.pathname);
    }
  }, [location]);

  const hideToolbar =
    location.pathname.includes("/Admin/Slider") ||
    location.pathname.includes("/404/error");
  const handleExpand = (text) => {
    setIsExpand((preval) => {
      if (preval !== text.url) {
        return text.url;
      } else {
        return null;
      }
    });
  };

  function SetUserRole(userRole) {
    switch (userRole) {
      case USER_ROLES.super_admin:
        setMenu(MENU_SUPER_ADMIN);
        break;
      case USER_ROLES.facility_admin:
        setMenu(MENU_FACILITY_ADMIN);
        break;
      case USER_ROLES.empanelment_admin:
        setMenu(MENU_EMPANELMENT_ADMIN);
        break;
      case USER_ROLES.doctor:
        setMenu(MENU_DOCTOR);
        break;
      case USER_ROLES.receptionist:
        setMenu(Receptionist_Login);
        break;
      case USER_ROLES.facility_receptionist:
        setMenu(Receptionist_Login);
        break;
      case USER_ROLES.field_agent:
        setMenu(MENU_FIELD_AGENT);
        break;
      case USER_ROLES.transcription_agent:
        setMenu(TRANSCRIPTION_AGENT);
        break;
      case USER_ROLES.opd_ops:
        setMenu(Operational_Receptionist_Login);
        break;
      case USER_ROLES.claims_admin:
        setMenu(CLAIMS_PROCESS);
        break;
      case USER_ROLES.claims_processor:
        setMenu(CLAIMS_PROCESS);
        break;
      case USER_ROLES.phc_lab_walkin:
        setMenu(PHC_LAB_WALKIN);
        break;
      case USER_ROLES.phc_finance_admin:
        setMenu(PHC_FINANCE);
        break;
      case USER_ROLES.phc_operation:
        setMenu(PHC_OPERATIONS);
        break;
      case USER_ROLES.phc_operation_admin:
        setMenu(PHC_OPERATIONS_ADMIN);
        break;
      case USER_ROLES.lab_vendor:
        vendorDetails?.vendorType === VENDOR_TYPE.lab ? setMenu(UNIFIED_APP_LAB_VENDOR): setMenu(UNIFIED_APP_VACCINATION);
        break;
      default:
        setMenu([]);
        break;
    }
  }
  /* New Notification Arraival */

  useEffect(() => {
    if (NewArraivalMsg && JSON.stringify(NewArraivalMsg) !== "{}")
      dispatch(NewNotifyUpd(NewArraivalMsg));
  }, [NewArraivalMsg]);

  /* SOCKET IO OPEN */
  useEffect(() => {
    if(user?._id)
    {
        closeSocket();
        instantChatIO(ChatInfo,user?._id);
        dispatch(UpdateDuplicateTab(false))
   }
   },[user?._id])

  useEffect(()=>{
    if (
      !location.pathname.includes("/Appointments/room") &&
      !location.pathname.includes("/InstantCall/room") && !location.pathname.includes("/chat-consulation") && 
      (sessionStorage.getItem("INSTANT_CHAT_INITIATED") ||sessionStorage.getItem("INSTANT_CALL_INITIATED"))
    ){
      setIsReconnectCheck(true);
      setDuplicateTab(false);
      sessionStorage.removeItem("currentTabDuplicate")
      setReconnectConsult({ show: false, path: "" });
      removeTrack();
    }

    if (
      location.pathname.includes("/Appointments/room") ||
      location.pathname.includes("/InstantCall/room") || location.pathname.includes("/chat-consulation")
    ){
      setReconnectConsult({ show: false, path: "" });
    }

    if(!location.pathname.includes("/InstantCall/room") && JSON.stringify(patientInfo) !== '{}'){
      dispatch(UpdatePatientInfo({}));
    }
  },[location])

  useEffect(()=>{
    if(isReconnectCheck){
      setrejoinFlag(true);
      dispatch(UpdateDuplicateTab(false))
      sessionStorage.removeItem("INSTANT_CHAT_INITIATED");
      sessionStorage.removeItem("INSTANT_CALL_INITIATED");
      setIsReconnectCheck(false)
    }
  },[isReconnectCheck])

  /* Initaite User Join */
  const reconnectCheck = () => {
    sendMsg('join',{userId: user?._id,type:"join",role:userRole});
  }

  /* After User Join Acknowledgement UserRejoin should check */
  useEffect(()=>{
    if(rejoinFlag)
    {
      if(userRole === USER_ROLES?.receptionist){
        if(sessionStorage.getItem("recepitionistEndCall"))
        {
          sessionStorage.removeItem("recepitionistEndCall");
          setTimeout(() => {sendMsg('receptionistFirstCheck',{userId: user?._id});},1000)
        }
        else
        sendMsg('receptionistFirstCheck',{userId: user?._id});     
      } else if(userRole === USER_ROLES?.doctor){
        sendMsg('doctorFirstCheck',{userId: user?._id});
      }else if(userRole === USER_ROLES?.facility_admin){
        sendMsg('adminFirstCheck',{userId: user?._id});
      }
      setrejoinFlag(false);
    }
  },[rejoinFlag])

  /* Update Redux state For Instant Call & chat*/
  useEffect(() => {
    let role =
      userRole === USER_ROLES.receptionist ||
      userRole === USER_ROLES.opd_ops ||
      userRole === USER_ROLES.facility_receptionist ||
      userRole === USER_ROLES.doctor;
    if (role) {
      if (location.pathname.includes("/InstantCall/room") && !onCall) {
        dispatch(UpdateCallInfo(true));
      } else if (onCall && !location.pathname.includes("/InstantCall/room")) {
        dispatch(UpdateCallInfo(false));
      }
    }
    if (
      userRole === USER_ROLES.doctor &&
      onchatBusy &&
      !location.pathname.includes("/chat-consulation")
    ) {
      dispatch(OnchatBusy(false));
    }
  }, [location]);

  /* Trigger Websocket On Internet Connect and Disconnect */

  const userOnline = () => setOnline(true);
  const userOffline = () => setOnline(false);

  useEffect(() => {
    function Online() {
      userOnline();
    }
    function Offline() {
      userOffline();
    }
    window.addEventListener("online", Online, true);
    window.addEventListener("offline", Offline, true);
    return () => {
      window.removeEventListener("online", Online, true);
      window.removeEventListener("offline", Offline, true);
    };
  }, []);

  /* Websocket CallBack Message Type */
  useEffect(() => {
    if (JSON.stringify(instantResponse) !== "{}") {
      if (
        instantResponse?.type === "INSTANT" &&
        instantResponse?.method === "Rejected"
      ) {
        dispatch(
          UpdateConnectingInfo({
            ...connecting,
            split: true,
            show: false,
            name: "",
          })
        );
      } else if (instantResponse?.type === "CHAT") {
        dispatch(ChatDetails(instantResponse));
        dispatch(setChatNow({ chatnow: true }));
        pickup && dispatch(setPickUp({ pickup: false }));
      } else if (instantResponse?.type === "RECONNECT" && !location.pathname.includes("/InstantCall/room")) {
        const isCallBckVirtual = instantResponse?.consulationType === "VIRTUAL_CONSULTATION" && instantResponse?.isCallBack; 
        if (instantResponse?.consulationType === "INSTANT" || isCallBckVirtual) {
          const copyInstRes = {...instantResponse,...{consulationType : "INSTANT"}} // For Page Routing Issue Handling this as Instant Call
          dispatch(UpdateInstInfo(copyInstRes));
          if (location.pathname.includes("/InstantCall/room")) {
            setinstantResponse({});
            dispatch(UpdateCallInfo(true));
            history.push({
              pathname: "/InstantCall/room",
              search: `${instantResponse?.roomId ? instantResponse?.roomId : instantdata?.roomId}`,
              state: { row: { row: { _id: instantResponse?.bookedId || instantdata?.bookedId } } },
            });
          } else setReconnectConsult({ show: true, path: "/InstantCall/room" });
        } else if (instantResponse?.consulationType === "CHAT") {
          dispatch(ChatDetails(instantResponse));
          dispatch(instantChatSlot(instantResponse?.bookedId));
          if (location.pathname.includes("/chat-consulation")) {
            instantResponse.chatbookedID = instantResponse?.bookedId;
            instantResponse.userId = instantResponse?.user;
            const roomID = new URLSearchParams(location.search).get("roomID")            
            const {name ,roomId,userId,chatLogId,chatbookedID,user,bookedId : BookedId} = instantResponse ;
            roomID !== roomId && dispatch(EmptyMessagelist());
            sendMsg('join',{ name, room:roomId, userId : userId || user, "type": "web",  chatLogId,bookSlotId: BookedId});
            setinstantResponse({});
            dispatch(OnchatBusy(true));
            history.push({
              pathname: "/chat-consulation",
              search: `?roomID=${chatdetails?.roomId}`,
              state: { row: { row: { _id: chatdetails?.bookedId } } },
            });
          } else setReconnectConsult({ show: true, path: "/chat-consulation" });
        }
        else if(instantResponse?.consulationType === "VIRTUAL_CONSULTATION")
        {
          dispatch(UpdateInstInfo(instantResponse));
          const stateValue = instantResponse?.isCallBack ? { row: { row: { _id: instantResponse?.bookedId } }, consultType : instantResponse?.consulationType  } : { row: { row: { _id: instantResponse?.bookedId } } }
          if (location.pathname.includes("/Appointments/room")) {
            setinstantResponse({});
            dispatch(UpdateCallInfo(true));
            history.push({
              pathname: "/Appointments/room",
              search: `${instantResponse?.roomId ? instantResponse?.roomId : instantdata?.roomId}`,
              state: stateValue,
            });
          } else setReconnectConsult({ show: true, path: "/Appointments/room" });
        }
      } else if (instantResponse?.type === "VIRTUAL_CONSULTATION"){
        dispatch(UpdateInstInfo(instantResponse));
        dispatch(setPickUp({ pickup: true }));
      }
    }
  }, [instantResponse]);

  useEffect(()=>{
    if(ReconnectConsult.show){
      dispatch(UpdateRejoinStatus(true))
    } else {
      dispatch(UpdateRejoinStatus(false))
    }
  },[ReconnectConsult])

  /* Update Doctor Online list*/
  useEffect(() => {
    if (JSON.stringify(onlineDoctorlist) !== "{}") {
      dispatch(UpdateDoctorStatus(JSON.parse(onlineDoctorlist)));
    }
  }, [onlineDoctorlist]);
  // /* Remove Notification on tab close*/
  useEffect(() => {
    pickup && dispatch(setPickUp({ pickup: false }));
    chatnow && dispatch(setChatNow({ chatnow: false }));
    dispatch(ChatDisConnected());
    dispatch(OnchatBusy(false));
    dispatch(EmptyChatState());
    sessionStorage.removeItem("INSTANT_CHAT_INITIATED");
    sessionStorage.removeItem("INSTANT_CALL_INITIATED");
    if (
      userRole === USER_ROLES.doctor &&
      location.pathname.includes("/chat-consulation")
    )
      dispatch(EmptyMessagelist());
    if( !location.pathname.includes("/Appointments/room") && !location.pathname.includes("/InstantCall/room") 
      && !location.pathname.includes("/chat-consulation") && sessionStorage.getItem('CallBackConnecting'))
    { sessionStorage.removeItem('CallBackConnecting') }
  }, []);
  const receptionist =
    userRole === USER_ROLES.receptionist ||
    userRole === USER_ROLES.opd_ops ||
    userRole === USER_ROLES.facility_receptionist;

  /* close Websocket connection for header excluded pages */
  useEffect(() => {
    if (DRAWER_EXCLUDED_PATHS.includes(window.location.pathname)) {
      removeCallNotify();
    }
    if(window.location.pathname ==='/Dashboard' && sessionStorage.getItem("socketOn")){
      socketConnection && closeSocket();
      instantChatIO(ChatInfo,user?._id);
      sessionStorage.removeItem("socketOn")
    }
    if (window.location.pathname.includes("/chat-consulation")) {
      setheaderDisable(true);
      handleDrawerClose();
    }
    if (
      !window.location.pathname.includes("/Appointments/room") &&
      !window.location.pathname.includes("/InstantCall/room") &&
      !window.location.pathname.includes("/chat-consulation") &&
      headerDisable
    ) {
      setheaderDisable(false);
      handleDrawerOpen();
    }
    if (
      (window.location.pathname.includes("/Appointments/room") ||
        window.location.pathname.includes("/InstantCall/room")) &&
      !headerDisable
    ) {
      setheaderDisable(true);
      handleDrawerClose();
    }
  }, [location]);
  const logoutUser = (value) => {
    setIsMultiUser(value);
    setIsLogin(true);
    if(TWILIO_ROOM.exist())
    TWILIO_ROOM.disconnect();
    dispatch(setPickUp({ pickup: false }));
    dispatch(setChatNow({ chatnow: false }));
  };
  useEffect(()=>{
    if(isLogin){
      window.onbeforeunload = function() {
        localStorage.clear();
     }
    }
    return () => {
      window.onbeforeunload = null;
    }
  },[isLogin])

  const clearLogout = async () => {
    try{
      setRequest({ message: "", loading: true, severity: "" });
      const res = await clearUser({practitionerId: user._id});
      if(res.status === 200) {
        setRequest({ message: "", loading: false, severity: "" });
        authStorage.deleteRememberKey();
        history.push("/logout");
      }
    } catch (error) {
      setRequest({
        message: error?.response?.data?.message || "Something went wrong",
        loading: false,
        severity: "error",
      });
    }
  }


/* Socket Action Listener for Camera Flip */
useEffect(() => {
  if(socketConnection)
  {
    socketConnection("cameraSwitchNotification",(event) => {  /* Event Listener sended by mobile while front/Back camera switch */
    try{
      const {cameraAction} = JSON.parse(event);
      const cameraFlip = cameraAction === "FRONT_TO_BACK" ?  "rotateY(180deg)" : "rotateY(360deg)";
      dispatch(UpdateCameraFlip(cameraFlip))
    }
    catch(err){}
    })
  }
},[socketConnection])
  
  const INSTANT_CALL_NOTIFY =
    pickup &&
    !DRAWER_EXCLUDED_PATHS.includes(window.location.pathname) &&
    JSON.stringify(instantResponse) !== "{}";
  const INSTANT_CHAT_NOTIFY =
    chatnow &&
    !DRAWER_EXCLUDED_PATHS.includes(window.location.pathname) &&
    JSON.stringify(instantResponse) !== "{}";
  const NotifyPopEnable = INSTANT_CALL_NOTIFY || INSTANT_CHAT_NOTIFY;

  const snackComponent = useMemo(() => 
  <Snackbar
  id="Patient_Joined"
  autoHideDuration={10000}
  ClickAwayListenerProps={{
    mouseEvent : false,
  }}
  anchorOrigin={{ vertical: 'top', horizontal: 'right'  }}
  open={patientWaiting?.show}
  onClose={()=>(setPatientWaiting({show : false , message : ''}))}
  key={"Patient_Joined"}
  TransitionComponent={(props) => <Slide {...props} direction="left"/>}
  >
    <MuiAlert  onClose={() => setPatientWaiting({show : false , message : ''})} severity="info">{patientWaiting?.message}</MuiAlert>
  </Snackbar>
  ,[patientWaiting?.show])

  return (
    <div className={clsx(classes.root)}>
      {NotifyPopEnable && (<CallPopUp
          Notifydata={instantResponse}
          setinstantResponse={setinstantResponse}
        /> 
      )}
      {request.message && (
        <Notify message={request.message} severity={request.severity} />
      )}
      <CssBaseline />
      {hideToolbar ? null : (
        <AppBar
          color="default"
          position="fixed"
          style={headerDisable ? { cursor: "not-allowed" } : {}}
          className={clsx(
            classes.appBar,
            window.location.pathname !== "/createfacility"
              ? {
                  [classes.appBarShift]: open,
                }
              : classes.appBarFull
          )}
        >
          <Toolbar
            style={
              headerDisable
                ? {
                    pointerEvents: "none",
                    maxHeight: "58px",
                    minHeight: "58px",
                  }
                : { maxHeight: "58px", minHeight: "58px" }
            }
          >
            {window.location.pathname === "/createfacility" && (
              <div>
                <img src={SecurraLogoBlue} alt="securra logo" />
              </div>
            )}
            <div
              className={classes.sectionDesktop}
              style={
                headerDisable
                  ? { cursor: "not-allowed", pointerEvents: "none" }
                  : {}
              }
            >
              {userRole === USER_ROLES.doctor && (
                <Notification
                  setOpenNotification={setOpenNotification}
                  openNotification={openNotification}
                />
              )}
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                color="inherit"
                disableRipple={true}
                disableFocusRipple={true}
                className={classes.profileButton}
                onMouseEnter={handleProfileMenuOpen}
                onMouseLeave={handleProfileMenuClose}
              >
                {user?.profilePic ? (
                  <div style={{display: 'flex'}}>
                    <img
                      className={classes.profileIcon}
                      src={user?.profilePic}
                      loading="lazy"
                      decoding="async"
                    />
                     {!dbData?.PRACTITIONER_ONLINE_STATUS_VISIBILITY_ROLES?.includes(userRole) && <div className={classes.DoctorOnlinestatus} 
                      style={connected ? (userOnlineStatus && userOnlineStatus[`${user?._id}`]?.status === 'busy') ?  {background: '#d32f2f'} : (userOnlineStatus && userOnlineStatus[`${user?._id}`]?.status === 'offline') ?  {background : 'yellow'} : {background: '#2e7d32'} : {background : 'yellow'}}/>}
                  </div>
                ) : (
                  <>
                  <Badge
                  color={connected ? (userOnlineStatus && userOnlineStatus[`${user?._id}`]?.status === 'busy') ? 'error' : (userOnlineStatus && userOnlineStatus[`${user?._id}`]?.status === 'offline') ? 'warning' : 'success' : 'warning'}
                   overlap="circular"
                   anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                   variant={dbData?.PRACTITIONER_ONLINE_STATUS_VISIBILITY_ROLES?.includes(userRole) ? "standard":"dot"}
                   >
                    <Avatar className={classes.avatarIcons}>
                        {user?.name?.first?.toUpperCase()?.charAt(0) +
                      user?.name?.last?.toUpperCase()?.charAt(0)}
                     </Avatar>
                  </Badge>
                  </>
                )}
                <div className={classes.profileTextContainer}>
                  {/* <span className={classes.profileName}>{clinic?.name}</span> */}
                  <span className={classes.profileRole}>
                    <Typography style={{ fontSize: "0.85rem" }}>
                      {fullname}
                    </Typography>
                    {!FACILITY_LIST_DRAWER.includes(window.location.pathname) &&
                      user?.practitionerProfile?.service?.map((data) => {
                        if (userRole === data?.practitionerRole?.id) {
                          return data?.practitionerRole?.name;
                        }
                      })}
                  </span>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {!isMenuOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </div>
                <div
                  className={clsx(
                    classes.profileMenu,
                    isMenuOpen
                      ? "openProfileMenuDropdown"
                      : "profileMenuDropdown"
                  )}
                >
                  {/* {showfacility && <div style={{ cursor: 'default', borderBottom: '1px solid #E6E6E6' }}>
                  <span style={{ color: '#828282', paddingTop: '10%' }}>Facility</span>
                  <div style={{ display: 'flex', flexDirection: "row", padding: '5%', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div style={{
                      height: "40px", width: "40px", backgroundImage: `url(${details?.logo})`, borderRadius: '2rem', marginRight: '10px', backgroundSize: "cover",
                      backgroundPosition: "center"
                    }}></div>
                    <div style={{ color: '#333333', wordWrap: 'break-word' }}>{details?.name}</div>
                  </div>
                </div>} */}
                  {/* {showSwitch && rolesList.length >= 2 && <div style={{ borderBottom: '1px solid #E6E6E6' }} onMouseEnter={() => { submenuView('role', true) }}
                  onMouseLeave={() => { submenuView('role', false) }}>
                  <div style={{ color: '#828282', padding: '16px', paddingLeft: "8px" }}
                  >Roles
                    <div className={clsx(classes.subMenuRoles, rolesVisible ? "openProfileMenuDropdown" : "profileMenuDropdown")}>
                      {rolesList?.map((data, i) => {
                        return (
                          <div style={{ listStyleType: 'none', marginLeft: '0', color: '#000000', padding: '8px', display: 'flex', justifyContent: "space-between" }} onClick={() => {
                            removeCallNotify()
                            dispatch(switchUserRole(data?._id));
                            history.replace('/');

                          }}>
                            {data?.name}
                            {userRole && userRole.includes(data?._id) ? <img src={tick} /> : ''}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>} */}
                  <div
                    className="profileDropdownMenuItem"
                    onClick={()=>   history.push("/MyProfile")}
                  >
                    <img src={MyProfile} width={16} height={16} />
                    <div>My Profile</div>
                  </div>
                  {showfacility && facilityList.length >= 2 && (
                    <div
                      style={{
                        color: "#828282",
                        padding: "16px",
                        borderBottom: "1px solid #E6E6E6",
                      }}
                      onMouseEnter={() => {
                        submenuView("facility", true);
                      }}
                      onMouseLeave={() => {
                        submenuView("facility", false);
                      }}
                    >
                      Change Facility
                      <div
                        className={clsx(
                          classes.subMenufacility,
                          facilityVisible
                            ? "openProfileMenuDropdown"
                            : "profileMenuDropdown"
                        )}
                      >
                        {facilityList?.map((data, i) => {
                          return (
                            <div
                              key={i}
                              className={
                                details?.id.includes(data?.id)
                                  ? classes.activeback
                                  : facilityList.length - 1 === i
                                  ? classes.grey
                                  : clsx(classes.borderbottom, classes.grey)
                              }
                              style={{
                                listStyleType: "none",
                                marginLeft: "0",
                                color: "#000000",
                                padding: "8px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                              onClick={() => {
                                removeCallNotify();
                                dispatch(switchUserRole(data?.roles[0]?._id));
                                dispatch(setClinicDetails(data));
                                history.replace("/");
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={
                                    data?.logo
                                      ? {
                                          height: "40px",
                                          width: "40px",
                                          backgroundImage: `url(${data?.logo})`,
                                          borderRadius: "2rem",
                                          marginRight: "10px",
                                          backgroundSize: "cover",
                                          backgroundPosition: "center",
                                        }
                                      : {}
                                  }
                                ></div>
                                {!data?.logo && (
                                  <span style={{ marginRight: "25px" }}>
                                    <LocalHospitalIcon />
                                  </span>
                                )}
                                <div>{data?.name}</div>
                              </div>
                              <div>
                                {details && details?.id.includes(data?.id) ? (
                                  <img src={tick} height={"20px"} />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  <div
                    className="profileDropdownMenuItem"
                    onClick={clearLogout}
                  >
                    <img src={Logout} />
                    <div>Logout</div>
                  </div>
                </div>
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      )}
      {renderMobileMenu}
      <div className={clsx(classes.root)}>
        {!DRAWER_EXCLUDED_PATHS.includes(window.location.pathname) && (
          <div style={headerDisable ? { cursor: "not-allowed" } : {}}>
            <Drawer
              style={headerDisable ? { pointerEvents: "none" } : {}}
              variant="permanent"
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              })}
              classes={{
                paper: clsx(classes.ScrollDrawer, {
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                }),
              }}
            >
              <div className={open ? classes.toolbar : classes.toolbarClose}>
                {open && (
                  <Link component={RouterLink} to="/">
                    <img
                      src={LogoWhite}
                      alt="securra logo"
                      className={classes.logo}
                    />
                  </Link>
                )}
                <IconButton
                  onClick={open ? handleDrawerClose : handleDrawerOpen}
                >
                  <img src={MenuUIIcon} />
                </IconButton>
              </div>

              <List>
                {!open &&
                  MENU.map((text, index) => {
                    return (
                      <List
                        key={index}
                        title={text.label}
                        className={clsx(
                          classes.MobileMenuLink,
                          classes.menuLink,
                          open === false ? classes.customhoverlink : "",
                          text.disabled ? "disabled" : ""
                        )}
                      >
                        <ListItem
                          button
                          disableRipple={text.disabled}
                          disableTouchRipple={text.disabled}
                          disableFocusRibble={text.disabled}
                          selected={findSelected(text.url)}
                          className={clsx(classes.menuItem, "menu-item-mobile")}
                          onClick={() => {
                            if (
                              !text.submenu.length > 0 &&
                              text?.url &&
                              !location.pathname.includes(text.url)
                            ) {
                              history.push(text.url);
                            }
                          }}
                        >
                          <ListItemIcon>
                            <Icon>
                              {findSelected(text.url)
                                ? text.iconActive
                                : text.icon}
                            </Icon>
                          </ListItemIcon>
                          <List
                            component="ul"
                            className={clsx(
                              classes.nested,
                              classes.MobileMenuDropdown,
                              "onhover-open"
                            )}
                          >
                            <Link
                              to={text.url}
                              disablePadding
                              component={
                                !text.disabled ? RouterLink : undefined
                              }
                              onClick={() => handleMenuClick()}
                            >
                              <ListItemText
                                className={classes.MenuTopPrimaryWrap}
                                classes={{ primary: classes.MenuTopPrimary }}
                                primary={text.label}
                              />
                            </Link>
                            {text.submenu.map((subMenuItem, submenuIndex) => (
                              <List
                                component="li"
                                disablePadding
                                className={classes.nestedOnMobile}
                              >
                                <Link
                                  component={RouterLink}
                                  to={subMenuItem.url}
                                  key={submenuIndex}
                                  title={subMenuItem.label}
                                  className={classes.menuLink}
                                  onClick={() => handleMenuClick()}
                                >
                                  <ListItemText primary={subMenuItem.label} />
                                </Link>
                              </List>
                            ))}
                          </List>
                        </ListItem>
                      </List>
                    );
                  })}
                {open &&
                  MENU.map((text, index) => {
                    return (
                      <Link
                        component={!text.disabled ? RouterLink : undefined}
                        to={text.url}
                        key={index}
                        title={text.label}
                        className={`${classes.menuLink} ${
                          open === false ? classes.customhoverlink : ""
                        } ${text.disabled ? "disabled" : ""}`}
                        onClick={() => handleMenuClick()}
                      >
                        <ListItem
                          button
                          disableRipple={text.disabled}
                          disableTouchRipple={text.disabled}
                          disableFocusRibble={text.disabled}
                          selected={findSelected(text.url)}
                          className={`${classes.menuItem} ${
                            text.disabled ? "disabled" : ""
                          }`}
                          onClick={() => handleExpand(text)}
                        >
                          <ListItemIcon>
                            <Icon>
                              {findSelected(text.url)
                                ? text.iconActive
                                : text.icon}
                            </Icon>
                          </ListItemIcon>
                          <ListItemText primary={text.label} />
                        </ListItem>
                        <Collapse
                          in={text.submenu.length > 0 && open}
                          timeout="auto"
                          className="collaps-menu"
                        >
                          <Collapse
                            in={isExpand ? isExpand.includes(text.url) : false}
                            timeout="auto"
                          >
                            <List className={clsx(classes.nested)}>
                              {text.submenu.map((subMenuItem, submenuIndex) => (
                                <Link
                                  component={RouterLink}
                                  to={subMenuItem.url}
                                  key={submenuIndex}
                                  title={subMenuItem.label}
                                  className={classes.menuLink}
                                  onClick={() => handleMenuClick()}
                                >
                                  <ListItem
                                    button
                                    selected={findSelected(subMenuItem.url)}
                                    className={classes.menuItem}
                                  >
                                    <ListItemIcon>
                                      <Icon>
                                        {findSelected(subMenuItem.url)
                                          ? subMenuItem.iconActive
                                          : subMenuItem.icon}
                                      </Icon>
                                    </ListItemIcon>
                                    <ListItemText primary={subMenuItem.label} />
                                  </ListItem>
                                </Link>
                              ))}
                            </List>
                          </Collapse>
                        </Collapse>
                      </Link>
                    );
                  })}
              </List>
              {open && (
                <div className={classes.menuBottom}>
                  <img
                    src={menulogo}
                    alt="securra"
                    style={{ width: "140px" }}
                  />
                  <span className={classes.version}>
                    v {process.env.REACT_APP_DEPLOYED_VERSION}
                  </span>
                </div>
              )}
            </Drawer>{" "}
          </div>
        )}
        {FACILITY_LIST_DRAWER.includes(window.location.pathname) &&
          FACILITY_VIEW_ROLE && (
            <Drawer
              style={headerDisable ? { pointerEvents: "none" } : {}}
              variant="permanent"
              className={clsx(classes.Sliderdrawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              })}
              classes={{
                paper: clsx(classes.ScrollDrawer, {
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                }),
              }}
            >
              <div className={classes.root}>
                <div className={""}>
                  <div className={classes.baseContainer}>
                    <img
                      src={logo}
                      alt="star-health"
                      className={classes.logo}
                    ></img>
                  </div>
                </div>
              </div>
            </Drawer>
          )}
        <main
          className={
            hideToolbar
              ? clsx(
                  window.location.pathname !== "/createfacility"
                    ? classes.content
                    : classes.contentWhite,
                  classes.listedContent
                )
              : window.location.pathname !== "/createfacility"
              ? classes.content
              : classes.contentWhite
          }
          style={{
            padding:
              headerDisable  && !window.location.pathname.includes('/chat-consulation') || hideToolbar ? "0px" : "80px 24px 24px 24px",
          }}
        >
          <div className={classes.mainRoot} />
          <div className={hideToolbar ? null : classes.toolbar} />
          {ReconnectConsult.show && (
            <div className={classes.messagePendingWrapper}>
              <div className={classes.messagePendingContainer}>
                <div style={{ display: "flex" }}>
                  <div className={classes.notifyPendingIcon}>
                    <img src={ic_notify} alt="notify icon" />
                  </div>
                  <div className={classes.facilityContent}>
                    Your consultation is still in progress, you can join now!
                  </div>
                </div>
                <CustomSubmitButton
                  className={clsx(classes.ViewForm)}
                  id="rejoinButton"
                  bookedSlotId={instantdata?.bookedId}
                  onClick={() => {
                    if (ReconnectConsult.path.includes("/InstantCall/room") || ReconnectConsult.path.includes("/Appointments/room")) {
                      const stateValue = instantdata?.isCallBack ? { row: { row: { _id: instantdata?.bookedId } }, consultType : instantdata?.consulationType  } : { row: { row: { _id: instantdata?.bookedId } } }
                      dispatch(UpdateInstantType(''))
                      dispatch(UpdateCallInfo(true));
                      setReconnectConsult({ show: false, path: "" });
                      setinstantResponse({});
                      history.push({
                        pathname: ReconnectConsult.path ?? "/InstantCall/room",
                        search: `${instantdata?.roomId}`,
                        state: stateValue,
                      });
                    } else if (
                      ReconnectConsult.path.includes("/chat-consulation")
                    ) {
                      dispatch(OnchatBusy(true));
                      setReconnectConsult({ show: false, path: "" });
                      setinstantResponse({});
                      const roomID = new URLSearchParams(location.search).get("roomID")   
                      const {name ,roomId,userId,chatLogId,chatbookedID,user,bookedId : BookedId} = chatdetails ;
                      roomID !== roomId && dispatch(EmptyMessagelist());
                      sendMsg('join',{ name, room:roomId, userId : userId || user, "type": "web",  chatLogId,bookSlotId: BookedId});
                      history.push({
                        pathname: "/chat-consulation",
                        search: `?roomID=${chatdetails?.roomId}`,
                        state: { row: { row: { _id: chatdetails?.bookedId } } },
                      });
                    }
                    
                  }}
                >
                  Re-join
                </CustomSubmitButton>
              </div>
            </div>
          )}
          {children}
        </main>
      </div>
      <SessionLogout />
      <CustomDialog
        title={"Logged Out"}
        open={isLogin}
        onClose={() => history.push("/logout")}
        size={"xs"}
        autoWidth={undefined}
        classes={{ paperFullWidth: classes.customDialogReject }}
        enableFooter={false}
        className={classes.CustomModel}
        CloseIcon={<CloseIcon />}
        style={{zIndex:3000}}
      >
        <Box>
          <Grid sm={12} className={classes.gridSpace}>
            <Typography variant="body1" component="p">
             {isMultiUser === "multi" ? "You have been logged out from the browser because we detected you tried to login in another browser. If it was not you then please change your password." : "You have multiple active sessions. We are logging out all your sessions. Kindly make sure you have only one session."}
            </Typography>
          </Grid>
          <Grid
            item
            sm={12}
            className={clsx(classes.RightAlign, classes.fullWidthButton)}
          >
            <CustomSubmitButton type="button" onClick={() => history.push("/logout")}>
              Back to Login
            </CustomSubmitButton>
          </Grid>
        </Box>
      </CustomDialog>
      <CustomDialog
        title={"Active Session"}
        open={duplicateTab}
        size={"xs"}
        autoWidth={undefined}
        classes={{ paperFullWidth: classes.customDialogReject }}
        enableFooter={false}
        CloseIcon={undefined}
        className={classes.CustomModelTab}
        style={{zIndex:3000}}
      >
        <Box>
          <Grid sm={12} className={classes.gridSpace}>
            <Typography variant="body1" component="p">
             {"Your ongoing consultation is active on another tab, so you will be navigated to dashboard."}
            </Typography>
          </Grid>
          <Grid
            item
            sm={12}
            className={clsx(classes.RightAlign, classes.fullWidthButton)}
          >
            <CustomSubmitButton type="button" onClick={() => {setDuplicateTab(false); sessionStorage.removeItem("currentTabDuplicate"); history.push("/Dashboard"); }}>
              Go To Dashboard
            </CustomSubmitButton>
          </Grid>
        </Box>
      </CustomDialog>
      {/* -----Notify User about Patient Waiting--------- */}
      {snackComponent}
    </div>
  );
}
