import { useEffect, useState,useRef } from "react";
import { useForm, Controller, ControllerRenderProps } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { validations } from "../../../../config/validations.config";
import useStyles from "./styles";
import clsx from "clsx";
import { Button, Chip, CircularProgress, Grid, Radio, RadioGroup, Typography } from "@material-ui/core";
import CommonTextField from "../../../../components/common/CommonTextField";
import CustomFormEM from "../../../common/CustomFormEM";
import { autoUpdateCaseSheet,getChiefComplainList, getLabInvestigation, getSymptomsList } from "../../../../api/consultation/consultation";
import { IcaseSheet, IMasterDataRequiredValue } from "../../../../models/consultation";
import { RootStateOrAny, useSelector } from "react-redux";
import { USER_ROLES } from "../../../../utils/constants";
import CommonDropDown from "../../../common/CommonDropDown";
import CommonMenuItem from "../../../common/CommonDropDown/CommonMenuItem";
import CommonDatePicker from "../../../common/CommonDatePicker";
import moment from "moment";
import { useQuery } from "react-query";
import { getSlotList } from "../../../../api/AppointmentManagement/Appointment";
import { setfollowUp, followUp } from "../../../../pages/AppointmentManagement/Consultation/FollowUp";
import Notify from "../../../Notify";
import { getTotalBookedAppointment } from "../../../../api/dashboardDetails";
import { Autocomplete , ListItemButton, ListItemText, TextField, ListItem, Checkbox, ListItemIcon} from "@mui/material";
import {customizeStyle} from '../../styles';
let debounceTimer: NodeJS.Timeout | null = null;
type AutoSaveType = "onchange" | "onblur";
interface IPagination
{
  search : string,
  pageNumber : number,
  pageSize : number,
  sortBy: string,
  orderBy: string,
  masterDataKey? : string
}

const CaseSheet = ({
  investigationsArray = [],
  caseSheetId = "",
  symptomsArr,
  data,
  patient
}: IcaseSheet) => {
  const [investigationArr, setinvestigationArr] = useState<any>([])
  const classes = useStyles();
  const customizeClass = customizeStyle();

  const [timeSlotList, setTimeSlotList] = useState<any>([]);
  const complainRef = useRef<string | null>(null);
  const symptomsRef = useRef<string | null>(null);
  const symptomsSearch  = useRef<IPagination>({search : '',pageNumber:1,pageSize:20,sortBy:'',orderBy:'',masterDataKey:'symptoms'});
  const complainSearch  = useRef<IPagination>({search : '',pageNumber:1,pageSize:20,sortBy:'',orderBy:'',masterDataKey:'complaints'});
  const invegtigationSearch  = useRef<IPagination>({search : '',pageNumber:1,pageSize:1000,sortBy:'',orderBy:''});
  const [symptomsMasterdata,setsymptomsMasterdata] = useState<Array<IMasterDataRequiredValue>>([]);
  const [complaintMasterdata,setcomplaintMasterdata] = useState<Array<IMasterDataRequiredValue>>([]);
  const { userRole, user } = useSelector((state: RootStateOrAny) => state?.auth);
  const { data: investigationRes , refetch : _investigationRefetch , isLoading : _investigationLoading } = useQuery(["Lab_INVESTIGATION",{...invegtigationSearch.current}], getLabInvestigation);
  useEffect(() => {
    return () => {
      if (debounceTimer) clearTimeout(debounceTimer);
    };
  }, []);

  const schema = Yup.object().shape({
    complaints: validations.commonRequired,
    diagnosis: validations.commonRequired,
    notes: validations.commonRequired,
    investigation: validations.commonRequired,
    symptoms: validations.commonRequired,
  });
  const [request, setRequest] = useState({
    loading: false,
    message: "",
    severity: "",
  });

  const {
    control,
    errors,
    getValues,
    watch,
    setValue
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      complaints: Array<IMasterDataRequiredValue>(),
      diagnosis: "",
      symptoms: Array<IMasterDataRequiredValue>(),
      investigation: Array<any>(),
      notes: "",
      followUpDate: '',
      FollowUp: '',
    },
  });
  const { details } = useSelector((state: RootStateOrAny) => state.clinic);
  const { followUpDate, FollowUp } = getValues();
  const [stateRefresh,setstateRefresh]=useState<boolean>(false);
  const { data: timeSlots, isFetching: timeSlotFetching } = useQuery(
    ["getSlotList",
      {
        facilityId: details?.id,
        practitionerId: user?._id,
        primaryUserId: patient?._id,
        roleId: 'UNIFIED_APP_DOCTOR',
        consultType: 'VIRTUAL_CONSULTATION',
        date: moment(followUpDate).format("YYYY/MM/DD"),
        "isTelemedicine": true,
      }
    ],
    getSlotList,
    { enabled: FollowUp === 'YES' && followUpDate && user }
  );

  useEffect(() => {
    if (data) {
      if (data?.symptoms?.length > 0) {
        let symptomsVal = data?.symptoms?.reduce((accumulator,initial : any) => {
          let value  : IMasterDataRequiredValue = {
            _id: initial._id,
            masterDataValue: initial.text
          }
          return accumulator.concat(value);
        },[])
        control.setValue("symptoms", symptomsVal)
      }

      if (investigationArr.length > 0 && data?.investigation?.length > 0) {
        let investigationVal = investigationArr?.filter((element) => data.investigation.includes(element?._id))
        control.setValue("investigation", investigationVal)
      }

      if (data?.complaints?.length > 0) {
        let complaintsVal = data?.complaints?.reduce((accumulator,initial : any) => {
          let value  : IMasterDataRequiredValue = {
            _id: initial._id,
            masterDataValue: initial.text
          }
          return accumulator.concat(value);
        },[])
        control.setValue("complaints", complaintsVal)
      }
      control.setValue("diagnosis", data?.diagnosis)
      control.setValue("notes", data?.notes)
    }
  }, [data,investigationArr]);

  watch(["complaints", "diagnosis", "symptoms", "investigation", "notes", "followUpDate", "FollowUp"]);

  const { data: complainListData, refetch: _fetchRefetch, isLoading: complainLoading } = useQuery(
    [
      "GET_ALL_COMPLAIN_LIST", {...complainSearch.current}
    ],
    getChiefComplainList,
    { enabled: patient?._id, retry: 0 }
  );

  const { data: symptomsListData, refetch: _symptomRefetch, isLoading: symptomLoading } = useQuery(
    [
      "GET_ALL_SYMPTOMS_LIST", {...symptomsSearch.current}
    ],
    getSymptomsList,
    { enabled: patient?._id, retry: 0 }
  );

  useEffect(() => {
    if(complainListData?.data && Array.isArray(complainListData?.data))
    {
        if(complainSearch?.current.pageNumber === 1)
        setcomplaintMasterdata(complainListData?.data);
        else
        setcomplaintMasterdata([...complaintMasterdata,...complainListData?.data]);
    }
  },[complainListData])

useEffect(() => {
  if(symptomsListData?.data && Array.isArray(symptomsListData?.data))
  {
      if(symptomsSearch?.current.pageNumber === 1)
      setsymptomsMasterdata(symptomsListData?.data);
      else
      setsymptomsMasterdata([...symptomsMasterdata,...symptomsListData?.data]);
  }
},[symptomsListData])

  const autoSave = async (actionType: AutoSaveType) => {
    if (debounceTimer) clearTimeout(debounceTimer);
    debounceTimer = setTimeout(
      () => {
        debounceTimer = null;
        if (caseSheetId) autoUpdateCaseSheet(caseSheetId, getValuePayload());
      },
      actionType === "onblur" ? 0 : 3000
    );
  };

  const getcountAppoinment = async () => {
    try {
      const response: any = await getTotalBookedAppointment(data.user._id);
      !response?.data.isAllowed &&
        setRequest({
          loading: false,
          message: response?.data?.message || 'Something went wrong',
          severity: response?.data?.severity,
        });
      return response?.data.isAllowed;
    } catch (err) {
      setRequest({
        loading: false,
        message: err?.response?.data?.message || 'Something went wrong',
        severity: err?.response?.data?.severity,
      });
      return false
    }
    
  }

  useEffect(() => {
    if(Array.isArray(investigationRes?.data))
    {
      const customizeArray = investigationRes?.data.reduce((accumulator,initial) => {
        initial.testName = initial?.testName && initial?.testName[0];
        return accumulator.concat(initial);
      },[]) ;
      setinvestigationArr(customizeArray);
    }
  }, [investigationRes]);

const payloadCustomize = (value : Array<IMasterDataRequiredValue>) =>
value?.reduce((accumulator,initial : any) => {
  let value =  initial ;
  if(initial?._id === initial?.masterDataValue)
  value = {text : initial?.masterDataValue} ;
  else
  value = {_id : initial?._id,text : initial?.masterDataValue}
  return accumulator.concat(value)      
},[])

const getValuePayload = () => {
    const value = getValues()
    let payload = {
      symptoms: payloadCustomize(value?.symptoms),
      diagnosis: value.diagnosis,
      complaints: payloadCustomize(value?.complaints),
      notes: value.notes,
      investigation: value.investigation.map((val: any) => { return val.id }),
    }
    return payload
  }

  useEffect(() => {
    if (timeSlots) {
      const { Morning, AfterNoon, Evening, Night } = timeSlots || {}
      let slotMap: any = [...Morning, ...AfterNoon, ...Evening, ...Night];

      const timeConvertion = slotMap?.map((x: any) => ({
        slotId: x?.slotId,
        booked: x?.isBooked,
        isExpired: x?.isExpired,
        time: moment(x?.time, "HH:mm").format("hh:mm a")
      }))
      setTimeSlotList(timeConvertion)
    }
  }, [timeSlots]);

  return (
    <>
      {request.message && (
        <Notify message={request.message} severity={request.severity} />
      )}
      <form className={classes.container}>
        <div className={classes.headerContainer}>
          <Typography className={classes.caseSheet}>Case Sheet</Typography>
        </div>
        <div className={classes.inputsContainer}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name="complaints"
                control={control}
                render={(
                  controlProps: ControllerRenderProps<Record<string, any>>
                ) => (
                  <Autocomplete
                  multiple
                  loading ={complainLoading}
                  key={'chief-complaints'}
                  noOptionsText = {<Button style={complainSearch.current.search ? {color : 'blue'}: {}}onClick={() => 
                  {

                    const value = [...controlProps.value ?? []]?.find((element : any) => element?._id?.toLowerCase() === complainRef.current?.trim()?.toLowerCase()) ;
                    if(!value && complainRef.current?.trim())
                    {
                      let controlValue = controlProps.value ?? [] ;
                      controlProps.onChange([...controlValue ?? [],...[{_id : complainRef.current.trim() , masterDataValue : complainRef.current.trim()}]]);
                      autoSave("onchange");
                    }
                  }
                  }>+Add New</Button>}
                  id="chief-complaints-select"
                  options={complaintMasterdata}
                  value={controlProps.value}
                  renderTags={(tagValue, getTagProps) => {
                    return tagValue.map((option : IMasterDataRequiredValue, index) => (
                      <Chip {...getTagProps({ index })} label={option?.masterDataValue} className={classes.blueContainer} style={{maxWidth :'320px'}}/>
                    ));
                  }}
                  ListboxProps={{
                    style: { maxHeight: "310px" },
                    onScroll: (event: { currentTarget: any }) => {
                      const listboxNode = event.currentTarget;
                      if ( (listboxNode.scrollHeight - listboxNode.scrollTop - listboxNode.clientHeight < 1) &&  complainListData?.hasNextPage) {
                        complainSearch.current.pageNumber = complainSearch.current.pageNumber + 1 ;
                      }
                    },
                  }}
                //   filterOptions={(options, params) => {
                //     let filtered = options.filter((ele) => {
                //       return (
                //         !Boolean(controlProps?.value?.find((userData) => userData?._id === ele?._id))
                //       );
                //     });
                //     const { inputValue } = params;
                //     if (inputValue && inputValue !== "" && inputValue.trim()) {
                //       let array: Array<IMAsterDataValue> = [];
                //       filtered.map((value) => {
                //         if (value?.masterDataValue?.toLowerCase()?.includes(inputValue.trim().toLowerCase())) {
                //           array.push(value);
                //         }
                //       });
                //       filtered = array;
                //     }
                //     return filtered;
                // }}
                renderOption={(props,option,_state) => 
                  <ListItem style={{margin : 0 ,padding : 0 , width : '100%'}} {...props}>
                    <ListItemButton alignItems = {'flex-start'} style={([...controlProps?.value ?? []]?.find((element) => element?._id === option?._id)) ? {background : '#cfdbfa78' , padding : '0.5rem', width : '100%'} : {padding : '0.5rem', width : '100%'}}>
                    <ListItemText primary={option?.masterDataValue}/>
                    </ListItemButton>
                  </ListItem>}
                  
                  onChange={(_event,value) => 
                    {
                      if(value?.length > 0)
                      {
                        const updateValue = value[value.length - 1];
                        const repeated = value.filter((check) => check?._id === updateValue._id)
                        if(repeated.length === 1)
                        controlProps.onChange(value)
                        else
                        {
                          const index = value?.findIndex((element) => element?._id === repeated[0]?._id);
                          value.splice(index,1);
                          value.splice(value.length - 1,1);
                          controlProps.onChange(value);
                        }
                      }
                      else
                      controlProps.onChange(value)
                      autoSave("onchange");
                    }
                  }
                  onBlur={() => autoSave("onblur")}
                  getOptionLabel={(option : any) => option?.masterDataValue}
                  style={{ width: '100%' }}
                  onInputChange={(_event ,value) => (complainRef.current = value,complainSearch.current.search = value , complainSearch.current.pageNumber = 1,setstateRefresh(!stateRefresh))}
                  renderInput={(params) => (
                    <TextField {...params} label={'Chief Complaints'} className={customizeClass.root}/>
                  )}
                />
                )}
              />
              <CustomFormEM
                errors={errors}
                name="complaints"
                render={({ message }) => <p>{message}</p>}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="symptoms"
                control={control}
                render={(
                  controlProps: ControllerRenderProps<Record<string, any>>
                ) => (
                  <Autocomplete
                  multiple
                  loading ={symptomLoading}
                  key={'symptoms'}
                  noOptionsText = {<Button style={symptomsSearch.current.search ? {color : 'blue'}: {}}onClick={() => 
                  {

                    const value = [...controlProps.value ?? []]?.find((element : any) => element?._id === symptomsRef.current?.trim()) ;
                    if(!value && symptomsRef.current?.trim())
                    {
                      let controlValue = controlProps.value ?? [] ;
                      controlProps.onChange([...controlValue ?? [],...[{_id : symptomsRef.current.trim() , masterDataValue : symptomsRef.current.trim()}]]);
                      autoSave("onchange");
                    }
                  }
                  }>+Add New</Button>}
                  id="symptom-select"
                  options={symptomsMasterdata}
                //   filterOptions={(options, params) => {
                //     let filtered = options.filter((ele) => {
                //       return (
                //         !Boolean(controlProps?.value?.find((userData) => userData?._id === ele?._id))
                //       );
                //     });
                //     const { inputValue } = params;
                //     if (inputValue && inputValue !== "" && inputValue.trim()) {
                //       let array: Array<IMAsterDataValue> = [];
                //       filtered.map((value) => {
                //         if (value?.masterDataValue?.toLowerCase()?.includes(inputValue.trim().toLowerCase())) {
                //           array.push(value);
                //         }
                //       });
                //       filtered = array;
                //     }
                //     return filtered;
                // }}
                  value={controlProps.value}
                  renderTags={(tagValue, getTagProps) => {
                    return tagValue.map((option : IMasterDataRequiredValue, index) => (
                      <Chip {...getTagProps({ index })} label={option?.masterDataValue} className={classes.blueContainer} style={{maxWidth :'320px'}}/>
                    ));
                  }}
                  ListboxProps={{
                    style: { maxHeight: "300px" },
                    onScroll: (event: { currentTarget: any }) => {
                      const listboxNode = event.currentTarget;
                      if ( (listboxNode.scrollHeight - listboxNode.scrollTop - listboxNode.clientHeight < 1) &&  symptomsListData?.hasNextPage) {
                        symptomsSearch.current.pageNumber = symptomsSearch.current.pageNumber + 1 ;
                      }
                    },
                  }}
                  renderOption={(props,option,_state) => 
                    <ListItem style={{margin : 0 ,padding : 0 , width : '100%'}} {...props}>
                      <ListItemButton alignItems = {'flex-start'} style={([...controlProps?.value ?? []]?.find((element) => element?._id === option?._id)) ? {background : '#cfdbfa78' , padding : '0.5rem' ,width : '100%'} : {padding : '0.5rem',width:'100%'}}>
                      <ListItemText primary={option?.masterDataValue}/>
                      </ListItemButton>
                    </ListItem>}
                    
                  onChange={(_event,value) => 
                    {
                      if(value?.length > 0)
                      {
                        const updateValue = value[value.length - 1];
                        const repeated = value.filter((check) => check?._id === updateValue._id)
                        if(repeated.length === 1)
                        controlProps.onChange(value)
                        else
                        {
                          const index = value?.findIndex((element) => element?._id === repeated[0]?._id);
                          value.splice(index,1);
                          value.splice(value.length - 1,1);
                          controlProps.onChange(value);
                        }
                      }
                      else
                      controlProps.onChange(value)
                      autoSave("onchange");
                    }
                  }
                  onBlur={() => autoSave("onblur")}
                  getOptionLabel={(option : any) => option?.masterDataValue}
                  style={{ width: '100%' }}
                  onInputChange={(_event ,value) => (symptomsRef.current = value,symptomsSearch.current.search = value , symptomsSearch.current.pageNumber = 1,setstateRefresh(!stateRefresh))}
                  renderInput={(params) => (
                    <TextField {...params} label={'Symptoms'} className={customizeClass.root}/>
                  )}
                />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="diagnosis"
                control={control}
                render={(
                  controlProps: ControllerRenderProps<Record<string, any>>
                ) => (
                  <CommonTextField
                    className={clsx(classes.root)}
                    multiline
                    rows={3}
                    type="text"
                    label="Diagnosis"
                    placeholder="Eg. Fever, Headache"
                    value={controlProps.value}
                    disabled={userRole && (userRole === USER_ROLES.receptionist || userRole === USER_ROLES.opd_ops ||
                      userRole === USER_ROLES.facility_receptionist)}
                    onChange={(e: { target: { value: string } }) => {
                      controlProps.onChange(e.target.value);
                      autoSave("onchange");
                    }}
                    onBlur={() => autoSave("onblur")}
                  />
                )}
              />
              <CustomFormEM
                errors={errors}
                name="diagnosis"
                render={({ message }) => <p>{message}</p>}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="notes"
                control={control}
                render={(
                  controlProps: ControllerRenderProps<Record<string, any>>
                ) => (
                  <CommonTextField
                    className={clsx(classes.root)}
                    multiline
                    rows={3}
                    type="text"
                    label="Clinical Notes"
                    placeholder="Enter your notes here..."
                    value={controlProps.value}
                    disabled={userRole && (userRole === USER_ROLES.receptionist || userRole === USER_ROLES.opd_ops ||
                      userRole === USER_ROLES.facility_receptionist)}
                    onChange={(e: { target: { value: string } }) => {
                      controlProps.onChange(e.target.value);
                      autoSave("onchange");
                    }}
                    onBlur={() => autoSave("onblur")}
                  />
                )}
              />
              <CustomFormEM
                errors={errors}
                name="notes"
                render={({ message }) => <p>{message}</p>}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="investigation"
                control={control}
                render={(
                  controlProps: ControllerRenderProps<Record<string, any>>
                ) => (
                  <Autocomplete
                  id="lab-investigation"
                  options={investigationArr}
                  multiple
                  classes={{paper:classes.headerTile,clearIndicator:classes.clearPadding,popupIndicator:classes.clearPadding}}
                  value ={controlProps?.value}
                  disableCloseOnSelect
                  groupBy={(option : any) => option.parentName}
                  getOptionLabel={(option) => option.testName.value}
                  renderOption={(props, option, { selected }) =>{ 
                  return(
                      <ListItem {...props} className={classes.checkPadding} key={props?.id}>
                        <ListItemIcon className={classes.checkedIcon}>
                          <Checkbox
                            className={classes.checkedColor}
                            checked={selected}
                          />
                        </ListItemIcon>
                        <ListItemText>{option.testName.value}</ListItemText>
                      </ListItem>
                  )}}
                  renderGroup={(params:any)=>{
                    return (
                      <ListItem className={classes.GroupsList} key={params?.key}>
                        <ListItemText className={classes.groupHeaderSticky}>{params.group}</ListItemText>
                        <ListItemText className={classes.groupSubHeader}>{investigationArr[params.key].groupName}</ListItemText>
                          {params.children}
                          <hr className={classes.hr} />
                      </ListItem>
                    )
                  }}
                  onChange={(_event,value) => 
                    {
                      if(value?.length > 0)
                      {
                        const updateValue = value[value.length - 1];
                        const repeated = value.filter((check) => check?._id === updateValue._id)
                        repeated.length === 1  && controlProps.onChange(value)
                      }
                      else
                      controlProps.onChange(value)
                      autoSave("onchange");
                    }
                  }
                  renderTags={(tagValue, getTagProps) => {
                    return tagValue.map((option : any, index) => (
                      <Chip {...getTagProps({ index })} label={option?.testName?.value} className={classes.blueContainer} style={{maxWidth :'280px'}}/>
                    ));
                  }}
                  renderInput={(params) => <TextField {...params} label="Investigations Recommended" className={customizeClass.root}/>}
                />
                )}
              />
              <CustomFormEM
                errors={errors}
                name="investigation"
                render={({ message }) => <p>{message}</p>}
              />
            </Grid>
            {data?.consultationType !== "CHAT" && data?.appointmentType !== 'FOLLOW_UP' &&
              <Grid item xs={12}>
                <Controller
                  name="FollowUp"
                  control={control}
                  render={(
                    controlProps: ControllerRenderProps<Record<string, any>>
                  ) => (
                    <CommonDropDown
                      multiple={false}
                      //variant="filled"
                      className={classes.Consult}
                      onChange={async (e: any) => {
                        setRequest({
                          loading: false,
                          message: '',
                          severity: '',

                        });
                        if(e.target.value === 'YES'){
                          const _t = await getcountAppoinment();
                          controlProps.onChange(e.target.value);
                          if (!_t || e.target.value === 'NO') {
                            setfollowUp && setfollowUp({ ...followUp, ...{ date: "", slot: "", slotStartTime: "" } })
                            setValue('followUpDate', undefined);
                            setValue('followUpTime', undefined);
                            setValue('FollowUp', "NO");
                            setTimeSlotList([]);
                          }
                          else {
                            setfollowUp && setfollowUp({ ...followUp, isFollowUp: e.target.value === 'YES' ? true : false })
                          }

                        }else{
                          setValue('FollowUp', "NO");
                        }
                       
                      }}
                      value={controlProps.value}
                      label={'Consultation Need Followup?'}
                    >
                      <CommonMenuItem value={'YES'}>Yes</CommonMenuItem>
                      <CommonMenuItem value={'NO'}>No</CommonMenuItem>
                    </CommonDropDown>
                  )}
                />
                <CustomFormEM
                  errors={errors}
                  name="FollowUp"
                  render={({ message }) => <p>{message}</p>}
                />
              </Grid>
            }
            {FollowUp === 'YES' && <Grid item xs={12}>
              <Controller
                name="followUpDate"
                control={control}
                render={(controlProps) => {
                  return (
                    <CommonDatePicker
                      label={"Select Date for Followup"}
                      format="DD/MM/YYYY"
                      inputProps={{ readOnly: true }}
                      placeholder="Select Date"
                      className={classes.dates}
                      value={controlProps.value || null}
                      disablePast
                      maxDate={moment(moment(new Date()).add(30, 'd').format('YYYY/MM/DD HH:mm:ss'))}
                      onChange={(e: any) => {
                        controlProps.onChange(e);
                        setValue('followUpTime', undefined);
                        setfollowUp && setfollowUp({ ...followUp, date: moment(e).format('YYYY/MM/DD') })
                      }}
                    />
                  );
                }}
              />
            </Grid>}
            {FollowUp === 'YES' && followUpDate && followUpDate !== '' && <Grid item container xs={12} className={classes.availableTime}>
              <Typography className={classes.title}>{`Available Slots on ${moment(followUpDate).format('DD/MM/YYYY')}`}</Typography>
              <Grid item container xs={12}>
                <Controller
                  name={"followUpTime"}
                  control={control}
                  render={(controlProps) => {
                    return (
                      <RadioGroup
                        key={'Followup+Time'}
                        row
                        name="followUpTime"
                        className={classes.IconHide}
                        onChange={(e) => {
                          controlProps.onChange(e.target.value);
                          const splitValue = moment(e.target.value, "hh:mm a").format("HH:mm").split(":")
                          const { Morning, AfterNoon, Evening, Night } = timeSlots || {}
                          let slotMap: any = [...Morning, ...AfterNoon, ...Evening, ...Night];
                          const slotTime = slotMap?.map((time: any) => (time?.time))
                          Object.entries(slotTime).forEach(([key, value]: any, index: any) => {
                            if (slotTime.indexOf(moment(e.target.value, "hh:mm a").format("HH:mm")) !== -1) {
                              const selectedTime = slotMap[slotTime.indexOf(moment(e.target.value, "hh:mm a").format("HH:mm"))]
                              setfollowUp && setfollowUp({ ...followUp, ...{ slot: selectedTime?.slotId, slotStartTime: (+splitValue[0] * (60000 * 60)) + (+splitValue[1] * 60000) } })
                            }
                          })
                        }}
                      >
                        {!timeSlotList && timeSlotFetching && <CircularProgress />}
                        {timeSlotList &&
                          !timeSlotFetching &&
                          timeSlotList?.map((key: any) => {
                            return (
                              <>
                                <Radio className={classes.IconHide} value={key?.time} id={key?.time + 'followUp'} disabled={key?.isExpired || key?.booked} icon={<></>} />
                                <label
                                  htmlFor={key?.time + 'followUp'}
                                  className={key?.isExpired || key?.booked ? clsx(classes.FollowupTime, classes.TimeHidden) : clsx(classes.FollowupTime, controlProps.value === key?.time ? "active" : "")}
                                >
                                  <Typography variant="body2">{key?.time}</Typography>
                                </label>
                              </>
                            );
                          })}
                      </RadioGroup>
                    );
                  }}
                />
                {timeSlotList?.filter((x: any) => x?.booked === false).length === 0 &&
                  <Typography className={classes.slotNotAvailable}>No slots available</Typography>
                }
              </Grid>
            </Grid>}
          </Grid>
        </div>
      </form>
    </>
  );
};

export default CaseSheet;
