import { PropsWithChildren, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import enhanceMessage from "./enhanceMessage";
import { TwilioError } from "twilio-video";
import { IconButton } from "@mui/material";
import CustomSubmitButton from "../../common/CustomSubmitButton";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { USER_ROLES } from "../../../utils/constants";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { sendMsg } from "../../Websocket/InstantChatIO";
import { UpdateErrorCode } from "../../../store/actions";
interface ErrorDialogProps {
  dismissError: Function;
  error: TwilioError | Error | null;
  isVideoCollapsed?: any;
  check?: any;
  roomState: any;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Retrybutton: {
      background: "#32529D !important",
      color: "#FFFFFF !important",
      border: "1px solid #32529D !important",
      "&:hover": {
        background: "#FFFFFF !important",
        color: "#32529D !important",
        border: "1px solid #FFFFFF !important",
      },
    },
    Cancelbutton: {
      background: "#8A8F9B !important",
      color: "#FFFFFF !important",
      border: "1px solid #8A8F9B !important",
      "&:hover": {
        background: "#FFFFFF !important",
        color: "#8A8F9B !important",
        border: "1px solid #8A8F9B !important",
      },
    },
    DisconnectedConatiner: {
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
      background: "#091021",
      textAlign: "center",
    },
    WifoSymbol: {
      height: "120px",
      width: "181px",
      borderRadius: "2rem",
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
      margin: "0% 50%",
    },
    Text1: {
      marginTop: "2%",
      color: "#FFFFFF",
      fontSize: "22px",
      fontWeight: 500,
      lineHeight: "24px",
      background: "transparent",
    },
    Text2: {
      color: "#FFFFFF",
      marginTop: "1%",
    },
    buttonWrapper: {
      display: "flex",
      flexDirection: "row",
      gap: "20px",
      margin: "2% 50%",
    },
    cancelBtn: {
      backgroundColor: "#B7BDCE !important",
      padding: "8.5px 25px",
      color: "#FFFFFF",
      borderRadius: "4px",
      lineHeight: "26px",
      fontSize: "14px",
      fontWeight: 600,
      textTransform: "none",
    },
    titleRoot: {
      borderBottom: "1px solid #CCCCCC",
      "& .close-btn": {
        display: "inline-block",
        width: "fit-content",
        position: "absolute",
        top: "12px",
        right: "16px",
      },
    },
  })
);

let twillioErrorMessage:string=''

function ErrorDialog({
  dismissError,
  error,
  isVideoCollapsed,
  check,
  roomState,
}: PropsWithChildren<ErrorDialogProps>) {
  const { message, code } = error || {};
  const history = useHistory();
  const enhancedMessage = enhanceMessage(message, code);
  const classes = useStyles();
  const { user, userRole } = useSelector(
    (state: RootStateOrAny) => state?.auth
  );
  const { instantdata } = useSelector((state: any) => state?.instantVideo);
  const { dbData } = useSelector((state: RootStateOrAny) => state?.auth);
  const dispatch = useDispatch();

  const checkHasError = () => {
    let hasCode : boolean = false;
    JSON.parse(dbData?.TWILLO_ERROR_CODE_VALIDATION_MESSAGE).map((data:object)=>{
      if(Object.keys(data).includes(JSON.stringify(code)) && !hasCode){
        hasCode = true
        twillioErrorMessage=Object?.values(data)[0];
      }
    })
    return hasCode
  }

  useEffect(() => {
    if ((code === 53118 || code === 53106) && !isVideoCollapsed) {
      dismissError();
      check();
    }

    if(checkHasError()){
      dispatch(UpdateErrorCode(JSON.stringify(code)));
    }
  }, [code]);

  const permissionDeniedUI = () => {
    return (
      <Dialog open={error !== null} fullWidth={true} maxWidth="xs">
        <DialogTitle className={classes.titleRoot} id="twillio-error">
          {code === -100001 ? "Alert" : "Permission denied"}
          {code !== -100001 && (
            <IconButton
              className="close-btn"
              onClick={() => {
                dismissError();
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span style={{ fontWeight: 500, color: "#000000" }}>
              {code === -100001 ? (
                <div style={{ paddingTop: "8px" }}>{message}</div>
              ) : (
                <>
                  We need access to your to microphone and camera to continue.
                  <br />
                  <ol style={{ lineHeight: "1.1" }}>
                    <li>Click camera icon in your browser's address bar</li>
                    <br />
                    <li>Select "Always allow",then click done</li>
                  </ol>
                </>
              )}
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: "16px" }}>
          <Button
            onClick={() => {
              dismissError();
              if (location.pathname.includes("/InstantCall/room")) {
                if (roomState.current === "disconnected") {
                  if (
                    location.pathname === "/InstantCall/room" &&
                    userRole === USER_ROLES.doctor &&
                    instantdata?.type !== "RECONNECT" && sessionStorage.getItem("new-consulation")
                  ) {
                    sendMsg("doctorReject", {
                      callId: instantdata?.callId,
                      role: userRole,
                    });
                  } else if (
                    location.pathname === "/InstantCall/room" &&
                    userRole === USER_ROLES.receptionist &&
                    instantdata?.type !== "RECONNECT" && sessionStorage.getItem("new-consulation")
                  ) {
                    sendMsg("receptionistReject", {
                      callId: instantdata?.callId,
                      userId: user,
                      role: userRole,
                    });
                  }
                }
                history.push("/Dashboard");
              } else {
                history.push("/Appointments");
              }
              //history.go(0)
            }}
            className={classes.cancelBtn}
          >
            Close
          </Button>
          {code !== -100001 && <CustomSubmitButton
            className={undefined}
            isLoading={false}
            onClick={() => {
              dismissError();
              location.reload();
            }}
          >
            Try Again
          </CustomSubmitButton>}
        </DialogActions>
      </Dialog>
    );
  };

  const LowNetworkUI = () => {
    return (
      <Dialog open={error !== null} fullWidth={true} maxWidth="xs">
        <DialogTitle className={classes.titleRoot}>Low Network</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span style={{ fontWeight: 500, color: "#000000" }}>
              <div style={{ paddingTop: "8px" }}>{twillioErrorMessage}</div>
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: "16px" }}>
          <CustomSubmitButton
            className={undefined}
            isLoading={false}
            onClick={() => {
              dismissError();
              history.push("/Dashboard");
            }}
          >
            Go To Dashboard
          </CustomSubmitButton>
        </DialogActions>
      </Dialog>
    );
  };

  const renderDeafultError = () => {
    return (
      <Dialog
        open={error !== null}
        onClose={() => dismissError()}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle>Alert</DialogTitle>
        <DialogContent>
          <DialogContentText>{enhancedMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
              className={classes.cancelBtn} 
              onClick={() => {
                dismissError()
                if (location.pathname.includes("/InstantCall/room")){
                  history.push("/Dashboard");
                } else {
                  history.push("/Appointments");
                }
              }} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const renderError = () => {
    if((code === 0 && message === "Permission denied") || code === -100001){
      return permissionDeniedUI()
    } else if (checkHasError()){
      return LowNetworkUI()
    } else if (code &&
      code !== 0 &&
      code !== -100001 &&
      code !== 53118 &&
      code !== 53106 &&
      code !== 53205 &&
      message !== "Permission denied"){
        return renderDeafultError()
      }
  }

  return (
    <>{renderError()}</>
  );
}

export default ErrorDialog;
