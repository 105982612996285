import { Grid, Typography } from "@material-ui/core"
import styles from "./styles"
import Consultation from "./Consultation"
import Followup from "./Followup"
import CommonTextField from "../../../components/common/CommonTextField"
import AppointmentTimeline from "./Timeline"
import { useEffect, ChangeEvent } from "react";
import { useForm, Controller, ControllerRenderProps } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { validations } from "../../../config/validations.config"
import CustomFormEM from "../../../components/common/CustomFormEM"
import moment from "moment";
import Avatar from '@material-ui/core/Avatar';
import { getAge } from "../../../utils"
import { RootStateOrAny, useDispatch ,useSelector} from "react-redux"
import { EmptyMessagelist } from "../../../store/actions"
import  Attachments  from "./Attachments"
import Documents from "./Documents"


const DetailsPopup = ({ userRole, type, selData, setDetailsData, setFees, detailData, followUpDetail, refetchDetail, activeTab ,chatNotifyId ,hideChat}: any) => {
  const classes = styles();
  const dispatch = useDispatch();
  const {user} = useSelector((state: RootStateOrAny) => state?.auth);
  const schema = Yup.object().shape({
    followUpFee: validations.commonRequired,
  });

  const {
    control,
    errors,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      followUpFee: " ",
    },
  });

  watch([
    "followUpFee",
  ]);

  useEffect(() => {
    if (detailData) {
      setDetailsData({
        bookedSlotId: detailData?._id,
        date: detailData?.date,
        slotStartTime: detailData?.slotStartTime,
        consultationType: detailData?.consultationType,
        practitioner: detailData?.practitioner,
        facilityId: detailData?.facility?._id,
        specialityId: detailData?.speciality,
        primaryUserId: detailData?.participant?.primaryUserId?.id,
        secondaryUserName:detailData?.participant?.secondaryUserId?.name,
        secondaryUserId: detailData?.participant?.secondaryUserId?.id,
        isFollowupChat : detailData?.isFollowupChat,
      })
      /* Open Chat After Details Fetched in Chat Notify Flow */
      if(sessionStorage.getItem('OpenChat'))
      {
        dispatch(EmptyMessagelist());
        hideChat(true);
        sessionStorage.removeItem('OpenChat');
      }
    }
  }, [detailData])

  const {
    participant,
    appointmentJourney,
  } = followUpDetail?.data || detailData || {}
  

  const { gender, mobile, name, dob, profile,countryCode } = participant?.secondaryUserId || {};

  const consultationTime = moment(dob).format("DD/MM/YYYY")
  
  var fullName = name?.first + " " + name?.last
  const intials = fullName.split(' ').map((name: string) => name[0]).join('').toUpperCase();

 
  return (
    <>
      <Grid container xs={12} spacing={2} className={classes.root}>
        <Grid item xs={3} style={{ padding: 0 }}>
          <Grid item className={classes.leftGrid}>
            <Typography variant="h6" className={classes.details}>Patient Details</Typography>
            {profile?.picture ?
              <div>
                <img src={profile?.picture} className={classes.profileImage} alt="profile img" width="100px" height="100px" />
              </div> :
              <Avatar className={classes.avatarIcons}>{intials}</Avatar>
            }
            <Typography variant="h6" className={classes.names}>{name?.first} {name?.last}</Typography>
            <Typography variant="h6" className={classes.subHeader}>Gender</Typography>
            <Typography variant="h6" className={classes.header} style={{textTransform:"capitalize"}}>{gender}</Typography>
            <Typography variant="h6" className={classes.subHeader}>Date of Birth</Typography>
            <Typography variant="h6" className={classes.header}>{dob ? consultationTime : "-"}</Typography>
            <Typography variant="h6" className={classes.subHeader}>Age</Typography>
            <Typography variant="h6" className={classes.header}>{dob ? getAge(dob) : '-'}</Typography>
            <Typography variant="h6" className={classes.subHeader}>Phone Number</Typography>
            <Typography variant="h6" className={classes.header}>+{countryCode} {mobile}</Typography>
          </Grid>

        </Grid>
        <Grid item xs={9} style={{ padding: '0px 0px 8px 16px' }}>
          <Consultation followUpDetail={followUpDetail} detailData={detailData} />
         <Attachments followUpDetail={followUpDetail} detailData={detailData}></Attachments>
         {((userRole === "Admin" ||userRole === "Doctor"|| userRole === "Receptionist")) && <AppointmentTimeline appointmentJourney={appointmentJourney}/>}
         {activeTab !== 1 && detailData?.state==="FULLFILLED" && (user?.permissions?.includes("CAN_SHOW_CASESHEET_AND_PRECRIPTION") || userRole === "Doctor") && <Documents detailData={detailData}/>}
          {type === "FollowupRequest" &&
            <Followup selData={selData} />}
          {type === "FollowupRequest" &&
            <Grid item xs={6} style={{ paddingTop: '16px' }}>
              <Controller
                name="followUpFee"
                control={control}
                render={(
                  controlProps: ControllerRenderProps<Record<string, any>>
                ) => (
                  <CommonTextField
                    label="Followup Consultation Fee in Rs."
                    type="number"
                    value={controlProps.value || ""}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      controlProps.onChange(e.target.value)
                      setFees(e.target.value)
                    }
                    }
                    variant="filled" />
                )}
              />
              <CustomFormEM
                errors={errors}
                name="followUpFee"
                render={({ message }: any) => <p>{message}</p>}
              />
            </Grid>}
          {type === "FollowupRequest" &&
            <div style={{ paddingTop: '16px', fontSize: '14px' }}>
              <b>Note:</b> Add consultation fee to approve the followup request.
            </div>}
        </Grid>
      </Grid>
    </>)
}
export default DetailsPopup;

