export const styles = (theme) => ({
    root: {
        padding: "7px 25px",
        borderRadius: "4px",
        lineHeight: "26px",
        backgroundColor: "#B7BDCE",
        fontSize: "12px",
        fontWeight: 600,
        color: "white",
        textTransform: "none",
        border: "2px solid #B7BDCE",
        "& .loadin-icon": {
            height: "20px !important",
            width: "20px !important",
            marginRight: "10px",
            color: "white",
        },
        "& .MuiSvgIcon-root": {
            marginRight: "10px",
        },
        "&:hover": {
            backgroundColor: "transparent",
            color: "#B7BDCE",
        }
    }}
    );
    