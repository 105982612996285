import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: any) => ({
  root: {
    "& .MuiButton-root": {
      minWidth: "50px !important",
    },
    "& .MuiAutocomplete-inputRoot": {
      padding: "18px 71px 20px 18px !important",
      border: "1px solid #E9E9E9 !important"
    },
    "& .MuiFilledInput-root":{
      background: "white",
    },
    [theme.breakpoints.down('md')]:{
      width:'280px',
   },
  },
  mainContainer: {
    borderRadius: "0.25rem",
    marginTop: "0.5rem",
  },
  mainHeading: {
    width: "100%",
    background: "#F4F7FB",
    margin: "0",
    padding: "0.875rem 1.25rem",
    textAlign: "left",
    overflow: "hidden",
    border: "1px solid #E4E4E4",
    borderRadius: "4px 4px 0px 0px",
  },
  gridContainer: {
    background: "white",
    borderRadius: "0px 0px 4px 4px",
  },
  wrapper: {
    padding: "1rem 1.25rem",
    border: "1px solid #E4E4E4",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  wrapperContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "0.5rem",
    marginBottom: "1rem",
  },
  allery: {
    margin: "0",
    color: "#00A3A1",
  },
  disease: {
    margin: "0",
    color: "#3246C8",
  },
  progressBar: {
    AlignItems: "center",
    display: "flex",
    left: "50%",
    margin: "0px auto",
  },
  surgery: {
    margin: "0",
    color: "#F68D2E",
  },
  blueContainer: {
    background: "#CFDBFA",
    color: "#34519C",
    margin: '2px 2px',
    borderRadius: "0.25rem",
    width: "fit-content",
    fontSize: "0.875rem",
  },
  historyContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "0.5rem",
    marginBottom: " 12px",
  },
  footerSubmit: {
    "& button": {
      background: "#35C5EA",
      border: "2px solid #35C5EA",
      padding: "0px !important",
      "&:hover": {
        backgroundColor: "transparent",
        color: "#35C5EA",
      },
    },
    "& .Mui-disabled": {
      color: "white",
      background: " #07C180",
      opacity: 0.5,
    },
  },
  select: {
    "& .MuiChip-root ": {
      maxWidth: "8rem",
      margin: "8px 9px 5px 0 !important",
    },
    "& .MuiTextField-root":{
      "& label":{
          backgroundColor:"white"
      }
  },
  "& .MuiAutocomplete-inputRoot":{
    overflowY: "auto",
  }
  },
  addBtn: {
    color: "#34519C !important",
    border:"0px !important",
    background:"transparent !important",
    padding: "0px !important",
    "&:hover":{
      color: "#34519C !important",
    }
  },
  cancelBtn: {
    color: "#F42B3D !important",
    border:"0px !important",
    background:"transparent !important",
    padding: "0px !important",
    "&:hover":{
      color: "#F42B3D !important",
    }
  },
  doneBtn: {
    color: "#34519C !important",
    border:"0px !important",
    background:"transparent !important",
    padding: "0px !important",
    "&:hover":{
      color: "#34519C !important",
    }
  },
  btnHandleContainer: {
    display: "flex",
    gap: "1rem",
    width: "100%",
    justifyContent: "flex-end",
    marginTop: "6px",
  },
  chipLabel :
  {
    maxWidth:'370px !important',
  },
  chipTooltip:
  {
    "& .MuiTooltip-tooltip":{
      background:"#FFFFFF !important",
      color : "black !important",
      boxShadow: '0px 2px 4px 0px rgba(40, 42, 43, 0.3)',
    }
    
  }
}));

export default useStyles;
