export const styles = (theme:any) => ({
    root: {
        width: "100%",
        borderRadius: "4px",
        "& label": {
            color: `${theme.palette.input.labelMain} !important`,
            paddingLeft: '40px',
            "&.MuiInputLabel-shrink": {
                transform: "translate(14px, 6px) scale(0.75)"
            }
        },
        "& input": {
            padding: "22px 15px 12px 44px",
            color: theme.palette.input.textMain,
            fontSize: "14px",
            fontWeight: "600",
            borderRadius: "4px",
            "&::placeholder":{
                fontSize:12
            }
        },
        '& fieldset': {
            border: '1px solid rgba(0, 0, 0, 0.23) !important',
            borderRadius: "4px",
        },
        "&:hover fieldset": {
            borderRadius: "4px",
            border: '1px solid #717171 !important'
        },
        "& .MuiOutlinedInput-root": {
            background: '#FFFFFF',
            '&.Mui-focused fieldset': {
                borderRadius: "4px",
                border: '1px solid #717171 !important'
            }
        },
        "& .MuiFilledInput-root":{
            borderRadius: "4px",
            border: '1px solid transparent !important',
            background: "#F4F4F4 !important"
        },
        "& .MuiFilledInput-underline:before":{
            borderBottom: 'none !important'
        },
        "& .MuiFilledInput-underline:after":{
            borderBottom: 'none !important'
        },
        "&:hover .MuiFilledInput-underline": {
            border: '1px solid #717171 !important'
        },
        "& .MuiFilledInput-underline.Mui-focused": {
            border: '1px solid #717171 !important'
        },
        "& .Mui-disabled":{
            opacity: '0.3'
        },
        "& legend":{
            maxWidth: '0 !important'
        },
        
    }
});