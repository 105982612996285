import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Popover, Typography ,PopoverOrigin } from "@material-ui/core";
import FilterIcon from "../../../assets/images/icons/ic_filtericon.svg";
import clsx from "clsx";
import styles from "./style";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomSubmitButton from "../CustomSubmitButton";

interface IFilterTypes {
    label : string;
    component : JSX.Element
}
interface ICustomFilterProps {
    buttonClass? : string;
    popoverClass? : string;
    filterTypes : IFilterTypes[];
    applyFilter : () => void;
    resetFilter : () => void;
    anchorOrigin?:PopoverOrigin;
    transformOrigin?:PopoverOrigin;
}

const CommonFilter = (props: ICustomFilterProps) => {
    const classes = styles();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [expanded, setExpanded] = useState<number | null>(null)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onApply = () => {
        setAnchorEl(null);
        props.applyFilter()
    }

    const onReset = () => {
        // setAnchorEl(null);
        props.resetFilter()
    }

    const handleChange = (index: number | null) => (event:  React.ChangeEvent<{}>, newExpanded: boolean) => {
        setExpanded(newExpanded ? index : null);
    };

    return (
        <>
            <IconButton className={clsx(classes.filterButtonRoot, props.buttonClass)} onClick={handleClick}>
                <img src={FilterIcon} />
                <Typography>Filter</Typography>
            </IconButton>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                className={clsx(classes.popoverRoot, props.popoverClass)}
                anchorOrigin={props?.anchorOrigin ? props?.anchorOrigin  : {
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={props?.transformOrigin ? props?.transformOrigin : {
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div>
                    {props.filterTypes && props.filterTypes.map(({label, component}: {label:string, component: any}, index: number) => {
                        return (
                            <Accordion className={classes.accordionRoot} square expanded={expanded === index} onChange={handleChange(index)}>
                                <AccordionSummary
                                    aria-controls={`accordion-content-${index}`}
                                    id={`accordion-header-${index}`}
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography className={classes.filterTypeTitle}>{label}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                   {component}
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}

                    <div className={classes.filterActionWrapper}>
                        <IconButton onClick={onReset} className='resetFilterBtn'>
                            <Typography style={{fontSize: "14px"}}>Reset filter</Typography>
                        </IconButton>
                        <div>
                            <IconButton disableRipple onClick={handleClose} className='cancelFilterBtn'>
                                <Typography style={{fontSize: "14px"}}>Cancel</Typography>
                            </IconButton>
                            <CustomSubmitButton
                                className='applyFilterBtn'
                                type="button"
                                isLoading={false}
                                onClick={onApply}
                            >
                                Apply
                            </CustomSubmitButton>
                        </div>
                    </div>
                </div>
            </Popover>
        </>
    );
}

export default CommonFilter;
