import HomeInactiveIcon from "../assets/images/icons/home_inactive.svg";
import SlotMangement from "..//assets/images/icons/appointment_inactive.svg";
import AppointmentIcon from "..//assets/images/icons/ic_menu_appointment.svg";
import ic_doctor from "../assets/images/menu-drawer/ic_menu_doctor.svg"
import ic_admin from "../assets/images/menu-drawer/ic_menu_admin.svg"
import ic_home from "../assets/images/menu-drawer/ic_menu_home.svg"
import ic_others from "../assets/images/menu-drawer/ic_menu_others.svg"
import ic_facility from "../assets/images/menu-drawer/ic_menu_facility.svg"
import ic_receptionist from "../assets/images/menu-drawer/ic_menu_receptionist.svg"
import ic_profile from "../assets/images/menu-drawer/ic_menu_profile.svg"
import ic_users from "../assets/images/menu-drawer/ic_menu_users.svg"
import ic_appointments from "../assets/images/menu-drawer/ic_menu_appoinments.svg"
import ic_ePharma from "../assets/images/menu-drawer/ic_menu_e-pharma.svg"
import ic_diagonosis from "../assets/images/menu-drawer/ic_menu_diagonosis.svg"
import ic_consultation from "../assets/images/menu-drawer/ic_menu_consultation.svg"
import ic_scheduler from "../assets/images/menu-drawer/ic_menu_scheduler.svg"
import ic_dashboardActiveIcon from "../assets/images/icons/DashboardActive_BlueIcon.svg"
import ic_doctorActiveIcon from "../assets/images/icons/DoctorActive_BlueIcon.svg"
import ic_receptionistActiveIcon from "../assets/images/icons/ReceptionistActive_BlueIcon.svg"
import ic_appointmentsActiveIcon from "../assets/images/icons/AppointmentActive_BlueIcon.svg"
import ic_slotActiveIcon from "../assets/images/icons/slotActive_BlueIcon.svg"
import ic_reportActiveIcon from "../assets/images/menu-drawer/ic_selected_report.svg"
import ic_reports from "../assets/images/menu-drawer/ic_menu_reports.svg"
import ic_hisrory from "../assets/images/menu-drawer/ic_history.svg"
import ic_selected_hisrory from "../assets/images/menu-drawer/ic_selectedHistory.svg"
import ic_menu_history from "../assets/images/menu-drawer/ic_menu_history.svg"
import ic_menu_history_active from "../assets/images/menu-drawer/ic_menu_history_active.svg"
import ic_inprogress from "../assets/images/menu-drawer/ic_inprogress.svg"
import ic_inprogress_active from "../assets/images/menu-drawer/ic_inprogress_active.svg"
import invoice from '../assets/images/menu-drawer/Invoice.svg'
import invoice_active from '../assets/images/menu-drawer/invoice_active.svg'
import request from '../assets/images/menu-drawer/request_inactive.svg'
import request_active from '../assets/images/menu-drawer/request_active.svg'
import labTest_active from "../assets/images/menu-drawer/labTest_Active.svg"
import labTestIcon from "../assets/images/menu-drawer/ic_status_inactive.svg"


export const MENU_EMPANELMENT_ADMIN = [

  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img
          src={ic_home}
          className="inactive"
          style={{ width: "19px" }}
        />
      </>
    ),
    submenu: []
  },
  // {
  //   label: "Prescription",
  //   url: "/Prescription",
  //   icon: (
  //     <>
  //       <img
  //         src={HomeInactiveIcon}
  //         className="inactive"
  //         style={{ width: "19px" }}
  //       />
  //     </>
  //   ),
  //   submenu: []
  // },
  // {
  //   label: "Lab Transcriber",
  //   url: "/labTranscriber",
  //   icon: (
  //     <>
  //       <img
  //         src={HomeInactiveIcon}
  //         className="inactive"
  //         style={{ width: "19px" }}
  //       />
  //     </>
  //   ),
  //   submenu: []
  // }, /* STAR FACILITY  IS COMMON * /
  // {
  //   label: "Facility Empanelment",
  //   url: "/Empanelment/facilityEmpanelment",
  //   icon: (
  //     <>
  //       <img
  //         src={ic_facility_emp}
  //         className="inactive"
  //         style={{ width: "19px" }}
  //       />
  //     </>
  //   ),
  //   submenu: []
  // }, 
  // {
  //   label: "Doctor Empanelment",
  //   url: "/Empanelment/DoctorEmpanelment",
  //   icon: (
  //     <>
  //       <img
  //         src={ic_doctor_emp}
  //         className="inactive"
  //         style={{ width: "15px" }}
  //       />
  //     </>
  //   ),
  //   submenu: []
  // }
  //Except dashboard all side menu items are temporarily removed for Star release
  /* {
    label: "Facility Empanelment",
    url: "/empanelment/facility",
    icon: (
      <>
        <img
          src={ClinicInactiveIcon}
          className="inactive"
          style={{ width: "19px" }}
        />
      </>
    ),
    submenu: []
  }, {
    label: "Doctor Empanelment",
    url: "/empanelment/doctor",
    icon: (
      <>
        <img
          src={HomeInactiveIcon}
          className="inactive"
          style={{ width: "19px" }}
        />
      </>
    ),
    submenu: []
  },
  {
    label: "Dashboard - Admin",
    url: "/admin/facility-detail",
    icon: (
      <>
        <img
          src={HomeInactiveIcon}
          className="inactive"
          style={{ width: "19px" }}
        />
      </>
    ),
    submenu: []
  },
  {
    label: "Manage users",
    url: "/admin",
    disabled: true,
    icon: (
      <>
        <img
          src={HomeInactiveIcon}
          className="inactive"
          style={{ width: "19px" }}
        />
      </>
    ),
    submenu: [
      {
        label: "Admins",
        url: "/admin/admins-list",
        icon: (
          <>
            <img src={AdminInactiveIcon} className="inactive" />
          </>
        )
      },
      {
        label: "Doctors",
        url: "/admin/doctors-list",
        icon: (
          <>
            <img src={AdminInactiveIcon} className="inactive" />
          </>
        )
      },
      {
        label: "AHPs",
        url: "/admin/ahps-list",
        icon: (
          <>
            <img src={AdminInactiveIcon} className="inactive" />
          </>
        )
      },
    ]
  }, */


  /*
  {
    label: "Dashboard",
    url: "/Empanelment/index",
    icon: (
      <>
        <img
          src={HomeInactiveIcon}
          className="inactive"
          style={{ width: "19px" }}
        />
        </>
        ),
        submenu:[]
  },
  {
    label: "Facility Empanelment",
    url: "/Empanelment/facilityEmpanelment",
    icon: (
      <>
        <img src={FacilityIcon} className="inactive" />
      </>
    ),
    submenu: []
    },
  {
    label: "Doctor Empanelment",
    url: "/Empanelment/DoctorEmpanelment",
    icon: (
      <>
        <img src={DoctorIcon} className="inactive" />
      </>
    ),
    submenu: []
  },
  */
];

export const Operational_Receptionist_Login = [
  {
    label: "Dashboard",
    url: "/OperationalReceptionist/dashboard",
    icon: (
      <>
        <img src={ic_home} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Slot Management",
    url: "/appointment-scheduler",
    icon: (
      <>
        <img src={ic_scheduler} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Appointments",
    url: "/Appointments",
    icon: (
      <>
        <img src={ic_appointments} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "My Profile",
    url: "/newprofile/operational-recepptionist",
    icon: (
      <>
        <img src={ic_profile} className="inactive" />
      </>
    ),
    submenu: [],
  },
]

export const Receptionist_Login = [
  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img src={ic_home} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_dashboardActiveIcon} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Appointments",
    url: "/Appointments",
    icon: (
      <>
        <img src={AppointmentIcon} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_appointmentsActiveIcon} className="active" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Slot Management",
    url: "/appointment-scheduler",
    icon: (
      <>
        <img src={SlotMangement} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_slotActiveIcon} className="active" />
      </>
    ),
    submenu: [],
  },

  // {
  //   label: "My Profile",
  //   url: "/newprofile/receptionist",
  //   icon: (
  //     <>
  //       <img src={ic_profile} className="inactive" />
  //     </>
  //   ),
  //   submenu: [],
  // },
]
export const MENU_SUPER_ADMIN = [
  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img src={ic_home} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Manage users",
    url: "/ManageUsers/",
    disabled: true,
    icon: (
      <>
        <img src={ic_users} className="inactive" />
      </>
    ),
    submenu: [
      {
        label: "Admins",
        url: "/ManageUsers/Admin",
        icon: (
          <>
            <img src={ic_admin} className="inactive" />
          </>
        ),
      },
      {
        label: "Doctors",
        url: "/ManageUsers/Doctor",
        icon: (
          <>
            <img src={ic_doctor} className="inactive" />
          </>
        ),
      },
      // commenting as per bug 19743
      // {
      //   label: "AHPs",
      //   url: "/ManageUsers/AHP",
      //   icon: (
      //     <>
      //       <img src={ic_ahp} className="inactive" />
      //     </>
      //   ),  
      // },
      //***--  Commenting this and will use in next phase --**//
      // {
      //   label: "Patients",
      //   url: "/ManageUsers/Patients",
      //   icon: (
      //     <>
      //       <img src={ic_patient} className="inactive" />
      //     </>
      //   ),  
      // },
      {
        label: "Others",
        url: "/ManageUsers/Others",
        icon: (
          <>
            <img src={ic_others} className="inactive" />
          </>
        ),
      },

    ],
  },
  {
    label: "Slot Management",
    url: "/appointment-scheduler",
    icon: (
      <>
        <img src={ic_scheduler} className="inactive" />
      </>
    ),
    submenu: [],
  },/* STAR FACILITY  IS COMMON */
  // {
  //   label: "Facility Settings",
  //   url: "/newprofile/facility",
  //   icon: (
  //     <>
  //       <img src={ic_settings} className="inactive" />
  //     </>
  //   ),
  //   submenu: [],
  // },
  {
    label: "My Profile",
    url: "/newprofile/admin",
    icon: (
      <>
        <img src={ic_profile} className="inactive" />
      </>
    ),
    submenu: [],
  },
];
export const MENU_FACILITY_ADMIN = [
  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img src={ic_home} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_dashboardActiveIcon} className="active" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Facility Users",
    url: "/ManageUsers/",
    disabled: true,
    icon: (
      <>
        <img src={ic_facility} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_facility} className="active" />
      </>
    ),

    submenu: [
      // {
      //   label: "Admins",
      //   url: "/ManageUsers/Admin",
      //   icon: (
      //     <>
      //       <img src={ic_admin} className="inactive" />
      //     </>
      //   ),  
      // },
      {
        label: "Doctors",
        url: "/ManageUsers/Doctor",
        icon: (
          <>
            <img src={ic_doctor} className="inactive" />
          </>
        ),
        iconActive: (
          <>
            <img src={ic_doctorActiveIcon} className="active" />
          </>
        )
      },
      // commenting as per bug 19743
      // {
      //   label: "AHPs",
      //   url: "/ManageUsers/AHP",
      //   icon: (
      //     <>
      //       <img src={ic_ahp} className="inactive" />
      //     </>
      //   ),  
      // },
      //***--  Commenting this and will use in next phase --**//
      // {
      //   label: "Patients",
      //   url: "/ManageUsers/Patients",
      //   icon: (
      //     <>
      //       <img src={ic_patient} className="inactive" />
      //     </>
      //   ),  
      // },
      {
        label: "Receptionist",
        url: "/ManageUsers/Others",
        icon: (
          <>
            <img src={ic_receptionist} className="inactive" />
          </>
        ),
        iconActive: (
          <>
            <img src={ic_receptionistActiveIcon} className="active" />
          </>
        ),
      },
    ],
  },
  {
    label: "Appointments",
    url: "/Appointments",
    icon: (
      <>
        <img src={AppointmentIcon} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_appointmentsActiveIcon} className="active" />
      </>
    ),
    submenu: []
  },
  {
    label: "Slot Management",
    url: "/appointment-scheduler",
    icon: (
      <>
        <img src={SlotMangement} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_slotActiveIcon} className="active" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Reports",
    url: "/Reports",
    icon: (
      <>
        <img src={ic_reports} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_reportActiveIcon} className="active" />
      </>
    ),
    submenu: [],
  },
  /* STAR FACILITY  IS COMMON */
  // {
  //   label: "Facility Settings",
  //   url: "/newprofile/facility",
  //   icon: (
  //     <>
  //       <img src={ic_settings} className="inactive" />
  //     </>
  //   ),
  //   submenu: [],
  // },
  // {
  //   label: "My Profile",
  //   url: "/newprofile/admin",
  //   icon: (
  //     <>
  //       <img src={ic_profile} className="inactive" />
  //     </>
  //   ),
  //   submenu: [],
  // },
];

export const MENU_FIELD_AGENT = [
  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img src={ic_home} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "My Profile",
    url: "/newprofile/admin",
    icon: (
      <>
        <img src={ic_profile} className="inactive" />
      </>
    ),
    submenu: [],
  },
];

export const PHC_LAB_WALKIN = [
  {
    label: "Upcoming",
    url: "/Lab/Upcoming",
    icon: (
      <>
        <img src={SlotMangement} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_slotActiveIcon} className="active" />
      </>
    ),
    submenu: []
  },
];

export const PHC_FINANCE = [
  {
    label: "Home",
    url: "/Dashboard",
    icon: (
      <>
        <img src={ic_home} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_dashboardActiveIcon} className="active" />
      </>
    ),
    submenu: []
  },

];

export const PHC_OPERATIONS = [
  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img src={ic_home} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_dashboardActiveIcon} className="active" />
      </>
    ),
    submenu: []
  },
  {
    label: "Upcoming",
    url: "/Upcoming",
    icon: (
      <>
        <img src={SlotMangement} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_slotActiveIcon} className="active" />
      </>
    ),
    submenu: []
  },
  {
    label: "In Progress",
    url: "/InProgress",
    icon: (
      <>
        <img src={ic_inprogress} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_inprogress_active} className="active" />
      </>
    ),
    submenu: []
  },
  {
    label: "History",
    url: "/history",
    icon: (
      <>
        <img src={ic_menu_history} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_menu_history_active} className="active" />
      </>
    ),
    submenu: []
  },
];

export const PHC_OPERATIONS_ADMIN = [
  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img src={ic_home} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_dashboardActiveIcon} className="active" />
      </>
    ),
    submenu: []
  },
  {
    label: "Upcoming",
    url: "/Upcoming",
    icon: (
      <>
        <img src={SlotMangement} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_slotActiveIcon} className="active" />
      </>
    ),
    submenu: []
  },
  {
    label: "In Progress",
    url: "/InProgress",
    icon: (
      <>
        <img src={ic_inprogress} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_inprogress_active} className="active" />
      </>
    ),
    submenu: [],
  },
  {
    label: "History",
    url: "/history",
    icon: (
      <>
        <img src={ic_hisrory} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_selected_hisrory} className="active" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Invoices",
    url: "/Invoices/OperationAdmin",
    icon: (
      <>
        <img src={invoice} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={invoice_active} className="active" />
      </>
    ),
    submenu: [],
  },
];

export const UNIFIED_APP_LAB_VENDOR = [
  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img src={ic_home} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_dashboardActiveIcon} className="active" />
      </>
    ),
    submenu: []
  },
  {
    label: "Requests",
    url: "/Requests",
    icon: (
      <>
        <img src={request} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={request_active} className="active" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Upcoming",
    url: "/Upcoming",
    icon: (
      <>
        <img src={SlotMangement} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_slotActiveIcon} className="active" />
      </>
    ),
    submenu: []
  },
  {
    label: "In Progress",
    url: "/InProgress",
    icon: (
      <>
        <img src={ic_inprogress} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_inprogress_active} className="active" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Lab Tests",
    url: "/boardView",
    icon: (
      <>
        <img src={labTestIcon} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={labTest_active} className="active" />
      </>
    ),
    submenu: []
  },
  {
    label: "History",
    url: "/history",
    icon: (
      <>
        <img src={ic_hisrory} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_selected_hisrory} className="active" />
      </>
    ),
    submenu: [],
  },
];

export const UNIFIED_APP_VACCINATION = [
  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img src={ic_home} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_dashboardActiveIcon} className="active" />
      </>
    ),
    submenu: []
  },
  {
    label: "Requests",
    url: "/Requests",
    icon: (
      <>
        <img src={request} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={request_active} className="active" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Upcoming",
    url: "/Upcoming",
    icon: (
      <>
        <img src={SlotMangement} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_slotActiveIcon} className="active" />
      </>
    ),
    submenu: []
  },
  {
    label: "Status",
    url: "/boardView",
    icon: (
      <>
        <img src={labTestIcon} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={labTest_active} className="active" />
      </>
    ),
    submenu: []
  },
  {
    label: "History",
    url: "/history",
    icon: (
      <>
        <img src={ic_hisrory} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_selected_hisrory} className="active" />
      </>
    ),
    submenu: [],
  },
];

export const MENU_DOCTOR = [
  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img src={ic_home} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_dashboardActiveIcon} className="active" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Appointments",
    url: "/Appointments",
    icon: (
      <>
        <img src={AppointmentIcon} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_appointmentsActiveIcon} className="active" />
      </>
    ),
    submenu: []
  },
  {
    label: "Slot Management",
    url: "/appointment-scheduler",
    icon: (
      <>
        <img src={SlotMangement} className="inactive" />
      </>
    ),
    iconActive: (
      <>
        <img src={ic_slotActiveIcon} className="active" />
      </>
    ),
    submenu: [],
  },
];

export const MENU_OPERATOR = [

  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img
          src={HomeInactiveIcon}
          className="inactive"
          style={{ width: "19px" }}
        />
      </>
    ),
    submenu: []
  },
  {
    label: "Appointments",
    url: "/Appointments",
    icon: (
      <>
        <img src={AppointmentIcon} className="inactive" />
      </>
    ),
    submenu: []
  },
  {
    label: "Followup Requests",
    url: "/Followup_requests",
    icon: (
      <>
        <img src={AppointmentIcon} className="inactive" />
      </>
    ),
    submenu: []
  },
  {
    label: "My Profile",
    url: "/newprofile/doctor",
    icon: (
      <>
        <img src={ic_profile} className="inactive" />
      </>
    ),
    submenu: [],
  },
]

/* Commenting this code and this will be used in next Phase */
export const TRANSCRIPTION_AGENT = [
  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img src={ic_home} className="active" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Lab Tests",
    url: "/labTranscriber",
    icon: (
      <>
        <img src={AppointmentIcon} className="inactive" />
      </>
    ),
    submenu: []
  }
];

/* Commenting this code and this will be used in next Phase */
export const CLAIMS_PROCESS = [
  {
    label: "Dashboard",
    url: "/Dashboard",
    icon: (
      <>
        <img src={ic_home} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Consultation",
    url: "/claims/consultation",
    icon: (
      <>
        <img src={ic_consultation} className="inactive" />
      </>
    ),
    submenu: []
  },
  {
    label: "E - Pharmacy",
    url: "/claims/epharmacylist",
    icon: (
      <>
        <img src={ic_ePharma} className="inactive" />
      </>
    ),
    submenu: [],
  },
  {
    label: "Diagnostics",
    url: "/claims/diagnostics",
    icon: (
      <>
        <img src={ic_diagonosis} className="inactive" />
      </>
    ),
    submenu: []
  }
];
