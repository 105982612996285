import Services from "../services";
import {
  APPOINTMENT_SERVICE,
  MASTER_DATA_SERVICE,
  PRACTITIONER_SERVICE,
  GET_SLOT_LIST_SERVICE,
  GET_USER_SERVICE, POLICY_DETAILS, BASE_URL_OPD
} from '../../config/url.config'

const instance = new Services().getServiceInstance();

export const getDoctorlistview = async (key: string, payload: any) => {
  const params = payload;
  const { data } = await instance.get(`${APPOINTMENT_SERVICE}/getAppointmentForPractitioner?`, { params });
  return data;
};

/* Get All Admin Appointment */
export const getAdminListView = async (key: string, payload: any) => {
  const params = payload;
  const { data } = await instance.get(`${APPOINTMENT_SERVICE}/getAppointmentForAdmin?`, { params });
  return data;
};

export const consultationTypeByFacility = async (key: any, facilityId: any, patient: any) => {
  const { data } = await instance.get(`${APPOINTMENT_SERVICE}/consultation-type?facilityId=${facilityId}`);
  return data;
}

export const getAllSpeciality = async (size: any, page: any, serach: any) => {
  const { data } = await instance.get(MASTER_DATA_SERVICE + "/speciality?select=providerSpeciality" + "&pageSize=" + `${size}` + "&pageNumber=" + `${page}` + `&search=${serach}`);
  return data;
}

export const getpractionerBySpeciality = async (facilityId: any, specialityId: any) => {
  const { data } = await instance.get(PRACTITIONER_SERVICE + "/mobile/speciality/practitioners?facilityId=" + `${facilityId}` + "&departmentId=" + `${specialityId}`);
  return data;
}

export const getSymptoms = async (pageSize: any, pageNumber: any, limit: any, skip: any) => {
  const { data } = await instance.get(MASTER_DATA_SERVICE + "/symptom?pageSize=" + `${pageSize}` + "&pageNumber=" + `${pageNumber}` + "&limit=" + `${limit}` + "&skip=" + `${skip}`);
  return data;
}

export const getSlotList = async (key: string, payload: any) => {
  const params = payload;
  const { data } = await instance.post(GET_SLOT_LIST_SERVICE, params);
  return data;
}

export const getUserDetailBySearch = async (search: any, limit: any, skip: any, pageSize: any, pageNumber: any) => {
  const { data } = await instance.get(GET_USER_SERVICE + "getAll/users?search=" + `${search}` + "&limit=" + `${limit}` + "&skip=" + `${skip}` + "&pageSize=" + `${pageSize}` + "&pageNumber=" + `${pageNumber}`);
  return data;
}

export const fixAppointment = async (payload) => {
  const params = payload;
  const { data } = await instance.post(APPOINTMENT_SERVICE + "/bookConsultation", params);
  return data;
}

/* Get FollowUp Appointment Details */
export const getFollowUpDetails = async (key: string, payload: any) => {
  const { data } = await instance.get(`${APPOINTMENT_SERVICE}/${payload.appointmentId}/followupAppointmentDetails`,);
  return data;
};

/*Get All FollowUp List */
export const getFollowUpList = async (key: string, payload: any) => {
  const params = payload;
  const { data } = await instance.get(`${APPOINTMENT_SERVICE}/consultation/getFollowupRequests?`, { params });
  return data;
};


/*Accept FollowUp */
export const acceptFollowUpRequest = async (key: any, params: any, bookedSlotId: any) => {
  return instance.patch(`${APPOINTMENT_SERVICE}/consultation/update-followup-request/${bookedSlotId}`, params).then((res: any) => res)
    .catch((err: any) => err.response);
}

/*Reject FollowUp */
export const rejectFollowUpRequest = async (key: any, params: any, bookedSlotId: any) => {
  return instance.patch(`${APPOINTMENT_SERVICE}/consultation/update-followup-request/${bookedSlotId}`, params).then((res: any) => res)
    .catch((err: any) => err.response);
}
export const appointmentDetail = async (key: string, payload: any) => {
  const { data } = await instance.get(`${APPOINTMENT_SERVICE}/${payload.appointmentId}/getAppointmentDetails`);
  return data
};

//Approve and Reject for Appointment
export const approveRejectAppointment = async (params: any) => {
  return instance.patch(`${APPOINTMENT_SERVICE}/consultation/updateStateConsultation`, params).then((res: any) => res)
    .catch((err: any) => err.response);
}

export const getpolicydetails = async (mobileno: string) => {
  const { data } = await instance.get(`${POLICY_DETAILS}/policy?mobileNo=${mobileno}`)
  return data;
}

export const getPolicy = async (userId: string, mobileno: string) => {
  const { data } = await instance.get(`${POLICY_DETAILS}/policy/backgroundprocess?userId=${userId}&mobileNo=${mobileno}`)
  return data;
}

export const getPolicyWalletBalance = async (params: any) => {
  const { data } = await instance.post(`${POLICY_DETAILS}/policy/GetWalletBalance`, params)
  return data;
}

export const createPatientApi = (payload) => {
  return instance.post(BASE_URL_OPD, payload);
};

export const getPatientByPhoneApi = (code, phone) => {
  return instance.get(`${BASE_URL_OPD}/mobile?countryCode=${code}&mobile=${phone}`);
};
export const fixFollowUpAppointment = async (payload) => {
  const params = payload;
  const { data } = await instance.post(APPOINTMENT_SERVICE + "/bookFollowUpConsultation", params);
  return data;
}

export const getDashBoardUpcomingAppointments = async (key:string,payload : any) =>{
  const { data } = await instance.post(`${APPOINTMENT_SERVICE}/getDashBoardUpcomingAppointments`,payload);
  return data;
}

