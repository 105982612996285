import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme: any) => ({
  root: {
    "& .MuiOutlinedInput-input": {
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: "14px",
      padding: "0px 10px 12px 18px !important",
      marginTop:"18px",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      background: "#EDEFF3",
      borderRadius: "4px !important",
    },
    "& fieldset": {
      border: "1px solid #E4E4E4 !important",
      borderRadius: "4px !important",
    },
    "& label": {
      transform: " translate(14px, 14px) scale(1)",
      color: "#000000 !important",
      fontWeight: 500,
      fontSize: "14px !important",
      lineHeight:'24px !important'
    },
  },
  container: {
    width: "30vw",
    borderRadius: "0.25rem",
  },
  caseSheet: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "16px",
    color: "#000000",
  },
  headerContainer: {
    padding: "12px 0 12px 18px",
    background: "#F4F7FB",
    border: "1px solid #E4E4E4",
  },
  inputsContainer: {
    background: "#FFFFFF",
    padding: "20px 20px 24px 20px",
    border: "1px solid #E4E4E4",
    height:"70vh !important",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
  },
  symptoms: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "14px",
    color: "#000000",
  },
  listContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
    marginTop: "10px",
  },
  symptomsContainer: {
    padding: " 6px 14px 6px 14px",
    width: "fit-content",
    background: "#CFDBFA",
    borderRadius: "4px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    color: "#34519C",
    cursor: "pointer",
  },
  addNew: {
    marginTop: "10px",
    color: "#34519C",
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "14px",
  },
  buttonwrapper: {
    margin: "-0.75rem 0rem 1rem 1rem",
  },
  select: {
    marginTop: "16px",
    "& .MuiInputBase-input": {
      paddingTop: "6px !important",
      paddingBottom: "6px !important",
      fontWeight: "400 !important",
      fontSize: "16px !important",
      lineHeight: "26px",
      background: "white",
    },
    "& .MuiFilledInput-root": {
      background: "white",
      border: "1px solid #E4E4E4 !important",
    },
    "& .MuiTextField-root": {
      "& label": {
        backgroundColor: "white",
        color: "#000000 !important",
        fontWeight: 500,
        fontSize: "14px !important"
      }
    },
    " & .makeStyles-root-232 label": {
      color: "#000000 !important",
      fontWeight: 500,
      fontSize: "14px !important"
    },
    "& .MuiAutocomplete-inputRoot": {
      overflowY: "auto"
    }
  },
  dates: {
    // "& .background-color: #F4F4F4;": {
    //   background: '#FFFFFF !important',
    //   borderRadius: "4px",
    // },
    "& .MuiOutlinedInput-root": {
      border: "1px solid #80808038",
      "&:hover":{
        border: "1px solid transparent",
      }
    },
    "& .MuiFormLabel-root": {
      color: "black !important",
      fontWeight: 600
    },
  },
  FollowupTime: {
    border: "1px solid #D0D8EA",
    padding: "10px 0",
    width: "100px",
    textAlign: "center",
    cursor: "pointer",
    "& .MuiTypography-body2": {
      fontSize: "14px !important",
    },
    borderRadius: "6px",
    margin: "0px 7px 7px 7px",
    "&.active": {
      backgroundColor: "#34519C !important",
      border: "1px solid #34519C",
      "& .MuiTypography-body2": {
        fontWeight: 600,
        color: "#FFFFFF",
      },
    },
  },
  headerTile:{
    "& .MuiListSubheader-root":{
      fontWeight: 600,
    }
  },
  slotNotAvailable: {
    fontSize: "20px !important",
    fontWeight: 600,
    margin: "2.5rem auto",
    "&.MuiTypography-colorPrimary": {
      cursor: "pointer",
    },
  },
  TimeHidden: {
    opacity: '0.6 !important',
    background: 'lightgrey !important',
    cursor: 'no-drop',
  },
  IconHide: {
    "& .MuiIconButton-root": {
      display:'none'
    }
  },
  title: {
    fontSize: "15px !important",
    fontWeight: 600,
    marginBottom: "8px",
    marginLeft: '2px',
    "&.MuiTypography-colorPrimary": {
      cursor: "pointer",
    },
  },
  Consult: {
    "& .MuiSelect-select": {
      background: '#FFFFFF',
    },
    "& .MuiFormLabel-root": {
      color: "black !important",
      fontWeight: 600
    },
    "& label": {
      transform: "translate(14px, 18px) scale(1)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(14px, 6px) scale(0.75)"
      }
    },
  },
  blueContainer: {
    background: "#CFDBFA",
    color: "#34519C",
    margin: '2px 2px',
   // padding: "0.375rem 0.875rem",
    borderRadius: "0.25rem",
    width: "fit-content",
    fontSize: "0.875rem",
  },
  checkPadding:{
    padding: "0 !important",
    paddingLeft: "0 !important",
    display:"flex",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "0.9rem !important",
    color: "black"
  },
  groupHeaderSticky:{
    top: "-8px",
    position: "sticky",
    zIndex: 1,
    "& .MuiTypography-root":{
      boxSizing: "border-box",
      lineHeight: "48px",
      listStyle: "none",
      fontSize: "0.875rem",
      paddingRight: "16px",
      backgroundColor: "#fff",
      paddingLeft:"10px",
      fontWeight:"bold"
    }
  },
  groupSubHeader:{
    paddingLeft:"10px",
    opacity:"0.5"
  },
  GroupsList:{
    padding:"0 8px !important",
    fontSize: "0.9rem !important",
    display: 'block !important'
  },
  hr:{
    margin: "10px",
    opacity:0.5
  },
  checkedColor:{
    padding: "0px !important",
    "& .MuiSvgIcon-root ":{
      fontSize: "1.35rem !important",
    },
    "& .MuiButtonBase-root":{
      padding: "0px !important"
    }
  },
  checkedIcon:{
    padding: "9px !important",
    minWidth: "30px !important"
  },
  clearPadding:{
    padding: "2px !important"
  }
}));

export default useStyles;
